import { routes } from 'router';
import {
  AbsaBankIcon,
  BidvestBankIcon,
  CapitecBankIcon,
  DiscoveryBankIcon,
  FnbBankIcon,
  NedbankIcon,
  RmbBankIcon,
  StandardBankIcon,
  TymeBankIcon,
} from 'assets';

export const employment_statuses = Object.freeze({
  formally_employed: 'Formally employed',
  contract_employed: 'Contract employed',
  self_employed: 'Self employed',
  commission: 'Commission',
  sassa_grant: 'Sassa grant',
  unemployed: 'Unemployed',
});

export const income_frequencies = Object.freeze({
  weekly: 'Weekly',
  monthly: 'Monthly',
  commission: 'Commission',
});

export const applicationDefaults = Object.freeze({
  loanTerms: [
    { label: '1 month', value: '1' },
    { label: '3 months', value: '3' },
    { label: '6 months', value: '6' },
    { label: '12 months', value: '12' },
    { label: '18 months', value: '18' },
    { label: '24 months', value: '24' },
    { label: '36 months', value: '36' },
    { label: '48 months', value: '48' },
    { label: '60 months', value: '60' },
    { label: '72 months', value: '72' },
  ],
  loanUsage: [
    { label: 'Housing/home improvement', value: 'housing' },
    { label: 'Education', value: 'education' },
    { label: 'Funeral', value: 'funeral' },
    { label: 'Emergency', value: 'emergency' },
    { label: 'I want to spoil myself', value: 'spoil_myself' },
    { label: 'Other', value: 'other' },
  ],
  employmentStatus: [
    { label: employment_statuses.formally_employed, value: 'formallyEmployed' },
    { label: employment_statuses.contract_employed, value: 'contractEmployed' },
    { label: employment_statuses.self_employed, value: 'selfEmployed' },
    { label: employment_statuses.commission, value: 'commission' },
    { label: employment_statuses.sassa_grant, value: 'sassaGrant' },
    { label: employment_statuses.unemployed, value: 'unemployed' },
  ],
  employmentSectors: [
    { label: 'Advertising and Media' },
    { label: 'Agriculture' },
    { label: 'Cleaning' },
    { label: 'Construction' },
    { label: 'Education' },
    { label: 'Financial Services' },
    { label: 'Government and Public Sector' },
    { label: 'Healthcare and Beauty' },
    { label: 'Hotels and Restaurants' },
    { label: 'Information Technology' },
    { label: 'Legal Services' },
    { label: 'Leisure, Travel and Tourism' },
    { label: 'Manufacturing' },
    { label: 'Marketing and Public Relations' },
    { label: 'Mining' },
    { label: 'Other' },
    { label: 'Property' },
    { label: 'Retail' },
    { label: 'Security' },
    { label: 'Transport and Logistics' },
    { label: 'Telecommunications' },
  ],
  incomeFrequency: [
    { label: income_frequencies.weekly, value: 'weekly' },
    { label: income_frequencies.monthly, value: 'monthly' },
    { label: income_frequencies.commission, value: 'commission' },
  ],
  incomeSource: [
    { label: 'Salary', value: 'salary' },
    { label: 'Wage', value: 'wage' },
    { label: 'SASSA Grant', value: 'sassa-grant' },
    { label: 'Self Employed', value: 'self-employed' },
    { label: 'Unemployed', value: 'unemployed' },
  ],
  rejectionReason: [
    { label: 'The loan is not enough', value: '1' },
    { label: 'The installments are too high', value: '2' },
    { label: 'The loans are too expensive', value: '3' },
  ],
  years: [
    { label: '2022', value: '2022' },
    { label: '2021', value: '2021' },
    { label: '2020', value: '2020' },
    { label: '2019', value: '2019' },
    { label: '2018', value: '2018' },
    { label: '2017', value: '2017' },
    { label: '2016', value: '2016' },
    { label: '2015', value: '2015' },
    { label: '2014', value: '2014' },
    { label: '2013', value: '2013' },
    { label: '2012', value: '2012' },
    { label: '2011', value: '2011' },
    { label: '2010', value: '2010' },
  ],
  banks: {
    standard_bank: { label: 'Standard Bank', value: 'standard-bank', icon: StandardBankIcon },
    bidvest: { label: 'Bidvest', value: 'bidvest', icon: BidvestBankIcon },
    fnb: { label: 'FNB', value: 'fnb', icon: FnbBankIcon },
    discovery: { label: 'Discovery Bank', value: 'discovery', icon: DiscoveryBankIcon },
    capitec: { label: 'Capitec', value: 'capitec', icon: CapitecBankIcon },
    nedbank: { label: 'Nedbank', value: 'nedbank', icon: NedbankIcon },
    absa: { label: 'ABSA', value: 'absa', icon: AbsaBankIcon },
    tyme_bank: { label: 'Tyme Bank', value: 'tyme-bank', icon: TymeBankIcon },
    rmb: { label: 'RMB', value: 'rmb', icon: RmbBankIcon },
  },
  applicationMethods: {
    apply: 'apply-now',
    quote: 'get-quote',
  },
  uploadMethods: {
    online: 'online',
    statement: 'statement',
  },
  issueTypes: [
    { label: 'Design issue', value: 'design-issue' },
    { label: 'Site is not usable', value: 'site-not-usable' },
    { label: 'Long loading times', value: 'long-loading-times' },
    { label: 'Links are broken', value: 'links-are-broken' },
    { label: 'Authentication issue', value: 'authentication-issue' },
    { label: 'Other please specify', value: 'other-please-specify' },
  ],
  operatingSystems: [
    { label: 'Mac OS', value: 'mac-os' },
    { label: 'Windows (32 bit)', value: 'windows-32-bit' },
    { label: 'Windows (64 bit)', value: 'windows-64-bit' },
    { label: 'Android', value: 'android' },
    { label: 'IOS', value: 'ios' },
    { label: 'Linux', value: 'linux' },
  ],
  browser: [
    { label: 'Google Chrome', value: 'google-chrome' },
    { label: 'Mozilla Firefox', value: 'mozilla-firefox' },
    { label: 'Safari', value: 'safari' },
    { label: 'Edge', value: 'edge' },
  ],
  homeUrl: 'https://vodalend.vodacom.co.za/compare/get-started',
  staticHomeUrl: 'https://www.vodacom.co.za/vodacom/services/financial-services/vodalend-compare',
  faqUrl:
    'https://www.vodacom.co.za/vodacom/services/financial-services/vodalend-compare/faqs?displayMode=Page',
  privacyUrl: 'https://www.vodacom.co.za/vodacom/terms/privacy-policy/payment-services',
  address: {
    maxLength: 255, // according to BillingStreet in Salesforce.
    /** @deprecated */
    provinces: [
      'Eastern Cape',
      'Free State',
      'Gauteng',
      'KwaZulu-Natal',
      'Limpopo',
      'Mpumalanga',
      'Northern Cape',
      'North West',
      'Western Cape',
    ],
    nullObject() {
      return /** @type {AddressInfo} */ ({
        streetAddress: '',
        city: '',
        postalCode: '',
        province: '',
        suburb: '',
        building: '',
      });
    },
  },
  business: {
    name: {
      maxLength: 23,
    },
    /** @deprecated */
    years: [
      { label: '0 - 1 years', value: '0' },
      { label: '1 - 2 years', value: '1' },
      { label: '2 - 5 years', value: '2' },
      { label: '5 - 10 years', value: '5' },
      { label: '10+ years', value: '10' },
    ],
  },
  personal: {
    firstName: {
      maxLength: 40,
    },
  },
  /** @deprecated */
  idTypes: [
    { key: 'id', value: 'RSA ID' },
    { key: 'pp', value: 'Passport' },
  ],
});

export const elementDefaults = Object.freeze({
  nullParagraphElement: /** @type {HTMLParagraphElement} */ (null),
  nullDivElement: /** @type {HTMLDivElement} */ (null),
});

export const errorMessages = Object.freeze({
  business: {
    positionMissing: 'Position in the company is invalid',
  },
  customer: {
    firstName: 'The first name entered is invalid',
    lastName: 'The last name name entered is invalid',
    mobileNumber: 'The mobile number entered is invalid',
    landlineNumber: 'The landline number entered is invalid',
    captcha: 'The captcha test failed. Please try again',
    title: 'Title is missing',
    rsaId: 'The ID number entered is invalid',
    rsaIdLength: 'The ID number is too short',
    passport: 'The passport number entered is invalid',
    email: 'The email entered is invalid',
  },
  email: {
    doublePunctuation: 'Double punctuation error',
    invalid: 'The email entered is invalid',
    maxLength: 'Address is above maximum length requirement',
    minLength: 'Address is below minimum length requirement',
    atSign: 'Missing @ sign',
    multipleAtSigns: 'Too many @ signs',
  },
  general: {
    loan: 'Missing loan term or usage',
    emptySuffix: 'cannot be empty',
    specialCharsSuffix: 'cannot have special characters',
    url: 'The URL entered is invalid',
    amount: 'The amount entered is invalid',
    employmentStatus: 'The employment status selected is invalid',
    employerName: 'The employer name entered is invalid',
    startDate: 'Please enter a valid year',
    employmentNumber: 'The employer contact number is invalid',
    employmentSector: 'The employment sector selected is invalid',
    employmentProvince: 'The province selected is invalid',
    incomeSource: 'Please select an income source',
    incomeFrequency: 'Please select an income frequency',
    grossIncome: 'The gross income entered is invalid',
    estimatedExpenses: 'The estimated expenses entered is invalid',
    issueType: 'Please select an issue type',
    operatingSystem: 'Please select an operating system',
    browser: 'Please select a browser',
    monthlyIncome: 'The monthly income entered is invalid',
    monthlyExpenses: 'The monthly expenses entered is invalid',
  },
  statement: {
    transactionHistory: 'Please upload a valid bank statement with 3 months transaction history.',
    startDate: 'Please upload a valid bank statement no older than 90 days.',
  },
  iam: {
    notFound: 'User does not exist',
    loginFailed: 'Login failed. Either the email address or password was incorrect.',
  },
  onboarding: {
    applicationNotFound: 'Onboarding application was not found.',
    userExists: 'A user with that email already exists.',
  },
  otp: {
    expired: 'OTP has expired - Resend a new OTP',
    incorrect: 'Incorrect OTP',
    tooManyAttempts: 'OTP attempts exceeded',
  },
  password: {
    empty: 'Password field cannot be empty',
    mismatch: 'Passwords do not match',
    minimumLength: 'Passwords must be 8 or more characters',
    unmetRules: 'Passwords must contain uppercase, lowercase and special characters',
  },
  technical: {
    general:
      "Uh oh! We're experiencing technical difficulties, but we're working on it. Please try again later.",
  },
});

export const lendingApplicationMessages = Object.freeze({
  actions: {
    updateApplication: 'UPDATE_APPLICATION',
    unchanged: 'NO_UPDATES',
  },
});

// TODO: these could probably all be joined up with the steps in create-account to create
// 1 cohesive source of steps, screens, and groupings.
export const lendingOnboardingSteps = Object.freeze({
  get_started: 'get_started',
  request: 'loan_request',
  personal_details: 'personal_details',
  employment_details: 'employment_details',
  upload_method: 'upload_method',
  online_bank_select: 'online_banking_select',
  online_bank_portal: 'online_bank_portal',
  upload_bank_statements: 'upload_bank_statements',
  confirm_income: 'confirm_income',
  portal_confirm_income: 'portal_confirm_income',
  offers_details: 'offers_details',
  accept_offer: 'accept_offer',
  offers_suggestions: 'offer_suggestions',
  thank_you: 'thank_you',
});

export const lendingOnboardingStepGroups = Object.freeze({
  request: 'Request',
  personal_details: 'Personal details',
  employment_details: 'Employment details',
  banking: 'Banking',
  offers: 'Offers',
});

export const storageKeys = Object.freeze({
  compare_application: 'compare_application',
  site_key: 'site_key',
});

export const applyNowPartners = Object.freeze({
  boodle: 'Boodle',
  finchoice: 'Finchoice',
  mpowa: 'MPOWA Finance',
});

export const allSteps = [
  {
    group: 'request',
    step: lendingOnboardingSteps.request,
    route: routes.loanRequest,
  },
  {
    group: 'personal_details',
    step: lendingOnboardingSteps.personal_details,
    route: routes.personalDetails,
  },
  {
    group: 'employment_details',
    step: lendingOnboardingSteps.employment_details,
    route: routes.employmentDetails,
  },
  {
    group: 'banking',
    step: lendingOnboardingSteps.upload_method,
    route: routes.uploadMethod,
  },
  {
    group: 'banking',
    step: lendingOnboardingSteps.online_bank_select,
    route: routes.bankSelect,
  },
  {
    group: 'banking',
    step: lendingOnboardingSteps.online_bank_portal,
    route: routes.bankPortal,
  },
  {
    group: 'banking',
    step: lendingOnboardingSteps.upload_bank_statements,
    route: routes.statementUpload,
  },
  {
    group: 'banking',
    step: lendingOnboardingSteps.portal_confirm_income,
    route: routes.confirmIncome,
  },
  {
    group: 'offers',
    step: lendingOnboardingSteps.offers_details,
    route: routes.offers,
  },
  {
    group: 'offers',
    step: lendingOnboardingSteps.offers_suggestions,
    route: routes.suggestions,
  },
  {
    group: 'offers',
    step: lendingOnboardingSteps.thank_you,
    route: routes.thankYou,
  },
];
