import React from 'react';
import { applicationDefaults } from 'utils';
import { Button } from 'components';
import { VodaLendLogoRed } from 'assets';
import './no-entry.scss';

export const NoEntry = () => (
  <article className='page-content-background Wizard full-height page-content'>
    <header className='page-header-common'>
      <img className='vodapay-logo' src={VodaLendLogoRed} alt='' />
    </header>
    <div className='no-entry'>
      <section className='content'>
        <header>
          <h2>Our apologies!</h2>
          <p>
            Looks like you landed on the wrong page. We've updated the link and you can find it
            below
          </p>
        </header>
        <section>
          <Button onClick={() => (window.location.href = applicationDefaults.homeUrl)} primary>
            VodaLend Compare
          </Button>
        </section>
      </section>
    </div>
  </article>
);
