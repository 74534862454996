import React, { useMemo } from 'react';
import { Environment, Environments } from 'Environment';
import { NoEntry } from 'pages';
import { Router } from 'router';
import { Cache } from 'services';
import { storageKeys } from 'utils';
import './app.scss';

export const App = () => {
  const isAuth = useMemo(() => {
    let siteKey = new URLSearchParams(window.location.search).get('siteKey');

    if (!siteKey) {
      siteKey = Cache.fetch(storageKeys.site_key);
    } else {
      Cache.save(storageKeys.site_key, siteKey);
    }

    return (
      Environment.current.name === Environments.Local.name ||
      Environment.current.env === Environments.Prod.env ||
      (siteKey && Environment.current.siteKey === siteKey)
    );
  }, []);

  if (isAuth) {
    return <Router />;
  }
  return <NoEntry />;
};
