import React, { useContext, useState, useEffect } from 'react';
import { LendingContext, WizardContext } from 'context';
import { Carousel, Modal } from 'components';
import { useNavService } from 'hooks';
import { routes } from 'router';
import { TealiumService, request } from 'services';
import { applicationDefaults, formatCurrency } from 'utils';
import { PersonalLoan, TickedCheckbox, UntickedCheckbox } from 'assets';
import './offers.scss';

export const Offers = () => {
  const { application, saveError } = useContext(LendingContext);
  const { setNext, setNextEnabled, forceUpdate } = useContext(WizardContext);
  const [showWhatDoesTheseMean, setWhatDoesTheseMean] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(-1);
  const navigate = useNavService();

  useEffect(() => {
    if (
      application?.request?.applicationMethod === applicationDefaults.applicationMethods.apply &&
      application?.offers?.matches.filter((match) => match.live).length === 0
    ) {
      navigate.replace(routes.offersUnavailable);
    }

    const numOffers = application?.offers?.matches.length || 0;
    TealiumService.onOffersStart(application.request?.applicationMethod, numOffers);
  }, []);

  useEffect(() => {
    const canGoNext = selectedOffer >= 0;
    setNextEnabled(canGoNext);
    setNext(() => canGoNext && save());
    forceUpdate((fresh) => !fresh);
  }, [selectedOffer]);

  async function acceptOffer(selectedOffer) {
    const { hashId, partnerId, liveScore } = selectedOffer;

    const reqBody = {
      hashid: hashId,
      partnerId: partnerId,
      type: application.request.applicationMethod,
    };

    if (applicationDefaults.applicationMethods.apply === application.request.applicationMethod) {
      reqBody.liveScore = liveScore;
    }

    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(reqBody),
    };

    let response = await request('/v1/accept', {
      fetchOptions,
      baseURLOverwrite: null,
      tealiumErrorLogger: null,
      suppressTealiumEventOnError: false,
      showToastOnError: true,
      ignoreErrorCodes: [401],
    });

    return response;
  }

  function save() {
    const index = selectedOffer;
    const offersList = application.offers.matches;

    TealiumService.onOffersEnd(
      application.request?.applicationMethod,
      offersList[index].companyName,
    );

    // updateApplication(lendingOnboardingSteps.offers_details, {
    //   hashId: application.offers.hashId,
    //   ...offersList[index],
    // });

    acceptOffer({
      hashId: application.offers.hashId,
      ...offersList[index],
    });

    let url = offersList[index]?.liveScore?.url || offersList[index]?.url; // || offersList[index].companyWebsiteUrl;

    if (!offersList[index].live || !url) {
      // updateApplication(lendingOnboardingSteps.thank_you);
      navigate.to(routes.thankYou);
      return;
    }

    url = url.startsWith('http') ? url : 'http://' + url;
    if (!saveError) {
      window.location.href = url;
    }
  }

  function toggleGlossary() {
    TealiumService.onLoanGlossary();
    setWhatDoesTheseMean(!showWhatDoesTheseMean);
  }

  function notWhatYoureLookingFor() {
    navigate.to(routes.suggestions);
  }

  const selectOffer = (values) => {
    setSelectedOffer(values);
  };

  const applyNowWorkflow =
    application.request.applicationMethod === applicationDefaults.applicationMethods.apply;

  return (
    <div className='offer-container'>
      {showWhatDoesTheseMean && (
        <Modal onClose={() => setWhatDoesTheseMean(false)} size='medium'>
          <h2 className='glossary-title'>Loan Glossary</h2>
          <section className='glossary-content'>
            <p className=' glossary-term'>Principal amount:</p>
            <p>The actual amount that will be paid into your bank account</p>

            <p className=' glossary-term'>Annual interest rate:</p>
            <p>The annual interest rate charged on the loan by the lender</p>

            <p className=' glossary-term'>Principal debt:</p>
            <p>The sum of the Principal Amount and the Initiation Fee.</p>

            <p className=' glossary-term'>Initiation fee:</p>
            <p>
              The amount charged upfront on the loan. It usually becomes part of the Principal Debt
            </p>

            <p className=' glossary-term'>Administration fee:</p>
            <p>The monthly service fee charged on the loan</p>

            <p className=' glossary-term'>Monthly instalment:</p>
            <p>
              The monthly amount that is due on your loan. If your loan term is less than one month,
              then this will equal the outstanding balance
            </p>

            <p className=' glossary-term'>Total amount payable:</p>
            <p>
              The sum of the principal amount as well as any fees or charges raised in the normal
              repayment cycle
            </p>
          </section>
        </Modal>
      )}
      <main className='offer-panel'>
        <header>
          <h2>Offers available to you</h2>
          <p>Choose the personal loan that is right for you</p>
        </header>
        <Carousel hasArrows activeForDesktop>
          {application?.offers?.matches.map((offer, key) => (
            <article key={key} className={`card ${selectedOffer === key ? 'offer-selected' : ''}`}>
              <img src={offer.companyLogoUrl} alt='Company Logo' className='company-logo' />
              {offer?.live && applyNowWorkflow ? (
                <h3>{`${
                  formatCurrency(offer?.liveScore?.LoanAmount || application.request.loanAmount) ||
                  '0'
                }`}</h3>
              ) : (
                <h3>{`${formatCurrency(application.request.loanAmount)}`}</h3>
              )}

              <img src={PersonalLoan} alt='Personal loan logo' />
              <section className='card-content'>
                {offer?.live && applyNowWorkflow ? (
                  <>
                    <p>Principle Amount</p>
                    <p className='amount'>{`${
                      formatCurrency(offer?.liveScore?.LoanAmount) || 'N/A'
                    }`}</p>

                    {offer?.liveScore?.InterestRate && (
                      <>
                        <p>Annual Interest Rate</p>
                        <p className='amount'>{`${offer?.liveScore?.InterestRate}%`}</p>
                      </>
                    )}

                    {offer?.liveScore?.InitiationFee && (
                      <>
                        <p>Initiation Fee</p>
                        <p className='amount'>{formatCurrency(offer?.liveScore?.InitiationFee)}</p>
                      </>
                    )}

                    {offer?.liveScore?.MonthlyRepayments && (
                      <>
                        <p>Monthly Instalment</p>
                        <p className='amount'>
                          {formatCurrency(offer?.liveScore?.MonthlyRepayments)}
                        </p>
                      </>
                    )}

                    {offer?.liveScore?.AmountPayable && (
                      <>
                        <p>Total Amount Payable</p>
                        <p className='amount'>{formatCurrency(offer?.liveScore?.AmountPayable)}</p>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <p>Chance of success</p>
                    <p>{`${offer?.probability}%`}</p>
                  </>
                )}
              </section>
              <footer>
                <a onClick={toggleGlossary}>What do these mean?</a>

                <label className='checkbox-label'>
                  <input
                    type='checkbox'
                    name='selectOffer'
                    checked={selectedOffer === key}
                    onChange={() => selectOffer(key)}
                    className='is-hidden'
                  />
                  <img
                    src={selectedOffer === key ? TickedCheckbox : UntickedCheckbox}
                    alt='toggle-icon'
                  />
                  <span className='checkbox-text'>Select offer</span>
                </label>
              </footer>
            </article>
          ))}
        </Carousel>
        <footer>
          <a className='teal-anchor' onClick={notWhatYoureLookingFor}>
            <span>Not what you're looking for?</span> ►
          </a>
        </footer>
      </main>
    </div>
  );
};
