import { prettyPrintTealiumEvent } from './tealium-debug';
import { Environment } from 'Environment';
import { getValue, urlQueryParams } from 'helpers';

// Used for logging. Ensure utag.js is loaded.
class Tealium {
  _urlParams = urlQueryParams(window.location.search);
  _debugQueryParam = getValue(this._urlParams, 'debugtagging');
  _queue = [];
  _initialized = false;
  _frequency = 100;
  _timeout = 2000 * this._frequency; // 2 seconds * frequency (milliseconds);
  debug = this._debugQueryParam || false; // use qp debugtagging=true to enable this

  constructor() {
    this._initScriptElement();
    this._run();
  }

  _initScriptElement() {
    const env = Environment.current?.tealium;
    const scriptSrc = `https://tags.tiqcdn.com/utag/vodafone/za-microservice/${env}/utag.js`;
    const scriptElement = document.createElement('script');
    scriptElement.src = scriptSrc;
    scriptElement.type = 'text/javascript';
    document.head.appendChild(scriptElement);
  }

  _run() {
    window.setTimeout(() => {
      if ('utag' in window) {
        this._initialized = true;
        this._timeout = 0;

        this._process();
      }

      this._timeout -= this._frequency;

      if (this._timeout > 0) {
        this._run();
      }
    }, this._frequency);
  }

  // Process queue
  _process() {
    if (!this._initialized) {
      return;
    }

    var action = null;

    action = this._queue.shift();

    while (action) {
      action();
      action = this._queue.shift();
    }
  }

  // Send view event
  view(data) {
    if (!Environment.current?.disabledFeatures?.debugtagging && this.debug) {
      prettyPrintTealiumEvent(data);
      return;
    }

    this._queue.push(() => {
      window.utag.view(data);
    });
    this._process();
  }

  // Send link event
  link(data) {
    if (data.link_name) {
      data.link_id = data.link_name;
    }

    if (!Environment.current?.disabledFeatures?.debugtagging && this.debug) {
      prettyPrintTealiumEvent(data);
      return;
    }

    this._queue.push(() => {
      window.utag.link(data);
    });

    this._process();
  }
}

export const objTealium = new Tealium();
