import React from 'react';
import { InputErrorIcon, BlueTick } from 'assets';
import { AiOutlineClose } from 'react-icons/ai';
import './toast-message.scss';

export const ToastMessage = ({ toast, removeToast }) => (
  <div className={`ToastMessage ${toast.type?.toLowerCase() || ''}`}>
    <div className='columns level is-mobile is-vcentered'>
      <div className={'column'}>
        <div className='field-text has-text-left'>
          {toast.type?.toLowerCase() === 'error' && (
            <img className='error-image' src={InputErrorIcon} alt='Error' />
          )}
          {toast.type?.toLowerCase() === 'info' && (
            <img className='info-image' src={BlueTick} alt='Info' />
          )}
          <span className='message'>
            <p className='message-main content-large-extra'>{toast.message}</p>

            <p className='message-subtitle content-small'>{toast.messageSubtitle}</p>

            {toast.link && (
              <a className='action-text' href={toast.link}>
                {toast.linkText}
              </a>
            )}
            {toast.fn && (
              <a className='action-text' onClick={toast.fn}>
                {toast.fnText}
              </a>
            )}
          </span>
        </div>
      </div>

      {toast.actionButtonText && toast.actionButtonOnClick && (
        <a
          className='column is-narrow action-button'
          onClick={() => {
            removeToast();

            toast.actionButtonOnClick();
          }}
          target='_blank'
        >
          {toast.actionButtonText}
        </a>
      )}

      <div
        className='column is-narrow cross-container has-text-right'
        onClick={() => {
          removeToast();
        }}
      >
        <AiOutlineClose className='icon-icon-close' />
      </div>
    </div>
  </div>
);
