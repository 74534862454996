import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { LendingContext, WizardContext } from 'context';
import { useNavService } from 'hooks';
import { ProgressBar, NavigationFooter } from 'components';
import { applicationDefaults, lendingOnboardingStepGroups } from 'utils';
import './wizard-outlet.scss';

export const WizardOutlet = () => {
  const {
    allSteps,
    steps,
    setSteps,
    nextEnabled,
    backLabel,
    nextLabel,
    backVisible,
    nextVisible,
    onBack,
    onNext,
    currentStep,
    setCurrentStep,
    hideHeader,
    hideFooter,
  } = useContext(WizardContext);
  const { application } = useContext(LendingContext);
  const navigate = useNavService();
  const location = useLocation();

  useEffect(() => {
    setCurrentStep(steps.find((s) => s.route === location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    if (application.request === undefined) {
      return;
    }
    if (application.request.applicationMethod === applicationDefaults.applicationMethods.quote) {
      setSteps(
        steps.filter((step) => step.group !== lendingOnboardingStepGroups.banking.toLowerCase()),
      );
      return;
    }
    setSteps(allSteps);
  }, [application?.request?.applicationMethod]);

  const backClicked = () => {
    if (onBack) {
      onBack();
    } else {
      navigate.back();
    }
  };

  return (
    <>
      {!hideHeader && (
        <ProgressBar
          steps={steps}
          currentStep={currentStep}
          onBack={backClicked}
          hideBack={!backVisible}
        />
      )}
      <article className='wizard-outlet'>
        <Outlet />
      </article>
      {!hideFooter && (
        <NavigationFooter
          onBack={backClicked}
          onNext={onNext}
          nextEnabled={nextEnabled}
          nextButtonLabel={nextLabel}
          backButtonLabel={backLabel}
          hideBack={!backVisible}
          hideNext={!nextVisible}
        />
      )}
    </>
  );
};
