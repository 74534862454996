import React, { createContext, useEffect, useState } from 'react';
import { trim } from 'lodash';
import { FullPageLoader } from 'components';
import { Cache, compareBackend, toastMessageServiceSingleton } from 'services';
import { lendingOnboardingSteps, storageKeys, stringFormat } from 'utils';

const initialState = {
  application: {},
  setApplication: () => {},
};

export const LendingContext = createContext(initialState);

export const LendingContextProvider = ({ children }) => {
  const [application, setApplication] = useState({});
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(false);

  const [saveError, setSaveError] = useState(false);
  const [techError, setTechError] = useState(false);

  useEffect(() => {
    const cachedApp = Cache.fetch(storageKeys.compare_application);

    if (cachedApp) {
      setApplication(cachedApp);
    }
  }, []);

  useEffect(() => {
    if (techError) {
      throw new Error('Technical Error in compare service');
    }
  }, [techError]);

  useEffect(() => {
    if (Object.keys(application).length > 0) {
      Cache.save(storageKeys.compare_application, application);

      compareBackend()
        .saveApplication(application)
        .then((outcome) => {
          setSaveError(!outcome?.successful);
          setTechError(outcome?.techError);

          if (!outcome?.successful && !outcome?.techError) {
            toastMessageServiceSingleton.addError(outcome.message, null, 'N/A', true, null, true);
          }

          setUpdating(false);
        });
    }
  }, [application]);

  const uuidv4 = () =>
    ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    );

  const updateApplication = (step, data) => {
    setUpdating(true);

    switch (step) {
      case lendingOnboardingSteps.get_started:
        setApplication((prevApp) => ({
          ...prevApp,
          lastStep: step,
          canSave: false,
          request: {
            ...prevApp?.request,
            applicationMethod: data.applicationMethod,
          },
        }));
        break;

      case lendingOnboardingSteps.request:
        setApplication((prevApp) => ({
          ...prevApp,
          lastStep: step,
          canSave: false,
          request: {
            ...prevApp?.request,
            loanTerm: data.loanTerm,
            loanAmount: data.loanAmount,
            loanUsage: data.loanUsage,
          },
        }));
        break;

      case lendingOnboardingSteps.personal_details:
        setApplication((prevApp) => ({
          ...prevApp,
          lastStep: step,
          canSave: true,
          personal: {
            firstName: trim(data.firstName),
            lastName: trim(data.lastName),
            idNumber: stringFormat.stripWhitespace(data.idNumber.replaceAll('_', '')),
            clientReference: uuidv4(),
            email: trim(data.email),
            mobileNumber: stringFormat.stripWhitespace(data.mobileNumber),
            consent: data.consentToggle,
          },
        }));
        break;

      case lendingOnboardingSteps.employment_details:
        setApplication((prevApp) => ({
          ...prevApp,
          lastStep: step,
          canSave: true,
          employer: {
            employmentStatus: data.employmentStatus,
            employerName: trim(data.employerName),
            startDate: data.startDate,
            employmentNumber: stringFormat.stripWhitespace(data.employmentNumber),
            employmentSector: data.employmentSector,
            employmentProvince: data.employmentProvince,
            incomeSource: data.incomeSource,
            incomeFrequency: data.incomeFrequency,
            grossIncome: trim(data.grossIncome),
            estimatedExpenses: trim(data.estimatedExpenses),
          },
        }));
        break;

      case lendingOnboardingSteps.upload_method:
        setApplication((prevApp) => ({
          ...prevApp,
          lastStep: step,
          canSave: false,
          bank: {
            ...prevApp?.bank,
            uploadMethod: data,
          },
        }));
        break;

      case lendingOnboardingSteps.online_bank_select:
        setApplication((prevApp) => ({
          ...prevApp,
          lastStep: step,
          canSave: true,
          bank: { ...prevApp?.bank, bankName: data },
        }));
        break;

      case lendingOnboardingSteps.online_bank_portal:
        setApplication((prevApp) => ({
          ...prevApp,
          lastStep: step,
          canSave: true,
        }));
        break;

      case lendingOnboardingSteps.upload_bank_statements:
        setApplication((prevApp) => ({
          ...prevApp,
          lastStep: step,
          canSave: true,
          bank: { ...prevApp?.bank, bankStatements: data.documents },
        }));
        break;

      case lendingOnboardingSteps.portal_confirm_income:
        setApplication((prevApp) => ({
          ...prevApp,
          lastStep: step,
          canSave: true,
          bank: {
            ...prevApp?.bank,
            summary: {
              ...prevApp?.bank?.summary,
              averages: {
                ...prevApp?.bank?.summary?.averages,
                averageIncomes: trim(data.monthlyIncome),
                averageExpenses: trim(data.monthlyExpenses),
              },
            },
          },
        }));
        break;

      case lendingOnboardingSteps.offers_details:
        setApplication((prevApp) => ({
          ...prevApp,
          lastStep: step,
          canSave: true,
          selectedOffer: data,
        }));
        break;

      case lendingOnboardingSteps.offers_suggestions:
        setApplication((prevApp) => ({
          ...prevApp,
          lastStep: step,
          canSave: false,
        }));
        break;

      case lendingOnboardingSteps.thank_you:
        setApplication((prevApp) => ({
          ...prevApp,
          lastStep: step,
          canSave: true,
        }));
        break;

      default:
        throw new Error(`update profile step not implemented: ${step}`);
    }
  };

  return (
    <LendingContext.Provider
      value={{
        application,
        setApplication,
        updateApplication,
        updating,
        setUpdating,
        loading,
        setLoading,
        saveError,
        setSaveError,
      }}
    >
      <FullPageLoader showLoader={loading || updating} />
      {children}
    </LendingContext.Provider>
  );
};
