import React, { useState, useEffect, useRef, useMemo } from 'react';
import { property } from 'lodash';
import { getIn } from 'formik';
import MaskedInput from 'react-text-mask';
import Autocomplete from 'react-autocomplete';
import { InputErrorIcon } from 'assets';
import './floating-input.scss';

/**
 * getItemDisplayValue(item) // mandatory for select or autocomplete)
 *  function that maps an item to a string that is displayed in the drop down
 *
 * onInputValueChange(newValue) // optional for select or autocomplete
 *  hook function that is called when the user changes the input value
 *
 * onItemSelect(item) // optional for autocomplete
 *  hook function that is called when the user selects an item from the drop down
 *
 * @typedef {Object & React.ReactElement} FloatingInput
 * @property {import('formik').Field} field
 * @property {("select"|"autocomplete"|"standard"|"masked"|"id")=} fieldType
 *  defaults to standard
 * @property {Boolean=} filterItemsWithInputValue
 *  optional for autocomplete - defaults to false
 *  when set to true, items in the list are filtered using the current input value,
 *  by only displaying list items for which the current input value is a substring within
 *  an item. This comparison is case-insensitive.
 * @property {import('formik').Form} form
 * @property {Boolean=} hasNoAfterLine
 *  when set to true, the line appearing ::after the autocomplete-input class will not appear. defaults to false
 * @property {Boolean=} isDark
 *  setting to true enables dark theme. Defaults to false
 * @property {any[]=} items
 *  array of dropdown objects. mandatory for select or autocomplete
 * @property {String=} placeholder
 * @property {String=} styleIconClose
 *  optional for select or autocomplete - defaults to generic close icon
 *  name of css class to override the default close icon, which is displayed
 *  when the drop down is open.
 * @property {String=} styleIconOpen
 *  optional for select or autocomplete - defaults to generic down arrow
 *  name of css class to override the down arrow icon, which is displayed
 *  when the drop down is open.
 * @property {String=} containerClassName Added to the container for additional styling options. Only works for autocomplete for now.
 * @property {String=} subtext
 *  If not empty, will be displayed underneath field when no error is displayed.
 * @property {Number=} maxLength
 *
 * @param {FloatingInput} inputProps
 * @returns
 */
export const FloatingInput = ({ field, form, ...props }) => {
  const [isActive, setIsActive] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [hasIDBeenTouched, setHasIDBeenTouched] = useState(false);

  const autocompleteWrapperRef = useRef(null);
  const autocompleteRef = useRef(/** @type {Autocomplete} */ (null));
  const [isInputDirty, setIsInputDirty] = useState(false); // has input value changed after selecting an item?

  let { placeholder, fieldType, isDark, hasNoAfterLine, containerClassName, ...newProps } = props;

  const errorValue = useMemo(() => getIn(form.errors, field.name), [field.name, form.errors]);
  const touchedValue = useMemo(() => getIn(form.touched, field.name), [field.name, form.touched]);

  const pipe = (conformedValue) => conformedValue;

  useEffect(() => {
    setIsActive(field.value !== '' || props.autoFocus);
  }, [field.value, props.autoFocus]);

  const handleEvents = (event) => {
    if (!autocompleteWrapperRef.current || !autocompleteRef.current) {
      return;
    }

    if (autocompleteWrapperRef.current.contains(event.target)) {
      if (event.type === 'keyup' && event.key) {
        const key = event.key.toLowerCase();

        if (key === 'arrowdown' && !isDropDownOpen) {
          setIsDropDownOpen(true);
        }

        if (fieldType === 'select') {
          const itemIndex = newProps.items.findIndex((item) =>
            newProps.getItemDisplayValue(item).toLowerCase().startsWith(key),
          );
          if (itemIndex >= 0) {
            autocompleteRef.current.setState({
              ...autocompleteRef.current.state,
              highlightedIndex: itemIndex,
            });
          }
        }
      }
    } else {
      setIsDropDownOpen(false);
    }
  };

  useEffect(() => {
    if (fieldType === 'select' || fieldType === 'autocomplete') {
      const eventTypes = ['click', 'keyup'];
      eventTypes.map((eventType) => document.addEventListener(eventType, handleEvents, true));
      return () =>
        eventTypes.map((eventType) => document.removeEventListener(eventType, handleEvents, true));
    }
  }, []);

  const buildStandard = () => {
    const passwordInputProps = {
      type: isPasswordVisible ? 'standard' : 'password',
      className: `${props.className} ${isPasswordVisible ? '' : 'password'}`,
    };

    return props.type === 'password' ? (
      <>
        <span
          className={`input-password-right-icon ${
            isPasswordVisible ? 'icon-eye-hide' : 'icon-eye-show'
          }`}
          onClick={() => setIsPasswordVisible((isVisible) => !isVisible)}
        />
        <input
          {...field}
          {...newProps}
          {...passwordInputProps}
          onFocus={() => setIsActive(true)}
          onBlur={(e) => {
            field.onBlur(e);
            setIsActive(field.value !== '');
          }}
        />
      </>
    ) : (
      <input
        {...field}
        {...newProps}
        onFocus={() => setIsActive(true)}
        onBlur={(e) => {
          field.onBlur(e);
          setIsActive(field.value !== '');
        }}
      />
    );
  };

  const buildDropDown = ({
    value,
    items,
    getItemDisplayValue,
    placeholder,
    isAutocomplete,
    filterItemsWithInputValue,
    disabled,
  }) => {
    !Array.isArray(items) &&
      console.error('Cannot build dropdown with null items', placeholder, items);
    const itemsWithKey = items.map((item, index) => ({
      originalItem: item,
      key: index,
    }));

    const getValueFromItemWithKey = (itemWithKey) => getItemDisplayValue(itemWithKey.originalItem);

    return (
      <div
        className='autocomplete-wrapper'
        ref={autocompleteWrapperRef}
        onClick={(e) => {
          !isDropDownOpen && setIsDropDownOpen(true);
          props.onClick && props.onClick(e);
        }}
      >
        <Autocomplete
          ref={autocompleteRef}
          className='input create-account-input'
          open={!disabled && isDropDownOpen}
          value={value}
          autoHighlight={false}
          items={itemsWithKey}
          inputProps={{ autoFocus: !!props.autoFocus, maxLength: newProps.maxLength || 255 }}
          wrapperProps={{
            className: `autocomplete-container-floating-input control ${containerClassName}`,
          }}
          getItemValue={(item) => getValueFromItemWithKey(item)}
          shouldItemRender={(item, value) => {
            if (isAutocomplete && filterItemsWithInputValue && getValueFromItemWithKey) {
              return getValueFromItemWithKey(item).toLowerCase().indexOf(value?.toLowerCase()) > -1;
            } else {
              return item.originalItem;
            }
          }}
          onSelect={(value, item) => {
            setIsActive(true);
            const autoCompleteFormValues =
              isAutocomplete && props.onItemSelect ? props.onItemSelect(item.originalItem) : null;
            if (autoCompleteFormValues) {
              form.validateForm(autoCompleteFormValues);
            } else {
              form.setFieldValue(field.name, getValueFromItemWithKey(item));
              if (props.onItemSelect) {
                props.onItemSelect(item.originalItem);
              }
            }
            if (isAutocomplete) {
              setIsInputDirty(false);
            }
            setIsDropDownOpen(false);
          }}
          onBlur={(event) => {
            setIsDropDownOpen(false);
            if (isAutocomplete && isInputDirty) {
              props.onItemSelect(null);
            }

            if (newProps.onBlur) {
              newProps.onBlur({ ...event, dontCallFormikHandleBlur: true });
            }
          }}
          onChange={(event, value) => {
            if (isAutocomplete) {
              setIsDropDownOpen(true);

              if (!isInputDirty) {
                if (props.onItemSelect) {
                  props.onItemSelect(null);
                }
                setIsInputDirty(true);
              }

              form.setFieldValue(field.name, value || '');
              form.setFieldTouched(field.name, true);
              if (props.onInputValueChange) {
                props.onInputValueChange(value);
              }
            }
            setIsActive(true);
          }}
          renderMenu={(items, value, style) => (
            <div className='autocomplete-list' style={{ ...style }}>
              {items}
            </div>
          )}
          renderItem={(item, isHighlighted) => (
            <div
              key={item.key}
              className={`autocomplete-item has-padding-x-5 has-padding-y-7 ${
                isDark ? 'dark' : ''
              } ${isHighlighted ? 'highlighted' : ''}`}
            >
              {getValueFromItemWithKey(item)}
            </div>
          )}
          renderInput={(props) => (
            <div
              className={`autocomplete-input ${
                !disabled && errorValue && touchedValue ? 'errorState' : ''
              }`}
            >
              {(disabled || !isDropDownOpen) && (
                <span
                  className={`drop-down-closed-right-icon ${
                    newProps.styleIconOpen ? newProps.styleIconOpen : 'icon-drop-down-arrow-reskin'
                  }`}
                />
              )}

              {!disabled && isDropDownOpen && (
                <span
                  className={`drop-down-closed-right-icon ${
                    newProps.styleIconClose
                      ? newProps.styleIconClose
                      : 'icon-close-icon-white-small'
                  }`}
                  onClick={() => {
                    if (!disabled) {
                      setIsDropDownOpen(false);
                    }
                  }}
                />
              )}
              <input
                {...props}
                onFocus={(e) => {
                  const evt = { ...e }; // because of synthetic events
                  // trying to force focus to come later.
                  setTimeout(() => {
                    if (props.onFocus) {
                      props.onFocus(evt);
                    }
                    if (newProps.onFocus) {
                      newProps.onFocus(evt);
                    }
                  }, 10);
                }}
                disabled={disabled}
                onClick={(e) => {
                  e.target.select();
                }}
                className={`auto-input ${isAutocomplete ? '' : 'no-caret'} ${
                  isDark ? 'dark' : ''
                } ${!disabled && isDropDownOpen ? 'is-expanded' : ''} ${newProps.className || ''}`}
                readOnly={!isAutocomplete}
              />
              <label
                className={`f-label ${isActive ? 'Active' : ''} ${
                  !disabled && isDropDownOpen ? 'is-expanded' : ''
                }`}
              >
                {placeholder}
              </label>
            </div>
          )}
        />
      </div>
    );
  };

  let errorState;
  let dynamicInput;
  switch (fieldType) {
    case 'autocomplete':
    case 'select':
      dynamicInput = buildDropDown({
        ...field,
        ...newProps,
        placeholder: placeholder,
        isAutocomplete: fieldType === 'autocomplete',
        filterItemsWithInputValue: newProps.filterItemsWithInputValue || false,
        disabled: newProps.disabled || false,
      });
      errorState = !newProps.disabled && errorValue && touchedValue;
      break;
    case 'standard':
      dynamicInput = buildStandard();
      errorState = errorValue && touchedValue;
      break;

    case 'masked':
      dynamicInput = (
        <MaskedInput
          {...field}
          {...newProps}
          onFocus={() => setIsActive(true)}
          onBlur={(e) => {
            field.onBlur(e);
            setIsActive(field.value !== '');
          }}
          pipe={pipe}
        />
      );
      errorState = errorValue && touchedValue;

      break;
    case 'id':
      dynamicInput = (
        <MaskedInput
          {...field}
          {...newProps}
          onBlur={(event) => {
            setHasIDBeenTouched(true);
            if (newProps.onBlur) {
              newProps.onBlur(event);
            }
          }}
          pipe={pipe}
        />
      );

      errorState = hasIDBeenTouched && property(props.index)(form.errors);
      break;

    default:
      dynamicInput = <input {...field} {...newProps} />;
      errorState = errorValue && touchedValue;
      break;
  }

  return (
    <div className={`field-component ${props.spacing ? props.spacing : ''}`}>
      <div
        id='float-label'
        className={`${!props.disabled && errorState ? 'errorState' : ''} ${
          hasNoAfterLine ? '' : 'has-after-line'
        }`}
      >
        {dynamicInput}

        <label className={isActive ? 'Active' : ''} htmlFor={field.name}>
          {placeholder}
        </label>
      </div>
      {!props.disabled && fieldType !== 'id' && errorValue && touchedValue && (
        <div className='field-error'>
          <img className='error-image' src={InputErrorIcon} alt='Error' />
          <span>{errorValue}</span>
        </div>
      )}
      {!!props.subtext && !(errorValue && touchedValue) && (
        <div className='field-error'>
          <span>{props.subtext}</span>
        </div>
      )}
    </div>
  );
};

export default FloatingInput;
