import {
  routePageMap,
  routeLinkMap,
  routeServiceNameMap,
  routeLinkMapQuote,
  routePageMapQuote,
  routeServiceNameMapQuote,
  objTealium,
  applicationDefaults,
} from 'utils';

export class TealiumService {
  static landingPageName = 'vodalend compare: landing page';
  static loanPageName = 'vodalend compare: apply now: loan details';
  static quoteLoanPageName = 'vodalend compare: quick quote: loan details';
  static personalPageName = 'vodalend compare: apply now: personal details';
  static quotePersonalPageName = 'vodalend compare: quick quote: personal details';
  static employmentPageName = 'vodalend compare: apply now: employment details';
  static quoteEmploymentPageName = 'vodalend compare: quick quote: employment details';
  static bankingPageName = 'vodalend compare: apply now: banking details';
  static selectBankPageName = 'vodalend compare: apply now: banking details: choose bank';
  static bankPortalPageName = 'vodalend compare: apply now: banking details: choose bank: login';
  static confirmIncomePageName =
    'vodalend compare: apply now: banking details: choose bank: login: income details: next';
  static uploadStatementsPageName = 'vodalend compare: apply now: banking details: manual upload';
  static offerPageName = 'vodalend compare: apply now: view offers';
  static quoteOfferPageName = 'vodalend compare: quick quote: view offers';
  static offerSuggestionPageName = 'vodalend compare: experience feedback';
  static offerThankYouPageName = 'vodalend compare: apply now: thank you';
  static quoteOfferThankYouPageName = 'vodalend compare: quick quote: thank you';

  static attempt = (func) => {
    try {
      func();
    } catch (err) {
      console.error(err);
    }
  };

  static sendViewToTealium(obj) {
    this.attempt(() => {
      objTealium.view(obj);
    });
  }

  static sendLinkToTealium(obj) {
    this.attempt(() => {
      objTealium.link(obj);
    });
  }

  static onBack(requestType, route) {
    const eventData =
      requestType === applicationDefaults.applicationMethods.apply
        ? {
            page_name: routePageMap[route],
            service_application_name: routeServiceNameMap[route],
            link_name: routeLinkMap[route],
          }
        : {
            page_name: routePageMapQuote[route],
            service_application_name: routeServiceNameMapQuote[route],
            link_name: routeLinkMapQuote[route],
          };
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      service_application_type: 'transaction',
      ...eventData,
    });
  }

  /**
   *
   * Form Events
   *
   */
  static onFormError(requestType, errorMessage, route) {
    const page_name =
      requestType === applicationDefaults.applicationMethods.apply
        ? routePageMap[route]
        : routePageMapQuote[route];
    this.sendLinkToTealium({
      page_form_event: 'error',
      page_name,
      page_form_name: page_name,
      page_errors: errorMessage,
      page_error_type: 'user error',
      page_error_code: '-1',
    });
  }

  static onFormInteraction(requestType, route) {
    const page_name =
      requestType === applicationDefaults.applicationMethods.apply
        ? routePageMap[route]
        : routePageMapQuote[route];
    this.sendLinkToTealium({
      page_form_event: 'started',
      page_name,
      page_form_name: page_name,
    });
  }

  /**
   *
   * Page name: landing page
   * Page url: /compare/get-started
   *
   * */
  static onLandingStart() {
    this.sendViewToTealium({
      event_name: 'view',
      page_name: this.landingPageName,
      service_application_name: 'vodalend compare: landing page',
      service_application_type: 'transaction',
      service_application_event: 'start',
    });
  }

  static onApply() {
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      page_name: this.landingPageName,
      service_application_name: 'vodalend compare: landing page',
      service_application_type: 'transaction',
      service_application_event: 'finish',
      link_name: 'vodalend compare: landing page: apply now',
    });
  }

  static onQuote() {
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      page_name: this.landingPageName,
      service_application_name: 'vodalend compare: landing page',
      service_application_type: 'transaction',
      service_application_event: 'finish',
      link_name: 'vodalend compare: landing page: quick quote',
    });
  }

  /**
   *
   * Page name: request
   * Page url: /compare/loan?request=<apply/quote>
   *
   * */
  static onLoanDetailStart(requestType) {
    const eventData =
      requestType === applicationDefaults.applicationMethods.apply
        ? {
            page_name: this.loanPageName,
            service_application_name: 'vodalend compare: apply now: loan details',
          }
        : {
            page_name: this.quoteLoanPageName,
            service_application_name: 'vodalend compare: quick quote: loan details',
          };
    this.sendViewToTealium({
      event_name: 'view',
      service_application_type: 'transaction',
      service_application_event: 'start',
      ...eventData,
    });
  }

  static onLoanDetailEnd(requestType, amount, termAmount, loanUsage) {
    const eventData =
      requestType === applicationDefaults.applicationMethods.apply
        ? {
            page_name: this.loanPageName,
            service_application_name: 'vodalend compare: apply now: loan details',
            link_name: 'vodalend compare: apply now: loan details: apply now',
          }
        : {
            page_name: this.quoteLoanPageName,
            service_application_name: 'vodalend compare: quick quote: loan details',
            link_name: 'vodalend compare: quick quote: loan details: continue',
          };
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      service_application_type: 'transaction',
      service_application_event: 'finish',
      loan_amount: amount,
      term_amount: termAmount,
      load_usage: loanUsage,
      ...eventData,
    });
  }

  /**
   *
   * Page name: personal-details
   * Page url: /compare/personal-details
   *
   * */
  static onPersonalDetailsStart(requestType) {
    const eventData =
      requestType === applicationDefaults.applicationMethods.apply
        ? {
            page_name: this.personalPageName,
            service_application_name: 'vodalend compare: apply now: personal details',
          }
        : {
            page_name: this.quotePersonalPageName,
            service_application_name: 'vodalend compare: quick quote: personal details',
          };
    this.sendViewToTealium({
      event_name: 'view',
      service_application_type: 'transaction',
      service_application_event: 'start',
      ...eventData,
    });
  }

  static onPersonalDetailsCheckbox(requestType) {
    const eventData =
      requestType === applicationDefaults.applicationMethods.apply
        ? {
            page_name: this.personalPageName,
            page_tool_name: 'vodalend compare: apply now: personal details: consent',
          }
        : {
            page_name: this.quotePersonalPageName,
            page_tool_name: 'vodalend compare: quick quote: personal details: consent',
          };
    this.sendLinkToTealium({
      event_name: 'link',
      page_tool_event: 'use',
      ...eventData,
    });
  }

  static onPersonalDetailsEnd(requestType) {
    const eventData =
      requestType === applicationDefaults.applicationMethods.apply
        ? {
            page_name: this.personalPageName,
            service_application_name: 'vodalend compare: apply now: personal details',
            link_name: 'vodalend compare: apply now: personal details: continue',
            page_form_name: 'vodalend compare: apply now: personal details',
          }
        : {
            page_name: this.quotePersonalPageName,
            service_application_name: 'vodalend compare: quick quote: personal details',
            link_name: 'vodalend compare: quick quote: personal details: continue',
            page_form_name: 'vodalend compare: quick quote: personal details',
          };
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      service_application_type: 'transaction',
      service_application_event: 'finish',
      ...eventData,
    });
  }

  /**
   *
   * Page name: employment-details
   * Page url: /compare/business-details
   *
   * */
  static onEmploymentDetailsStart(requestType) {
    const eventData =
      requestType === applicationDefaults.applicationMethods.apply
        ? {
            page_name: this.employmentPageName,
            service_application_name: 'vodalend compare: apply now: employment details',
          }
        : {
            page_name: this.quoteEmploymentPageName,
            service_application_name: 'vodalend compare: quick quote: employment details',
          };
    this.sendViewToTealium({
      event_name: 'view',
      service_application_type: 'transaction',
      service_application_event: 'start',
      ...eventData,
    });
  }

  static onEmploymentDetailsDropDown(requestType, employmentStatus) {
    const eventData =
      requestType === applicationDefaults.applicationMethods.apply
        ? {
            page_name: this.employmentPageName,
            page_tool_name: 'vodalend compare: apply now: employment details',
            link_name: `vodalend compare: apply now: employment details: ${employmentStatus}`,
            page_form_name: 'vodalend compare: apply now: employment details',
          }
        : {
            page_name: this.quoteEmploymentPageName,
            page_tool_name: 'vodalend compare: quick quote: employment details',
            link_name: `vodalend compare: quick quote: employment details: ${employmentStatus}`,
            page_form_name: 'vodalend compare: quick quote: employment details',
          };
    this.sendLinkToTealium({
      page_tool_event: 'use',
      page_form_event: 'started',
      ...eventData,
    });
  }

  static onEmploymentDetailsEnd(requestType) {
    const eventData =
      requestType === applicationDefaults.applicationMethods.apply
        ? {
            page_name: this.employmentPageName,
            service_application_name: 'vodalend compare: apply now: employment details',
            link_name: 'vodalend compare: apply now: employment details: next',
            page_form_name: 'vodalend compare: apply: employment details',
          }
        : {
            page_name: this.quoteEmploymentPageName,
            service_application_name: 'vodalend compare: quick quote: employment details',
            link_name: 'vodalend compare: quick quote: employment details: next',
            page_form_name: 'vodalend compare: quick quote: employment details',
          };
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      service_application_type: 'transaction',
      service_application_event: 'finish',
      ...eventData,
    });
  }

  /**
   *
   * Page name: banking-details
   * Page url: /compare/banking-details
   *
   * */
  static onBankingDetailsStart() {
    this.sendViewToTealium({
      event_name: 'view',
      page_name: this.bankingPageName,
      service_application_name: 'vodalend compare: apply now: banking details',
      service_application_type: 'transaction',
      service_application_event: 'start',
    });
  }

  static onBankingDetailsEnd(userChoice) {
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      page_name: this.bankingPageName,
      service_application_name: 'vodalend compare: apply now: banking details',
      service_application_type: 'transaction',
      link_name: `vodalend compare: apply now: banking details: ${userChoice}`,
    });
  }

  /**
   *
   * Page name: select-online-banking
   * Page url: /compare/bank/select
   *
   * */
  static onSelectBankStart() {
    this.sendViewToTealium({
      event_name: 'view',
      page_name: this.selectBankPageName,
      service_application_name: 'vodalend compare: apply now: banking details: choose bank',
      service_application_type: 'transaction',
      service_application_event: 'start',
    });
  }

  static onSelectBank(bankChoice) {
    this.sendLinkToTealium({
      event_name: 'link',
      page_tool_event: 'use',
      page_tool_name: 'vodalend compare: apply now: banking details: choose bank',
      page_name: this.selectBankPageName,
      link_name: `vodalend compare: apply now: banking details: choose bank: ${bankChoice}`,
      bank_name: bankChoice,
    });
  }

  static onSelectBankEnd() {
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      page_name: this.selectBankPageName,
      service_application_name: 'vodalend compare: apply now: banking details: choose bank',
      service_application_type: 'transaction',
      link_name: 'vodalend compare: apply now: banking details: choose bank: next',
    });
  }

  /**
   *
   * Page name: bank-portal
   * Page url: /compare/bank/portal
   *
   * */
  static onBankPortalStart() {
    this.sendViewToTealium({
      event_name: 'view',
      page_name: this.bankPortalPageName,
      service_application_event: 'start',
      service_application_name: 'vodalend compare: apply now: banking details: choose bank',
      service_application_type: 'transaction',
    });
  }

  static onBankPortalEnd() {
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      page_name: this.bankPortalPageName,
      service_application_name: 'vodalend compare: apply now: banking details: choose bank',
      service_application_type: 'transaction',
      link_name: 'vodalend compare: apply now: banking details: choose bank: login: continue',
    });
  }

  /**
   *
   * Page name: confirm-income
   * Page url: /compare/confirm-income
   *
   * */
  static onConfirmIncomeStart() {
    this.sendViewToTealium({
      event_name: 'view',
      page_name: this.confirmIncomePageName,
      service_application_name: 'vodalend compare: apply now: banking details: choose bank',
      service_application: 'transaction',
    });
  }
  static onConfirmIncomeEnd(monthlyIncome, monthlyExpenses) {
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      page_name: this.confirmIncomePageName,
      service_application_name: 'vodalend compare: apply now: banking details: choose bank',
      service_application_type: 'transaction',
      link_name:
        'vodalend compare: apply now: banking details: choose bank: login: income details: next',
      monthly_income: monthlyIncome,
      monthly_expenses: monthlyExpenses,
    });
  }

  /**
   *
   * Page name: upload-statements
   * Page url: /compare/bank/upload
   *
   * */
  static onUploadStatementsStart() {
    this.sendViewToTealium({
      event_name: 'view',
      page_name: this.uploadStatementsPageName,
      service_application_name: 'vodalend compare: apply now: banking details: manual upload',
      service_application_type: 'transaction',
    });
  }

  static onUploadStatement() {
    this.sendLinkToTealium({
      page_tool: 'use',
      page_name: this.uploadStatementsPageName,
      page_tool_name: 'vodalend compare: apply now: banking details: manual upload',
      link_name: 'vodalend compare: apply now: banking details: manual upload: attach document',
    });
  }

  static onUploadStatementsEnd() {
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      page_name: this.uploadStatementsPageName,
      service_application_name: 'vodalend compare: apply now: banking details: manual upload',
      service_application_type: 'transaction',
      link_name: 'vodalend compare: apply now: banking details: manual upload: next',
    });
  }

  /**
   *
   * Page name: offers
   * Page url: /compare/offers
   *
   * */
  static onOffersStart(requestType, noOffers) {
    const eventData =
      requestType === applicationDefaults.applicationMethods.apply
        ? {
            page_name: this.offerPageName,
            service_application_name: 'vodalend compare: apply now: view offers',
          }
        : {
            page_name: this.quoteOfferPageName,
            service_application_name: 'vodalend compare: quick quote: product select',
          };
    this.sendViewToTealium({
      event_name: 'view',
      service_application_event: 'start',
      service_application_type: 'transaction',
      number_of_offers: noOffers,
      ...eventData,
    });
  }

  static onOffersEnd(requestType, productOption) {
    const eventData =
      requestType === applicationDefaults.applicationMethods.apply
        ? {
            page_name: this.offerPageName,
            page_event: 'vodalend_applynow',
            service_application_name: 'vodalend compare: apply now: view offers',
            link_name: 'vodalend compare: apply now: product select: continue',
          }
        : {
            page_name: this.quoteOfferPageName,
            page_event: 'vodalend_quote',
            service_application_name: 'vodalend compare: quick quote: product select',
            link_name: 'vodalend compare: quick quote: product select: continue',
          };
    this.sendLinkToTealium({
      link_type: 'o',
      service_application_type: 'transaction',
      service_application_event: 'finish',
      product_option: productOption,
      ...eventData,
    });
  }

  static onOfferRedirect(requestType, companyName) {
    this.sendViewToTealium({
      event_name: 'view',
      page_name: `vodalend compare: ${
        requestType === applicationDefaults.applicationMethods.apply ? 'apply now' : 'quick quote'
      }: ${companyName} redirect: success`,
    });
  }

  /**
   *
   * Page name: suggestions
   * Page url: /compare/offers/suggestions
   *
   * */
  static onOfferSuggestionStart() {
    this.sendViewToTealium({
      event_name: 'view',
      page_name: this.offerSuggestionPageName,
      service_application_event: 'start',
      service_application_name: "vodalend compare: not what you're looking for",
      service_application_type: 'query',
    });
  }

  static onOfferSuggestionExit() {
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      page_name: this.offerSuggestionPageName,
      link_name: 'vodalend compare:experience feedback: exit',
    });
  }

  static onOfferSuggestionSubmit(optionSelected) {
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      page_name: this.offerSuggestionPageName,
      link_name: `vodalend compare: experience feedback: ${optionSelected}`,
      service_application_event: 'finish',
      service_application_name: "vodalend compare: not what you're looking for",
      service_application_type: 'query',
    });
  }

  /**
   *
   * Page name: loan glossary
   * Page url: /compare/offers
   *
   * */
  static onLoanGlossary() {
    this.sendViewToTealium({
      event_name: 'view',
      page_name: 'vodalend compare: apply now: product select: loan glossary',
      service_application_name: 'vodalend compare: apply now: product select: loan glossary',
      service_application_type: 'transaction',
    });
  }

  /**
   *
   * Page name: thank you
   * Page url: /compare/offers/thank-you
   *
   * */
  static onThankYouStart(requestType) {
    const eventData =
      requestType === applicationDefaults.applicationMethods.apply
        ? {
            page_name: this.offerThankYouPageName,
            page_event: 'view, vodalend_applynow',
            service_application_name: 'vodalend compare: apply now: thank you',
          }
        : {
            page_name: this.quoteOfferThankYouPageName,
            page_event: 'view, vodalend_quote',
            service_application_name: 'vodalend compare: quick quote: thank you',
          };
    this.sendViewToTealium(eventData);
  }

  /**
   *
   * Page name: offer-failed
   * Page url: /compare/offers/unsuccessful
   *
   * */
  static onApplicationFailedStart() {
    this.sendViewToTealium({
      event_name: 'view',
      page_name: 'vodalend compare: application unsuccessful',
      service_application_name: 'vodalend compare: application unsuccessful',
      service_application_type: 'transaction',
      service_application_event: 'start',
    });
  }

  static onApplicationFailedExit() {
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      page_name: 'vodalend compare: application unsuccessful',
      link_name: 'vodalend compare: application unsuccessful: exit',
      service_application_name: 'vodalend compare: application unsuccessful',
      service_application_type: 'transaction',
      service_application_event: 'finish',
    });
  }

  static onApplicationFailedReportIssue() {
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      page_name: 'vodalend compare: application unsuccessful',
      link_name: 'vodalend compare: application unsuccessful: report an issue',
      service_application_name: 'vodalend compare: application unsuccessful',
      service_application_type: 'transaction',
      service_application_event: 'finish',
    });
  }

  /**
   *
   * Page name: technical-error
   *
   * */
  static onTechnicalErrorStart() {
    this.sendViewToTealium({
      event_name: 'view',
      page_name: 'vodalend compare: technical error',
      service_application_name: 'vodalend compare: technical error',
      service_application_type: 'transaction',
      service_application_event: 'start',
    });
  }

  static onTechnicalTryAgain() {
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      page_name: 'vodalend compare: technical error',
      link_name: 'vodalend compare: technical error: exit',
      service_application_name: 'vodalend compare: technical error',
      service_application_type: 'transaction',
      service_application_event: 'finish',
    });
  }

  static onTechnicalErrorExit() {
    this.sendLinkToTealium({
      event_name: 'link',
      link_type: 'o',
      page_name: 'vodalend compare: technical error',
      link_name: 'vodalend compare: technical error: try again',
      service_application_name: 'vodalend compare: technical error',
      service_application_type: 'transaction',
      service_application_event: 'finish',
    });
  }
}
