import { createNumberMask } from 'text-mask-addons';
import { DateTime } from 'luxon';
import { stripWhitespace } from 'helpers';
import { applicationDefaults } from './constants';

const alphaNumeric = /[a-zA-Z0-9]/;

export const htmlFormat = {
  decode(input) {
    var doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  },
};

export const langFormat = {
  /** Return null if a = b, else return a */
  nullIf(a, b) {
    return a === b ? null : a;
  },
};

export const stringFormat = {
  stripWhitespace(value) {
    if (value && typeof value === 'string' && value !== '') {
      return value.replace(/ /g, '');
    } else {
      return value;
    }
  },
};

export const masks = {
  businessRegistration: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
  ],
  postalCode: [/\d/, /\d/, /\d/, /\d/],
  rsaId: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
  passport: Array.from(Array(13).keys()).map((i) => alphaNumeric),
  phoneNumber: ['+', '2', '7', /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  phoneNumberSpaced: [
    '+',
    '2',
    '7',
    ' ',
    /[1-9]/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  year: [/[1-2]/, /[0-9]/, /\d/, /\d/],
  currencyAmount: createNumberMask({
    prefix: 'R ',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ' ',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 9,
    allowNegative: false,
    allowLeadingZeroes: false,
    inputMode: 'numeric',
  }),
};

export const offersFormat = (offersData) =>
  offersData.matches.flatMap(
    ({ id, probability, companyLogoUrl, companyName, companyWebsiteUrl, liveScore, offerType }) => {
      const hasLiveOffer = liveScore && liveScore !== -2;
      const e2eLender = offerType === applicationDefaults.applicationMethods.apply;

      if (hasLiveOffer) {
        if (Array.isArray(liveScore.offers)) {
          return liveScore.offers.map((offerItem) => ({
            partnerId: id,
            probability,
            companyLogoUrl,
            companyName,
            companyWebsiteUrl,
            live: true,
            e2eLender,
            liveScore: offerItem,
          }));
        } else {
          return {
            partnerId: id,
            probability,
            companyLogoUrl,
            companyName,
            companyWebsiteUrl,
            live: true,
            e2eLender,
            liveScore,
          };
        }
      } else {
        return {
          partnerId: id,
          probability,
          companyLogoUrl,
          companyName,
          companyWebsiteUrl,
          live: false,
        };
      }
    },
  );

export const stripRandSymbolPrefix = (text) => (text ? text.toString().replace(/R/g, '') : text);

export const parseCurrency = (turnover) =>
  Number.parseFloat(stripWhitespace(stripRandSymbolPrefix(turnover)) || '0');

/**
 *
 * @param {string} startDate - the start date
 * @param {string} endDate - the end date
 * @param {string | Array} filter - which values to filter by
 * @returns {Object} returns the difference between the dates by the filter
 */
export const calcDateDiff = (startDate, endDate, filter) => {
  const start = DateTime.fromISO(startDate);
  const end = DateTime.fromISO(endDate);
  const dateDiff = end.diff(start, filter);
  return dateDiff.toObject();
};

export const formatCurrency = (number) => {
  if (!number) {
    return false;
  }
  const formatter = new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
    currencyDisplay: 'symbol',
  });

  return formatter.format(number);
};
