import React, { useContext, useEffect } from 'react';
import { LendingContext } from 'context';
import { NavigationFooter } from 'components';
import { TealiumService } from 'services';
import { applicationDefaults } from 'utils';
import { MessageSent, VodaLendLogoRed } from 'assets';
import './thank-you.scss';

export const ThankYou = () => {
  const { application } = useContext(LendingContext);
  useEffect(() => {
    if (!application) {
      return;
    }
    TealiumService.onThankYouStart(application?.request?.applicationMethod);
  }, [application]);

  return (
    <article className='page-content-background'>
      <section className='Wizard full-height page-content'>
        <header className='page-header-common'>
          <img
            className='vodapay-logo'
            src={VodaLendLogoRed}
            style={{ width: '40px' }}
            alt='VodaPay'
          />
        </header>
        <div className='thank-you'>
          <section className='content'>
            <h2>Thank you for applying</h2>
            <p>
              You will receive an SMS or call from the partner you have chosen with further
              instructions and details about your application
            </p>
            <img src={MessageSent} alt='Message sent' />
          </section>
        </div>
        <NavigationFooter
          nextButtonLabel='VodaLend home'
          backButtonLabel="FAQ's"
          onNext={() => (window.location.href = applicationDefaults.staticHomeUrl)}
          onBack={() => (window.location.href = applicationDefaults.faqUrl)}
          hideSaveForLater
          hideCancel
          nextEnabled
        />
      </section>
    </article>
  );
};
