import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import { redLoader } from 'assets';
import './loader-animation.scss';

export const LoaderAnimation = (props) => {
  const element = useRef(null);
  const lottieInstance = useRef();
  const [style, setStyle] = useState({});

  useEffect(() => {
    const styleNew = {};

    if (props.width) {
      styleNew.width = props.width;
    }

    if (props.height) {
      styleNew.height = props.height;
    }

    const params = {
      loop: true,
      autoplay: true,
      animationData: redLoader,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
      ...styleNew,
    };

    setStyle(styleNew);

    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        ...params,
        container: element.current,
      });
    }
  }, []);

  return <div className='LoaderAnimation' style={style} ref={element} />;
};
