import { distance } from 'fastest-levenshtein';
import { tealiumSpec } from './tealium-spec';

const isVariableFieldValue = (field) =>
  field && field.trim && field.trim().startsWith('<') && field.trim().endsWith('>');

const getFieldsCompareScore = (fieldA, fieldB) => {
  if (fieldA === fieldB || isVariableFieldValue(fieldA) || isVariableFieldValue(fieldB)) {
    return 1;
  } else if (!fieldA || !fieldB) {
    return 0;
  } else {
    const dist = distance(fieldA, fieldB);
    return 1 - dist / Math.max(fieldA.length, fieldB.length);
  }
};

const getEventsCompareScore = (eventA, eventB) => {
  const keys = Object.keys({ ...eventA, ...eventB });
  let similarityScore = 0;

  keys.map((key) => {
    similarityScore += getFieldsCompareScore(eventA[key], eventB[key]);
  });

  return (similarityScore / keys.length) * 100;
};

const getBestMatchingEventFromSpec = (event, tealiumSpec) => {
  let bestScore = 0;
  let bestEvent = {};

  tealiumSpec.map((specEvent) => {
    const score = getEventsCompareScore(event, specEvent);

    if (score > bestScore) {
      bestScore = score;
      bestEvent = specEvent;
    }
  });

  return {
    bestScore: bestScore,
    bestEvent: bestEvent,
  };
};

export const prettyPrintTealiumEvent = (obj) => {
  const objString = JSON.stringify(obj, undefined, 2);
  console.log('------------------------------------------------');
  console.log(`tagging event fired on page ${window.location.href}\n`);
  if (tealiumSpec) {
    const { bestEvent, bestScore } = getBestMatchingEventFromSpec(obj, tealiumSpec);
    console.log(
      `best match percentage in spec: ${bestScore}% (you can expand matched event object on the next line)`,
    );
    console.dir(bestEvent);
  }
  console.log('------------------------------------------------');
  console.log(objString);
  console.log('\n\n');
};
