import React, { createContext, useRef, useState } from 'react';
import { allSteps } from 'utils';

export const WizardContext = createContext({});

export const WizardContextProvider = ({ children }) => {
  const [steps, setSteps] = useState(allSteps);
  const [currentStep, setCurrentStep] = useState(null);

  const [backEnabled, setBackEnabled] = useState(true);
  const [nextEnabled, setNextEnabled] = useState(true);

  const [backLabel, setBackLabel] = useState('Back');
  const [nextLabel, setNextLabel] = useState('Next');

  const [backVisible, setBackVisible] = useState(true);
  const [nextVisible, setNextVisible] = useState(true);

  const [hideHeader, setHideHeader] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);

  const [, reRender] = useState(false);

  const onBack = useRef(undefined);
  const onNext = useRef(() => {});

  const setBack = (func) => (onBack.current = func);
  const setNext = (func) => (onNext.current = func);

  return (
    <WizardContext.Provider
      value={{
        allSteps,
        steps,
        setSteps,
        backEnabled,
        setBackEnabled,
        nextEnabled,
        setNextEnabled,
        backLabel,
        setBackLabel,
        nextLabel,
        setNextLabel,
        backVisible,
        setBackVisible,
        nextVisible,
        setNextVisible,
        onBack: onBack.current,
        setBack,
        onNext: onNext.current,
        setNext,
        currentStep,
        setCurrentStep,
        hideHeader,
        setHideHeader,
        hideFooter,
        setHideFooter,
        forceUpdate: reRender,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};
