import { applicationDefaults, parseCurrency } from 'utils';

export const getValueOfLoanTermSelected = (values) => {
  if (values?.loanTerm) {
    return applicationDefaults.loanTerms.find((obj) => obj.label === values?.loanTerm)?.value;
  } else {
    return 1;
  }
};

export const getInitiationFee = (loanAmount) => {
  const initiationFee =
    (165 + (loanAmount - 1000) * 0.1) * 1.15 > loanAmount * 0.15
      ? loanAmount * 0.15 * 1.15
      : (165 + (loanAmount - 1000) * 0.1) * 1.15;

  return initiationFee > 1297 ? 1297 : initiationFee;
};

export const getMonthlyRepaymentAmount = (values) => {
  const loanAmount =
    parseInt(parseCurrency(values.loanAmount)) +
    getInitiationFee(parseInt(parseCurrency(values.loanAmount)));
  const creditLife = (loanAmount / 1000) * 4.5 * 1.15;

  // admin fee calculation provided by business
  const adminFee = 60 * 1.15;
  const interestRate = 0.23 / 12;
  const loanTerm = getValueOfLoanTermSelected(values);

  // pvif stands for present value interest factor
  const pvif = Math.pow(1 + interestRate, loanTerm);
  const installments = (interestRate * loanAmount * pvif) / (pvif - 1);

  return installments + adminFee + creditLife;
};
