import React, { useEffect, useRef, useState } from 'react';
import { CarouselLeftIcon, CarouselRightIcon } from 'assets';
import './carousel.scss';

export const Carousel = ({
  hasArrows = false,
  hasScrollbar = false,
  activeForDesktop = false,
  children,
}) => {
  const carouselRef = useRef(HTMLDivElement);
  const [isLeft, setIsLeft] = useState(true);
  const [isRight, setIsRight] = useState(false);

  const getLeftLimit = () => Math.floor(carouselRef.current?.getBoundingClientRect().left || 0);
  const getRightLimit = () => Math.ceil(carouselRef.current?.getBoundingClientRect().right || 0);

  useEffect(
    () =>
      carouselRef.current?.addEventListener('scroll', () =>
        setTimeout(() => {
          const leftX = Math.round(
            carouselRef.current?.firstElementChild?.getBoundingClientRect().x || 0,
          );

          const lastBlock = carouselRef.current?.lastElementChild?.getBoundingClientRect();
          const rightX = Math.round(Number(lastBlock?.x) + Number(lastBlock?.width));

          setIsLeft(leftX >= getLeftLimit());
          setIsRight(rightX <= getRightLimit());
        }, 100),
      ),
    [],
  );

  const leftArrowClicked = () => carouselRef.current?.scrollBy({ left: -280, behavior: 'smooth' });
  const rightArrowClicked = () => carouselRef.current?.scrollBy({ left: 280, behavior: 'smooth' });

  return (
    <article className={`carousel-container ${activeForDesktop ? 'only-desktop' : ''}`}>
      {hasArrows && (
        <img
          className={`carousel-button ${isLeft ? 'disabled' : 'enabled'}`}
          src={CarouselLeftIcon}
          alt='left-arrow'
          onClick={leftArrowClicked}
        />
      )}
      <section id='carousel' className={hasScrollbar ? '' : 'hide-scrollbar'} ref={carouselRef}>
        {children}
      </section>
      {hasArrows && (
        <img
          className={`carousel-button ${isRight ? 'disabled' : 'enabled'}`}
          src={CarouselRightIcon}
          alt='right-arrow'
          onClick={rightArrowClicked}
        />
      )}
    </article>
  );
};
