import React, { useContext, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import { LendingContext, WizardContext } from 'context';
import { useNavService } from 'hooks';
import { routes } from 'router';
import { FloatingInput } from 'components';
import { getMonthlyRepaymentAmount, getValueOfLoanTermSelected, TealiumService } from 'services';
import {
  errorMessages,
  applicationDefaults,
  lendingOnboardingSteps,
  htmlFormat,
  masks,
  parseCurrency,
  validate,
} from 'utils';
import { formatCurrencyAmount } from 'helpers';
import './request.scss';

export const Request = () => {
  const { setNext, setNextEnabled } = useContext(WizardContext);
  const { application, updateApplication } = useContext(LendingContext);
  const navigate = useNavService();

  useEffect(() => {
    TealiumService.onLoanDetailStart(application.request?.applicationMethod);
  }, []);

  function isFormCompleted(values) {
    return (
      !validate.lang.isBlank(values.loanTerm) &&
      !validate.lang.isBlank(values.loanUsage) &&
      validate.number.maybeValid(parseCurrency(values.loanAmount).toString())
    );
  }

  function getInitialValues() {
    const request = application?.request;
    const loanTerm =
      request && applicationDefaults.loanTerms.find((obj) => obj.value === request.loanTerm);
    return {
      loanAmount: request?.loanAmount || '',
      loanTerm: loanTerm?.label || '',
      loanUsage: request?.loanUsage || '',
    };
  }

  function validateForm({ loanAmount, loanTerm, loanUsage }) {
    const errors = {};

    if (!loanAmount || !validate.number.loanAmount(parseCurrency(loanAmount))) {
      errors.loanAmount = errorMessages.general.amount;
    }

    if (!loanTerm || !loanUsage) {
      errors.loan = errorMessages.general.loan;
    }

    return errors;
  }

  function save(values) {
    values.loanTerm = getValueOfLoanTermSelected(values);
    values.loanAmount = parseCurrency(values.loanAmount);

    TealiumService.onLoanDetailEnd(
      application.request?.applicationMethod,
      values.loanAmount,
      values.loanTerm,
      values.loanUsage,
    );

    updateApplication(lendingOnboardingSteps.request, values);
    navigate.to(routes.personalDetails);
  }

  return (
    <section className='request-container'>
      <article className='request-panel'>
        <header>
          <h2>How much would you like to borrow?</h2>
          <p>
            Please indicate how much you would like to borrow so we can get estimates from our
            lenders
          </p>
        </header>
        <section>
          <Formik
            innerRef={(formik) => formik && setNext(formik.submitForm)}
            initialValues={getInitialValues() || {}}
            initialErrors={isFormCompleted({}) ? {} : { incomplete: 'true' }}
            validate={validateForm}
            validateOnChange
            validateOnBlur={false}
            validateOnMount
            enableReinitialize
            onSubmit={save}
          >
            {({ values, setFieldValue, errors, isSubmitting, validateForm }) => {
              const canGoNext =
                !isSubmitting && !validate.hasErrors(errors) && isFormCompleted(values);
              setTimeout(() => {
                setNextEnabled(canGoNext);
              }, 1);

              return (
                <Form>
                  <Field
                    name='loanAmount'
                    type='text'
                    placeholder='Loan amount (R500 - R350,000)'
                    component={FloatingInput}
                    fieldType='masked'
                    mask={(e) => masks.currencyAmount(e)}
                    className='top-field'
                    autoFocus
                  />
                  <Field
                    name='loanTerm'
                    type='text'
                    placeholder='Loan term'
                    component={FloatingInput}
                    items={applicationDefaults.loanTerms}
                    getItemDisplayValue={(item) => htmlFormat.decode(item.label)}
                    filterItemsWithInputValue
                    fieldType='select'
                    className='top-field'
                  />
                  <Field
                    name='loanUsage'
                    type='text'
                    placeholder='Loan usage'
                    component={FloatingInput}
                    items={applicationDefaults.loanUsage}
                    getItemDisplayValue={(item) => htmlFormat.decode(item.label)}
                    filterItemsWithInputValue
                    fieldType='select'
                    className='top-field'
                  />
                  {!validate.hasErrors(errors) && isFormCompleted(values) && (
                    <section className='monthly-repayment-block'>
                      <h3 className='monthly-repayment-title'>Approximate monthly repayment</h3>
                      <p className='monthly-repayment-amount'>
                        R {formatCurrencyAmount(getMonthlyRepaymentAmount(values))}*
                      </p>
                      <p className='monthly-repayment-tcs'>
                        *For display purposes, monthly repayments are calculated using a 23% per
                        annum interest rate.
                        <br />
                        Actual pricing may differ depending on the risk assessment for the
                        individual lender.
                      </p>
                    </section>
                  )}
                </Form>
              );
            }}
          </Formik>
        </section>
      </article>
    </section>
  );
};
