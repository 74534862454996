import { flatten, isEmpty, merge, toPath } from 'lodash';
import { errorMessages } from './constants';

const langValidations = {
  isBlank: (value, noTrim = false) => {
    switch (true) {
      case value === null || value === undefined:
        return true;
      case Object.prototype.toString.call(value) === '[object String]':
        return (noTrim ? value : value.trim()).length === 0;
      case Object.prototype.toString.call(value) === '[object Date]':
        return false;
      default:
        return isEmpty(value);
    }
  },
  isString: (value) => Object.prototype.toString.call(value) === '[object String]',
  maybeNonEmpty:
    (value, fieldName, noTrim = false) =>
    () =>
      langValidations.isBlank(value, noTrim)
        ? `${fieldName} ${errorMessages.general.emptySuffix}`
        : true,
};

const emailValidations = {
  noDoublePunctuation(value) {
    return !/([^a-zA-Z\d\s:]{2,})/.test(value);
  },
  belowMaxLength(value, length) {
    return !value || value.length <= length;
  },
  aboveMinLength(value, length) {
    return !value || value.length >= length;
  },
  hasAtSign(value) {
    return !value || value.includes('@');
  },
  noMultipleAtSign(value) {
    return !value || value.match(/@/g).length <= 1;
  },
  hasValidSyntax(value) {
    return (
      value &&
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,64}@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(value)
    );
  },
};

const passwordValidations = {
  isNotEmpty(value) {
    return value && value.trim();
  },
  areEqual(value, confirmValue) {
    return value && confirmValue && value === confirmValue;
  },
  minLength(value) {
    return value && value.length >= 8;
  },
  matchPolicyRules(value) {
    if (value) {
      const lowercase = /[a-z]/.test(value);
      const uppercase = /[A-Z]/.test(value);
      const numerical = /[0-9]/.test(value);
      const specialCharacters = /[<>?\\/*+!@$#%^&*()=}{\][|\\|~:;,_]/.test(value);

      return (
        (lowercase ? 1 : 0) +
          (uppercase ? 1 : 0) +
          (numerical ? 1 : 0) +
          (specialCharacters ? 1 : 0) >=
        3
      );
    }
    return false;
  },
};

const phoneValidations = {
  maybeValid(value) {
    return () => value && /^\+27\d{9}$/.test(value);
  },
};

const stringValidations = {
  alphaCharsOnly(value) {
    return value && /^[a-zA-Z- öç_-]+$/.test(value);
  },
  alphaNumericCharsOnly(value) {
    return value && /^[a-zA-Z0-9]+$/.test(value);
  },
  businessNameChars(value) {
    return value && /^[a-zA-Z- öç_%&*().@-]+$/.test(value);
  },
  noSpecialChars(value) {
    return value && !/[<>?\\/*+!@$#%^&*()=}{\][|\\|~:;,_]/.test(value);
  },
  numericalCharsOnly(value) {
    return value && /^[0-9]+$/.test(value);
  },
};

const idValidations = {
  rsaIdMaybeValid:
    (value, preClean = true) =>
    () => {
      const testValue = preClean ? (value || '').replaceAll('_', '').replaceAll(' ', '') : value;
      const valid =
        testValue &&
        /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))$/.test(
          testValue,
        );
      return valid ? idCheckSum(testValue) : false;
    },
  passportMaybeValid:
    (value, preClean = true) =>
    () => {
      const testValue = preClean ? (value || '').replaceAll('_', '').replaceAll(' ', '') : value;
      return (
        !!testValue &&
        testValue.length >= 6 &&
        testValue.length < 13 &&
        stringValidations.alphaNumericCharsOnly(testValue)
      );
    },
};

function idCheckSum(idString) {
  let control = -1;
  try {
    let a = 0;
    for (let i = 0; i < 6; i++) {
      a += parseInt(idString[2 * i]);
    }

    let b = 0;
    for (let i = 0; i < 6; i++) {
      b = b * 10 + parseInt(idString[2 * i + 1]);
    }
    b *= 2;
    let bAsString = b.toString();
    let c = 0;
    for (let i = 0; i < bAsString.length; i++) {
      c += parseInt(bAsString[i]);
    }
    c += a;
    control = 10 - (c % 10);
    if (control === 10) {
      control = 0;
    }
  } catch {
    /* ignore*/
  }
  return parseInt(idString.slice(-1)) === control;
}

export const validate = {
  create(defaultError = {}) {
    const testFn = (
      /** @type {String} */ key,
      /** @type {(...rest) => Boolean | String} */ validationFn,
      /** @type {String} */ message = 'validation missing',
    ) => {
      const toObject = (msg) => {
        const paths = toPath(key);
        const obj = paths
          .reverse()
          .reduce((prev, curr) => (!isNaN(parseInt(curr)) ? [prev] : { [curr]: prev }), msg);
        return obj;
      };

      const result = validationFn(key);
      switch (true) {
        case langValidations.isString(result):
          return validate.create(merge({ ...defaultError }, toObject(result)));
        default:
          return validate.create(
            result ? defaultError : merge({ ...defaultError }, toObject(message)),
          );
      }
    };
    return /** @type {ValidationObject} */ ({
      ...defaultError,
      build() {
        return JSON.parse(JSON.stringify(defaultError));
      },
      test: testFn,
      testMany({ collectionName, fieldName, validationFn, collection, messageFn }) {
        const results = collection.map((item, idx) =>
          testFn(
            `${collectionName}[${idx}].${fieldName}`,
            validationFn(item, idx, collection),
            messageFn(item),
          ),
        );

        const consolidatedArray = flatten(results.map((r) => r[collectionName]));
        return validate.create(merge({ ...defaultError }, { [collectionName]: consolidatedArray }));
      },
    });
  },
  hasErrors(errors) {
    return Object.keys(errors).some((k) => {
      switch (true) {
        case k === 'incomplete':
          return errors[k] === 'true';
        case typeof errors[k] === 'function':
          return false;
        default:
          return Array.isArray(errors[k]) ? errors[k].some((e) => !!e) : !!errors[k];
      }
    });
  },
  collection: {
    maybeValid: (/** @type {any[]} */ list, value) => () => list.some((l) => l === value),
  },
  email: {
    maybeValid: (value) => () => {
      const prefix = (message) => `Invalid Email Address: ${message}`;
      switch (false) {
        case emailValidations.hasValidSyntax(value):
          return prefix(errorMessages.email.invalid);
        case emailValidations.noDoublePunctuation(value):
          return prefix(errorMessages.email.doublePunctuation);
        case emailValidations.belowMaxLength(value, 100):
          return prefix(errorMessages.email.maxLength);
        case emailValidations.aboveMinLength(value, 6):
          return prefix(errorMessages.email.minLength);
        case emailValidations.hasAtSign(value):
          return prefix(errorMessages.email.atSign);
        case emailValidations.noMultipleAtSign(value):
          return prefix(errorMessages.email.multipleAtSigns);
        default:
          return true;
      }
    },
  },
  id: idValidations,
  lang: langValidations,
  name: {
    maybeValid:
      (value, optional = false) =>
      () => {
        if (optional) {
          return true;
        }
        if ((value || '').trim().length === 0 && !optional) {
          return false;
        }
        if (!stringValidations.alphaCharsOnly(value)) {
          return false;
        }
        return true;
      },
  },
  number: {
    maybeValid:
      (value, optional = false) =>
      () => {
        if (optional) {
          return true;
        }
        if ((value || '').length === 0 && !optional) {
          return false;
        }
        if (!stringValidations.numericalCharsOnly(value)) {
          return false;
        }
        return true;
      },
    loanAmount: (value) => {
      const amount = parseFloat(value);
      return amount >= 500 && value <= 350000;
    },
  },
  password: {
    maybeValid: (value, confirmValue) => () => {
      switch (false) {
        case passwordValidations.isNotEmpty(value):
          return errorMessages.password.empty;
        case passwordValidations.areEqual(value, confirmValue):
          return errorMessages.password.mismatch;
        case passwordValidations.minLength(value):
          return errorMessages.password.minimumLength;
        case passwordValidations.matchPolicyRules(value):
          return errorMessages.password.unmetRules;
        default:
          return true;
      }
    },
  },
  phone: phoneValidations,
  string: stringValidations,
};
