import React, { useContext, useState, useEffect } from 'react';
import { LendingContext, WizardContext } from 'context';
import { routes } from 'router';
import { useNavService } from 'hooks';
import { FileUpload } from 'components';
import { TealiumService, toastMessageServiceSingleton } from 'services';
import { lendingOnboardingSteps } from 'utils';
import './upload-statements.scss';

export const UploadStatements = () => {
  const { updateApplication, updating, saveError } = useContext(LendingContext);
  const { setNext, setNextEnabled } = useContext(WizardContext);
  const FILE_SIZE_LIMIT = 3_072_000;
  const [documents, setDocuments] = useState('');
  const [nextClicked, setNextClicked] = useState(false);
  const navigate = useNavService();

  useEffect(() => {
    TealiumService.onUploadStatementsStart();
  }, []);

  useEffect(() => {
    if (nextClicked && !updating && !saveError) {
      navigate.to(routes.confirmIncome);
    }
  }, [nextClicked, updating, saveError]);

  useEffect(() => {
    if (documents.documents?.length > 3) {
      toastMessageServiceSingleton.addError('Too many files', null, 'N/A', true, null, true);
    }

    const canGoNext = documents.documents?.length === 1 || documents.documents?.length === 3;
    setNext(save);
    setNextEnabled(canGoNext);
  }, [documents]);

  function save() {
    TealiumService.onUploadStatementsEnd();
    updateApplication(lendingOnboardingSteps.upload_bank_statements, documents);

    setNextClicked(true);
  }

  const newDocumentUpload = (document) => {
    setDocuments(document);
  };

  return (
    <div className='upload-statements-container'>
      <article className='upload-statements-panel'>
        <header>
          <h2>Upload your bank statements</h2>
          <p>Please upload your most recent 3 month bank statement.</p>
        </header>
        <FileUpload
          title='Upload document'
          subtext='Please upload PDF files'
          fileLimitText='Maximum size: 3MB'
          documentType='application/pdf'
          sizeLimit={FILE_SIZE_LIMIT}
          allowedFileTypes={['application/pdf']}
          maxAllowedFiles={3}
          unsupportedFileTypeErrorMessage='Upload failed, We only support PDF file type. Please try again.'
          currentDocuments={documents}
          setCurrentDocuments={newDocumentUpload}
          setDeletedDocs={() => {}}
          onDocumentUpload={() => TealiumService.onUploadStatement()}
        />
      </article>
    </div>
  );
};
