import React, { useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import { useNavService } from 'hooks';
import { routes } from 'router';
import { NavigationFooter, FloatingInput } from 'components';
import { TealiumService } from 'services';
import { applicationDefaults, htmlFormat } from 'utils';
import { VodaLendLogoRed } from 'assets';
import './suggestions.scss';

export const Suggestions = () => {
  const [enableNext, setEnableNext] = useState(false);
  const [answer, setAnswer] = useState('');
  const navigate = useNavService();

  function validateForm(values) {
    setEnableNext(values.offerRejectionReason);
    setAnswer(values.offerRejectionReason);
    return enableNext;
  }

  useEffect(() => {
    TealiumService.onOfferSuggestionStart();
  }, []);

  function handleBack() {
    TealiumService.onOfferSuggestionExit();
    navigate.back();
  }

  function handleNext() {
    TealiumService.onOfferSuggestionSubmit(answer);
    navigate.to(routes.suggestionsSent);
  }

  return (
    <section className='suggestions-page'>
      <header>
        <img className='vodapay-logo' src={VodaLendLogoRed} alt='VodaPay' />
      </header>

      <section className='suggestions-container'>
        <article className='suggestions-panel'>
          <header>
            <h2>Not what you're looking for?</h2>
          </header>
          <section>
            <Formik
              initialValues={{ offerRejectionReason: 'Please make a selection' }}
              initialErrors={{}}
              validate={validateForm}
              validateOnChange
              validateOnBlur={false}
              enableReinitialize
              onSubmit={() => {}}
            >
              <Form>
                <Field
                  name='offerRejectionReason'
                  type='text'
                  placeholder='Why were the offers not right for you?'
                  component={FloatingInput}
                  items={applicationDefaults.rejectionReason}
                  getItemDisplayValue={(item) => htmlFormat.decode(item.label)}
                  filterItemsWithInputValue
                  validateOnChange
                  fieldType='select'
                  className='top-field'
                />
              </Form>
            </Formik>
          </section>
        </article>
        <NavigationFooter
          onBack={handleBack}
          onNext={handleNext}
          nextEnabled={enableNext}
          nextButtonLabel='Submit'
          backButtonLabel='Back'
          hideNext={false}
          hideBack={false}
          hideCancel
        />
      </section>
    </section>
  );
};
