export const Environments = {
  Local: {
    name: 'Local',
    env: 'dev',
    uiHost: 'http://localhost:3000',
    apiHost: 'https://vodalenddevapi.vodacom.co.za',
    apiBase: '/',
    siteKey: 'dbb94de6-16c5-4169-b8da-65fbebaf0077',
    tealium: 'dev',
  },
  Dev: {
    name: 'Dev',
    env: 'dev',
    uiHost: 'https://vodalenddev.vodacom.co.za',
    apiHost: 'https://vodalenddevapi.vodacom.co.za',
    apiBase: '/',
    siteKey: '3cc9728d-53fa-4b6e-8abe-67546ed64e29',
    tealium: 'dev',
  },
  QA: {
    name: 'QA',
    env: 'qa',
    uiHost: 'https://vodalendqa.vodacom.co.za',
    apiHost: 'https://vodalendqaapi.vodacom.co.za',
    apiBase: '/',
    siteKey: '90b9aff2-1cb6-4d26-b01a-4324e9068c95',
    tealium: 'dev',
  },
  Prod: {
    name: 'Production',
    env: 'prod',
    uiHost: 'https://vodalend.vodacom.co.za',
    apiHost: 'https://vodalendapi.vodacom.co.za',
    apiBase: '/',
    tealium: 'prod',
  },
};

Environments.Staging = {
  ...Environments.Prod,
  env: 'staging',
  siteKey: '72be572f-0b6d-41fc-a1ca-45915d215c1e',
  uiHost: 'https://vodalendstaging.vodacom.co.za',
  apiHost: 'https://vodalendstagingapi.vodacom.co.za',
};

export const Environment = {
  current: Environments.Prod,
};

const envs = {
  local: 'local',
  dev: 'dev',
  qa: 'qa',
  staging: 'staging',
  prod: 'prod',
};

const envIds = {
  [envs.local]: 'localhost',
  [envs.dev]: 'dev.vodacom.co.za',
  [envs.qa]: 'qa.vodacom.co.za',
  [envs.staging]: 'staging.vodacom.co.za',
};

export const initEnv = (host) => {
  if (host.includes(envIds.local)) {
    Environment.current = Environments.Local;
    return envs.local;
  }

  if (host.includes(envIds.dev)) {
    Environment.current = Environments.Dev;
    return envs.dev;
  }

  if (host.includes(envIds.qa)) {
    Environment.current = Environments.QA;
    return envs.qa;
  }

  if (host.includes(envIds.staging)) {
    Environment.current = Environments.Staging;
    return envs.staging;
  }

  Environment.current = Environments.Prod;
  return envs.prod;
};

initEnv(window.location.host);
