import React from 'react';
import { CloseIcon } from 'assets';
import './modal.scss';
import { useFixedPage } from 'hooks';

export const Modal = ({ visible = true, size = 'large', onClose, children }) => {
  useFixedPage(visible);

  return visible ? (
    <article className='address-modal-overlay'>
      <section className={`modal-box ${size}`}>
        {onClose && <img src={CloseIcon} alt='X' className='close-icon' onClick={onClose} />}

        <section className='scroll-box'>{children}</section>
      </section>
    </article>
  ) : null;
};
