class ToastMessageService {
  setAddMethod(addMethod) {
    this.addMethod = addMethod;
  }

  setRemoveAllMethod(removeAllMethod) {
    this.removeAll = removeAllMethod;
  }

  addToast(toast) {
    if (this.addMethod && typeof this.addMethod === 'function') {
      this.addMethod(toast);
    }
  }

  removeAllToasts() {
    if (this.removeAll && typeof this.removeAll === 'function') {
      this.removeAll();
    }
  }

  /**
   * @param {Object} details
   * @param {String} details.message
   * @param {String} [details.consoleMessage]
   * @param {'ERROR'|'INFO'} [details.severity]
   * @param {String} [details.subtitle]
   * @param {String} [details.code]
   * @param {Boolean} [details.isUserError]
   * @param {String} [details.tealiumError]
   * @param {Boolean} [details.suppressTealiumEvent]
   */
  addMessage(details) {
    const {
      message,
      severity,
      consoleMessage,
      subtitle,
      code,
      isUserError,
      tealiumError,
      suppressTealiumEvent,
    } = {
      code: 'N/A',
      severity: 'ERROR',
      isUserError: true,
      suppressTealiumEvent: true,
      ...details,
    };

    if (consoleMessage) {
      // TODO: push to logging framework (WIP)
      console.error(consoleMessage);
    }
    const toast = {
      message: message,
      type: severity,
      errorCode: code,
      isUserError: isUserError,
      tealiumError: tealiumError,
      suppressTealiumEvent: suppressTealiumEvent,
      messageSubtitle: subtitle,
    };
    this.addToast(toast);
  }

  /** @deprecated Dropped in favour of addMessage() */
  addError(
    message,
    logMessage,
    code = 'N/A',
    isUserError = false,
    tealiumError = null,
    suppressTealiumEvent = false,
    messageSubtitle = null,
  ) {
    if (logMessage) {
      console.error(logMessage);
    }
    const toast = {
      message: message,
      type: 'ERROR',
      errorCode: code,
      isUserError: isUserError,
      tealiumError: tealiumError,
      suppressTealiumEvent: suppressTealiumEvent,
      messageSubtitle: messageSubtitle,
    };
    this.addToast(toast);
  }
}

export const toastMessageServiceSingleton = new ToastMessageService();
