import React, { useContext, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { LendingContext, WizardContext } from 'context';
import { routes } from 'router';
import { useNavService } from 'hooks';
import { FloatingInput } from 'components';
import { TealiumService } from 'services';
import {
  applicationDefaults,
  errorMessages,
  lendingOnboardingSteps,
  htmlFormat,
  masks,
  parseCurrency,
} from 'utils';
import { InfoIcon } from 'assets';
import './confirm-income.scss';

export const ConfirmIncome = () => {
  const { application, updateApplication, updating, saveError } = useContext(LendingContext);
  const { setNext, setNextEnabled, setBack } = useContext(WizardContext);
  const [nextClicked, setNextClicked] = useState(false);
  const navigate = useNavService();

  useEffect(() => {
    setBack(() => navigate.back(2));
    TealiumService.onConfirmIncomeStart();

    return () => setBack(undefined);
  }, []);

  useEffect(() => {
    if (nextClicked && !updating && !saveError) {
      navigate.to(routes.offers);
    }
  }, [nextClicked, updating]);

  function validateForm(values) {
    const { monthlyIncome, monthlyExpenses, mainIncome } = values;
    const errors = {};

    if (!monthlyIncome || monthlyIncome <= 0) {
      errors.monthlyIncome = errorMessages.general.monthlyIncome;
    }

    if (!mainIncome) {
      errors.mainIncome = errorMessages.general.incomeSource;
    }

    if (!monthlyExpenses || monthlyExpenses <= 0) {
      errors.monthlyExpenses = errorMessages.general.monthlyExpenses;
    }

    return errors;
  }

  function isFormCompleted(values) {
    return Object.keys(validateForm(values)).length === 0;
  }

  function save(values) {
    values.monthlyIncome = parseCurrency(values.monthlyIncome);
    values.monthlyExpenses = parseCurrency(values.monthlyExpenses);

    TealiumService.onConfirmIncomeEnd(values.monthIncome, values.monthlyExpenses);
    updateApplication(lendingOnboardingSteps.portal_confirm_income, values);

    setNextClicked(true);
  }

  function getBankAccountSummary() {
    const summary = application.bank?.summary?.averages;
    if (summary) {
      return {
        monthlyIncome: summary.averageIncomes,
        monthlyExpenses: summary.averageExpenses,
        mainIncome: mapIncomeType(summary.frequentIncomeType),
      };
    }
  }

  function mapIncomeType(incomeType) {
    switch (incomeType) {
      case 'salary-wages': {
        return 'Salary';
      }
      case 'inheritance': {
        return 'Inheritance';
      }
      case 'commission': {
        return 'Commission';
      }
      default:
        return '';
    }
  }

  return (
    <section className='confirm-income-container'>
      <article className='confirm-income-panel'>
        <header>
          <h2>Confirm income details</h2>
          <p>
            Please check that the below details which we retrieved from your bank statements are
            correct
          </p>
        </header>
        <section>
          <Formik
            innerRef={(formik) => formik && setNext(formik.submitForm)}
            initialValues={getBankAccountSummary() || {}}
            initialErrors={isFormCompleted({}) ? {} : { incomplete: 'true' }}
            validate={validateForm}
            validateOnChange
            validateOnMount
            validateOnBlur
            enableReinitialize
            onSubmit={save}
          >
            {({ values, errors, isSubmitting, validateForm }) => {
              const canGoNext = !isSubmitting && isFormCompleted(values);
              setTimeout(() => {
                setNextEnabled(canGoNext);
              }, 1);

              return (
                <Form>
                  <Field
                    name='monthlyIncome'
                    type='text'
                    placeholder='Monthly income after taxes'
                    component={FloatingInput}
                    fieldType='masked'
                    mask={(e) => masks.currencyAmount(e)}
                    className='top-field'
                  />
                  <Field
                    name='mainIncome'
                    placeholder='Main income source'
                    component={FloatingInput}
                    items={applicationDefaults.incomeSource}
                    getItemDisplayValue={(item) => htmlFormat.decode(item.label)}
                    filterItemsWithInputValue
                    fieldType='select'
                    className='top-field'
                  />
                  <Field
                    name='monthlyExpenses'
                    type='text'
                    placeholder='Average monthly expenses'
                    component={FloatingInput}
                    fieldType='masked'
                    mask={(e) => masks.currencyAmount(e)}
                    className='top-field'
                  />
                  <section className='confirm-info-container'>
                    <img src={InfoIcon} alt='Info' />
                    <p>This is the average across all three months</p>
                  </section>
                </Form>
              );
            }}
          </Formik>
        </section>
      </article>
    </section>
  );
};
