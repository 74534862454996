import React from 'react';
import { applicationDefaults } from 'utils';
import { VodaLendLogoCompare } from 'assets';
import classNames from 'classnames';
import './disclaimer.scss';

export const Disclaimer = ({ fixed }) => {
  const disclaimerClass = classNames('disclaimer', {
    'is-fixed': fixed,
  });
  return (
    <section className={disclaimerClass}>
      <img src={VodaLendLogoCompare} alt='vodalend compare logo' />
      <p>
        We use industry-leading encryption and security methods when handling your documents.
        <br className='show-desktop-only' /> Your details are not shared with anyone. To find out
        more, read our{' '}
        <a href={applicationDefaults.privacyUrl} target='_blank' rel='noreferrer'>
          privacy statement
        </a>
        .
      </p>
    </section>
  );
};
