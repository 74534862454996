import React from 'react';
import { routes } from 'router';
import { Button } from 'components';
import { TealiumService } from 'services';
import { VodaLendLogoRed } from 'assets';
import './technical-error.scss';

export class TechnicalError extends React.Component {
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  reportAnIssue() {
    TealiumService.onTechnicalReportIssue();
    window.location.href = `${window.location.origin}${routes.reportIssue}`;
  }

  tryAgain() {
    TealiumService.onTechnicalTryAgain();
    window.location.href = window.location.origin;
  }

  render() {
    if (this.state.hasError) {
      return (
        <article className='page-content-background'>
          <section className='Wizard full-height page-content'>
            <header className='page-header-common'>
              <img
                className='vodapay-logo'
                src={VodaLendLogoRed}
                style={{ width: '40px' }}
                alt='VodaPay'
              />
            </header>
            <div className='technical-error'>
              <section className='content'>
                <header>
                  <h2>Our apologies!</h2>
                  <p>
                    There was a technical error on our side.
                    <br className='show-desktop-only' /> Please try again.
                  </p>
                </header>

                <section>
                  <Button onClick={this.reportAnIssue} secondary>
                    Report an issue
                  </Button>
                  <Button onClick={this.tryAgain} primary>
                    Try again
                  </Button>
                </section>
              </section>
            </div>
          </section>
        </article>
      );
    }

    return this.props.children;
  }
}
