import React, { useState, useContext } from 'react';
import { Form, Formik, Field } from 'formik';
import { LendingContext } from 'context';
import { useNavService } from 'hooks';
import { routes } from 'router';
import { FloatingInput, NavigationFooter } from 'components';
import { applicationDefaults, errorMessages, htmlFormat, validate } from 'utils';
import { VodaLendLogoRed } from 'assets';
import './report-issue.scss';

export const ReportIssue = () => {
  const { application } = useContext(LendingContext);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const navigate = useNavService();

  function validateForm(values) {
    const errors = validate
      .create({})
      .test(
        'issueType',
        validate.name.maybeValid(values.issueType),
        errorMessages.general.issueType,
      )
      .test(
        'additionalNotes',
        validate.name.maybeValid(values.additionalNotes, true),
        errorMessages.general.specialCharsSuffix,
      )
      .test(
        'operatingSystem',
        validate.name.maybeValid(values.operatingSystems, true),
        errorMessages.general.operatingSystem,
      )
      .test(
        'browser',
        validate.name.maybeValid(values.browser, true),
        errorMessages.general.browser,
      )
      .test('email', validate.email.maybeValid(values.email))
      .test('incomplete', () => isFormCompleted(values), 'true')
      .build();

    setNextButtonEnabled(Object.keys(errors).length === 0);
    return errors;
  }

  function isFormCompleted(values) {
    return (
      !validate.lang.isBlank(values.issueType) &&
      !validate.lang.isBlank(values.operatingSystem) &&
      !validate.lang.isBlank(values.browser) &&
      !validate.lang.isBlank(values.email)
    );
  }

  function initialValues() {
    return {
      email: application?.personal?.email || '',
      additionalNotes: '',
    };
  }

  return (
    <article className='page-content-background'>
      <section className='Wizard full-height page-content'>
        <header className='page-header-common'>
          <img
            className='vodapay-logo'
            src={VodaLendLogoRed}
            style={{ width: '40px' }}
            alt='VodaPay'
          />
        </header>
        <div className='report-issue'>
          <section className='content'>
            <h2>Tell us what went wrong</h2>
            <Formik
              initialValues={initialValues()}
              initialErrors={isFormCompleted(initialValues()) ? {} : { incomplete: 'true' }}
              validate={validateForm}
              validateOnChange
              validateOnBlur={false}
            >
              <Form>
                <Field
                  name='issueType'
                  type='text'
                  placeholder='Select the type of issue'
                  component={FloatingInput}
                  items={applicationDefaults.issueTypes}
                  getItemDisplayValue={(item) => htmlFormat.decode(item.label)}
                  filterItemsWithInputValue
                  fieldType='select'
                />
                <Field
                  name='additionalNotes'
                  type='text'
                  placeholder='Add any additional notes'
                  component={FloatingInput}
                  fieldType='standard'
                />
                <Field
                  name='operatingSystem'
                  type='text'
                  placeholder='What operating system do you use?'
                  component={FloatingInput}
                  items={applicationDefaults.operatingSystems}
                  getItemDisplayValue={(item) => htmlFormat.decode(item.label)}
                  filterItemsWithInputValue
                  fieldType='select'
                />
                <Field
                  name='browser'
                  type='text'
                  placeholder='What browser do you use?'
                  component={FloatingInput}
                  items={applicationDefaults.browser}
                  getItemDisplayValue={(item) => htmlFormat.decode(item.label)}
                  filterItemsWithInputValue
                  fieldType='select'
                />
                <Field
                  name='email'
                  type='text'
                  placeholder='Email address'
                  component={FloatingInput}
                  fieldType='standard'
                />
              </Form>
            </Formik>
          </section>
        </div>
        <NavigationFooter
          onBack={() => (window.location.href = window.location.origin)}
          onNext={() => navigate.to(routes.issueSent)}
          nextEnabled={nextButtonEnabled}
          nextButtonLabel='Send Report'
          backButtonLabel='Cancel'
          hideNext={false}
          hideBack={false}
          hideCancel
        />
      </section>
    </article>
  );
};
