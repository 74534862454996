import React from 'react';
import { Button } from '../button/button';
import './navigation-footer.scss';

export const NavigationFooter = ({
  onBack,
  onNext,
  nextEnabled,
  nextButtonLabel,
  backButtonLabel,
  hideBack,
  hideNext,
}) => {
  const BackButton = () =>
    !hideBack && (
      <Button onClick={onBack} tertiary>
        {backButtonLabel || 'Back'}
      </Button>
    );

  const NextButton = () =>
    !hideNext && (
      <Button onClick={onNext} disabled={!nextEnabled} primary>
        {nextButtonLabel || 'Next'}
      </Button>
    );

  return (
    <footer className='NavigationFooter'>
      <div className='btn-placeholder'>
        <BackButton />
      </div>
      <div className='btn-placeholder'>
        <NextButton />
      </div>
    </footer>
  );
};
