export class Cache {
  static save = (key, data) => {
    window.sessionStorage.setItem(key, JSON.stringify(data));
  };

  static fetch = (key) => {
    const app = window.sessionStorage.getItem(key);
    if (!app) {
      return undefined;
    }
    return JSON.parse(app);
  };
}
