import React, { useContext, useEffect, useState } from 'react';
import { LendingContext, WizardContext } from 'context';
import { routes } from 'router';
import { useNavService } from 'hooks';
import { LoaderAnimation } from 'components';
import { isValidJson } from 'helpers';
import { TealiumService } from 'services';
import { lendingOnboardingSteps } from 'utils';
import './bank-portal.scss';

export const BankPortal = () => {
  const { application, updateApplication, updating, saveError } = useContext(LendingContext);
  const { setNext, setNextEnabled } = useContext(WizardContext);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [nextClicked, setNextClicked] = useState(false);
  const navigate = useNavService();

  useEffect(() => {
    TealiumService.onBankPortalStart();
    setNext(save);
    setNextEnabled(false);

    const listener = (e) => {
      if (isValidJson(e.data)) {
        const response = JSON.parse(e.data);

        if (response.bank_account) {
          setTimeout(() => setNextEnabled(true), 1000);
        }
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  useEffect(() => {
    if (nextClicked && !updating && !saveError) {
      navigate.to(routes.confirmIncome);
    }
  }, [nextClicked, updating]);

  function save() {
    TealiumService.onBankPortalEnd();
    updateApplication(lendingOnboardingSteps.online_bank_portal);

    setNextClicked(true);
  }

  return (
    <main className='bank-portal-container'>
      <article className='bank-portal-panel'>
        <section className='iframe-container'>
          {iframeLoading && (
            <div className='animation'>
              <LoaderAnimation />
            </div>
          )}
          <iframe
            className={`bank-frame ${iframeLoading ? 'hidden' : ''}`}
            src={application.bank?.bankPortalUrl}
            title='Bank portal'
            onLoad={() => setIframeLoading(false)}
          />
        </section>
      </article>
    </main>
  );
};
