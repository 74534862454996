import React from 'react';
import classNames from 'classnames';
import './button.scss';

export const Button = ({
  children,
  className,
  onClick,
  primary,
  secondary,
  tertiary,
  ...props
}) => {
  const btnClass = classNames('custom-button', {
    'is-primary': primary,
    'is-secondary': secondary,
    'is-tertiary': tertiary,
  });
  return (
    <button {...props} onClick={onClick} className={btnClass}>
      {children}
    </button>
  );
};
