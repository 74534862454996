import { Environment } from 'Environment';
import { merge } from 'lodash';
import { errorMessages } from 'utils';
import { toastMessageServiceSingleton } from './toast-message.service';

const baseUrl = () => {
  let baseUrl = (Environment.current?.apiHost || '') + (Environment.current?.apiBase || '');
  if (!baseUrl.endsWith('/')) {
    baseUrl = baseUrl + '/';
  }
  return baseUrl;
};

export async function request(
  url,
  options = {
    fetchOptions: {},
    baseURLOverwrite: null,
    tealiumErrorLogger: null,
    showToastOnError: true,
    suppressTealiumEventOnError: false,
    ignoreErrorCodes: [],
  },
) {
  let requestBaseUrl = baseUrl();
  const {
    baseURLOverwrite,
    tealiumErrorLogger,
    showToastOnError,
    suppressTealiumEventOnError,
    ignoreErrorCodes,
  } = merge(
    {
      baseURLOverwrite: null,
      tealiumErrorLogger: null,
      showToastOnError: true,
      suppressTealiumEventOnError: false,
      ignoreErrorCodes: [],
    },
    options,
  );

  if (baseURLOverwrite) {
    requestBaseUrl = !baseURLOverwrite.endsWith('/') ? baseURLOverwrite + '/' : baseURLOverwrite;
  }

  const controller = new AbortController();

  options.fetchOptions = options.fetchOptions || {};
  let { fetchOptions } = options;
  fetchOptions.method = fetchOptions.method || 'GET';
  fetchOptions.headers = fetchOptions.headers || {};
  fetchOptions.headers.Accept = 'application/json';
  fetchOptions.signal = controller.signal;
  //  fetchOptions.headers['Content-Type'] = fetchOptions.headers['Content-Type'] || 'application/json';

  if (fetchOptions.headers['Content-Type'] === 'multipart/form-data') {
    // server doesn't like these set for form-data
    delete fetchOptions.headers['Content-Type'];
    delete fetchOptions.headers['Accept'];
  }

  if (url && url.startsWith('/')) {
    url = url.substr(1);
  }

  const fullUrl = `${requestBaseUrl}${url}`;
  setTimeout(() => controller.abort(), 30000);

  try {
    const res = await fetch(fullUrl, fetchOptions);
    const body = res.status === 204 ? {} : await res.json();
    const result = body?.result || body; /* SF */

    if (result && !ignoreErrorCodes.some((ec) => res.status === ec) && res.status >= 400) {
      let message = errorMessages.technical.general;

      // LLL
      if (result.Message) {
        message = result.Message;
      }
      if (showToastOnError && (!fetchOptions.signal || !fetchOptions.signal.aborted)) {
        toastMessageServiceSingleton.addError(
          message,
          null,
          `${res.status}`,
          false,
          null,
          suppressTealiumEventOnError,
        );
      }
      if (tealiumErrorLogger) {
        tealiumErrorLogger(message);
      }
    }

    return { status: res.status, result: result };
  } catch (e) {
    console.warn(fullUrl, e);
    return {
      status: 500,
      errorMsg: errorMessages.technical.general,
    };
  }
}
