import React, { useContext, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { LendingContext, WizardContext } from 'context';
import { useNavService } from 'hooks';
import { routes } from 'router';
import { FloatingInput, Checkbox } from 'components';
import { TealiumService } from 'services';
import { isValidAlphabetical, isValidEmail, isValidID, isValidMobileNumber } from 'helpers';
import {
  errorMessages,
  lendingOnboardingSteps,
  validate,
  langFormat,
  masks,
  stringFormat,
} from 'utils';
import './personal-details.scss';

export const PersonalDetails = () => {
  const [formInteractionCount, setFormInteractionCount] = useState(0);
  const [formErrors, setFormErrors] = useState([]);
  const [nextClicked, setNextClicked] = useState(false);
  const { application, updateApplication, updating, saveError } = useContext(LendingContext);
  const { setNext, setNextEnabled } = useContext(WizardContext);
  const navigate = useNavService();

  useEffect(() => {
    TealiumService.onPersonalDetailsStart(application.request?.applicationMethod);
  }, []);

  useEffect(() => {
    if (nextClicked && !updating && !saveError) {
      navigate.to(routes.employmentDetails);
    }
  }, [nextClicked, updating]);

  function determineFormError(errors) {
    for (const key in errors) {
      const errorMessage = errors[key];
      if (
        errorMessage &&
        errorMessage !== '' &&
        errorMessage !== 'true' &&
        !formErrors.includes(errorMessage)
      ) {
        TealiumService.onFormError(
          application.request?.applicationMethod,
          errorMessage,
          routes.personalDetails,
        );
        setFormErrors([...formErrors, errorMessage]);
      }
    }
  }

  function validateForm({ idNumber, firstName, lastName, email, mobileNumber, consentToggle }) {
    const errors = {};

    if (!idNumber || !isValidID(stringFormat.stripWhitespace(idNumber))) {
      errors.idNumber =
        stringFormat.stripWhitespace(idNumber).replace(/_/g, '').length < 13
          ? errorMessages.customer.rsaIdLength
          : errorMessages.customer.rsaId;
    }

    if (!firstName || !isValidAlphabetical(firstName)) {
      errors.firstName = errorMessages.customer.firstName;
    }

    if (!lastName || !isValidAlphabetical(lastName)) {
      errors.lastName = errorMessages.customer.lastName;
    }

    if (!email || !isValidEmail(email)) {
      errors.email = errorMessages.customer.email;
    }

    if (!mobileNumber || !isValidMobileNumber(stringFormat.stripWhitespace(mobileNumber))) {
      errors.mobileNumber = errorMessages.customer.mobileNumber;
    }

    if (!consentToggle) {
      errors.consentToggle = 'Consent not granted';
    }

    determineFormError(errors);
    return errors;
  }

  function extractPersonalDetails() {
    return {
      position: application?.personal?.position || '',
      title: application?.personal?.title || '',
      firstName: application?.personal?.firstName || '',
      lastName: langFormat.nullIf(application?.personal?.surname, 'Last Name Placeholder') || '',
      idType: application?.personal?.idType || '',
      idNumber: application?.personal?.idNumber || '',
      dateOfBirth: application?.personal?.dateOfBirth
        ? DateTime.fromISO(application?.personal?.dateOfBirth).toJSDate()
        : null,
      email: application?.personal?.email || '',
      mobileNumber: application?.personal?.mobileNumber || '',
    };
  }

  function isFormCompleted(values) {
    return (
      values.consentToggle &&
      !validate.lang.isBlank(values.idNumber) &&
      !validate.lang.isBlank(values.firstName) &&
      !validate.lang.isBlank(values.lastName) &&
      !validate.lang.isBlank(values.email) &&
      !validate.lang.isBlank(values.mobileNumber)
    );
  }

  function save(values) {
    TealiumService.onPersonalDetailsEnd(application.request?.applicationMethod);
    updateApplication(lendingOnboardingSteps.personal_details, values);

    setNextClicked(true);
  }

  function getInitialValues() {
    const personal = application.personal;

    return {
      idNumber: personal?.idNumber || '',
      firstName: personal?.firstName || '',
      lastName: personal?.lastName || '',
      email: personal?.email || '',
      mobileNumber: personal?.mobileNumber || '',
      consentToggle: personal?.consent,
    };
  }

  return (
    <section className='personal-details-container'>
      <article className='personal-details-panel'>
        <header>
          <h2>Personal information</h2>
          <p>Please insert your details as per your ID document</p>
        </header>
        <section>
          <Formik
            innerRef={(formik) => formik && setNext(formik.submitForm)}
            initialValues={getInitialValues() || {}}
            initialErrors={isFormCompleted(extractPersonalDetails()) ? {} : { incomplete: 'true' }}
            validate={validateForm}
            validateOnChange
            validateOnMount
            validateOnBlur
            enableReinitialize
            onSubmit={save}
          >
            {({ values, setFieldValue, errors, isSubmitting, validateForm, touched }) => {
              if (Object.values(touched).length > 1 && formInteractionCount < 1) {
                TealiumService.onFormInteraction(
                  application.request?.applicationMethod,
                  routes.personalDetails,
                );
                setFormInteractionCount(formInteractionCount + 1);
              }
              const canGoNext =
                !isSubmitting && !validate.hasErrors(errors) && isFormCompleted(values);
              setTimeout(() => {
                setNextEnabled(canGoNext);
              }, 1);

              return (
                <Form>
                  <Field
                    name='idNumber'
                    type='text'
                    placeholder='South African ID'
                    component={FloatingInput}
                    fieldType='masked'
                    className='top-field'
                    mask={masks.rsaId}
                    autoFocus
                  />
                  <Field
                    name='firstName'
                    type='text'
                    placeholder='First name'
                    component={FloatingInput}
                    fieldType='standard'
                    className='top-field'
                    maxLength={30}
                  />
                  <Field
                    name='lastName'
                    type='text'
                    placeholder='Last name'
                    component={FloatingInput}
                    fieldType='standard'
                    maxLength={30}
                  />
                  <Field
                    name='email'
                    type='text'
                    placeholder='Email address'
                    component={FloatingInput}
                    fieldType='standard'
                    maxLength={255}
                  />
                  <Field
                    name='mobileNumber'
                    mask={masks.phoneNumberSpaced}
                    id='mobileNumber'
                    placeholder='Mobile number'
                    className='bottom-field'
                    type='tel'
                    fieldType='masked'
                    component={FloatingInput}
                  />
                  <label>
                    <Field
                      name='consentToggle'
                      type='checkbox'
                      component={Checkbox}
                      checkboxText='I consent to the above personal info being used for the purpose of a credit enquiry'
                      onClick={() =>
                        TealiumService.onPersonalDetailsCheckbox(
                          application.request?.applicationMethod,
                        )
                      }
                    />
                  </label>
                </Form>
              );
            }}
          </Formik>
        </section>
      </article>
    </section>
  );
};
