import React from 'react';
import { TickedCheckbox, UntickedCheckbox } from 'assets';
import './checkbox.scss';

export const Checkbox = ({ field, form, checkboxText, ...props }) => {
  const isDisabled = props.disabled || form?.initialStatus?.initialDisabled?.[field.name];
  const { onClick, ...rest } = props;

  const newProps = {
    ...rest,
    disabled: isDisabled,
  };

  function handleChange() {
    onClick();
    form.setFieldValue(field.name, !field.value);
  }

  return (
    <div className={`Checkbox ${isDisabled ? 'disabled' : ''}`}>
      <div className={'Checkbox-label'}>
        <input
          id={field.name}
          type='checkbox'
          className='is-hidden'
          {...field}
          {...newProps}
          onChange={handleChange}
        />
        <img src={field.value ? TickedCheckbox : UntickedCheckbox} alt='toggle-icon' />

        <span className='checkbox-label-text'>{checkboxText}</span>
      </div>
    </div>
  );
};
