import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';
import { CloudUpload, DeviceSelected as UploadedIcon } from 'assets';
import { compressImage } from 'helpers';
import { toastMessageServiceSingleton } from 'services';
import './file-upload.scss';

export const FileUpload = (props) => {
  const [showIdModal, setShowIdModal] = useState(false);
  const maxSize = props.sizeLimit;
  const allowedFileTypes = props.allowedFileTypes;
  const maxAllowedFiles = props.maxAllowedFiles;
  var hasSubmitted = false;

  useEffect(() => {
    if (!showIdModal && !hasSubmitted) {
      removeMultipleFiles(getFileNames());
    }
  }, [showIdModal]);

  const idTypeList = ['Old Green Identity Document', 'New Identity Card', 'Passport'];

  const checkForUploadedId = (docs) =>
    props.documentType === 'ID' &&
    docs.length > 0 &&
    docs.some((document) => document.id_number === 'TBD');

  const documentIDNumber = (file) => (file.type === 'ID' ? 'TBD' : undefined);

  const removeUploadedFile = (fileToRemove) => {
    const newDocs = _.reject(props.currentDocuments.documents, (file) =>
      Object.is(file, fileToRemove),
    );
    props.setDeletedDocs(fileToRemove.content_document_id);
    props.setCurrentDocuments({
      documents: newDocs,
    });
  };

  const options = { maxSizeMB: 1, maxWidthOrHeight: 640 };

  const compressIfJpegCached = (files) =>
    files.map((file) => {
      if (file.type === 'image/jpeg') {
        return compressImage(file, options);
      } else {
        return file;
      }
    });

  const onDrop = (acceptedFiles) => {
    if (props.onDocumentUpload) {
      props.onDocumentUpload();
    }
    if (acceptedFiles.length > 0) {
      Promise.all(compressIfJpegCached(acceptedFiles))
        .then((uploadedFiles) => {
          const newDocuments = [];
          if (props.currentDocuments.documents?.length > 0) {
            props.currentDocuments.documents.map((element) => newDocuments.push(element));
          }
          uploadedFiles.forEach((file) => {
            newDocuments.push(file);
          });

          props.setCurrentDocuments({
            documents: newDocuments,
          });

          if (checkForUploadedId(newDocuments)) {
            if (props.onShowIdModal) {
              props.onShowIdModal();
            }
            hasSubmitted = false;
            setShowIdModal(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getFileNames = () => {
    const fileNames = [];
    if (props.currentDocuments.documents?.length > 0) {
      props.currentDocuments.documents.forEach((element) => {
        if (element.id_number === 'TBD') {
          fileNames.push(element.document_name);
        }
      });
    }
    return fileNames;
  };

  const hasUploadedFile = () =>
    _.findIndex(
      props.currentDocuments.documents,
      (file) =>
        file.type === props.documentType ||
        (props.documentType === 'ID' && idTypeList.includes(file.document_type)),
    ) >= 0;

  const hasUploadedOneOrThreeFiles = () =>
    props.currentDocuments.documents?.length === 1 ||
    props.currentDocuments.documents?.length === 3;

  const removeMultipleFiles = (fileNames) => {
    const newDocs = _.reject(props.currentDocuments.documents, (doc) =>
      fileNames.includes(doc.document_name),
    );
    props.setCurrentDocuments({
      documents: newDocs,
    });
  };

  const onDropRejected = (fileRejections) => {
    if (fileRejections[0].errors[0].code === 'file-invalid-type') {
      const errorMessage =
        props.unsupportedFileTypeErrorMessage ||
        `File upload failed: Incorrect file type, only ${allowedFileTypes} supported.`;
      toastMessageServiceSingleton.addError(errorMessage, null, 'N/A', true, null, true);
      if (props.onUploadError) {
        props.onUploadError(errorMessage);
      }
    } else if (fileRejections[0].errors[0].code === 'file-too-large') {
      const errorMessage = `File upload failed: Maximum file size of ${
        maxSize > 1000000 ? `${maxSize / 1024000}MB` : `${maxSize / 1024}KB`
      } exceeded.`;
      toastMessageServiceSingleton.addError(errorMessage, null, 'N/A', true, null, true);
      if (props.onUploadError) {
        props.onUploadError(errorMessage);
      }
    } else if (fileRejections[0].errors[0].code === 'dxl-upload-error') {
      throw new Error(fileRejections[0].errors[0].message);
    } else {
      const errorMessage = fileRejections[0].errors[0].message;
      toastMessageServiceSingleton.addError(errorMessage, null, 'N/A', false, null, true);
      if (props.onUploadError) {
        props.onUploadError(errorMessage);
      }
    }
  };

  const { isDragActive, getRootProps, getInputProps, isDragReject } = useDropzone({
    onDrop,
    onDropRejected,
    accept: allowedFileTypes,
    maxFiles: maxAllowedFiles,
    minSize: 0,
    maxSize,
  });

  return (
    <div className='has-margin-x-6'>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          className={`file-upload ${isDragActive && !isDragReject ? 'valid-file-drag' : ''} ${
            props.currentDocuments.documents?.length > 0 ? '' : 'center-box'
          } has-text-center ${isDragActive ? 'drag-over' : ''} ${
            isDragReject ? 'drag-reject' : ''
          } ${!isDragReject && hasUploadedOneOrThreeFiles() ? 'upload-success' : ''}`}
        >
          {!isDragReject && hasUploadedOneOrThreeFiles() && (
            <img className='checkmark' src={UploadedIcon} alt='Upload' />
          )}
          <div className=' has-padding-x-6 has-padding-top-6 has-padding-bottom-3 min-height'>
            <p className='upload-title has-padding-left-6'>{props.title}</p>
            {!isDragActive && (
              <div>
                <div
                  className={`columns has-margin-6 is-mobile has-text-centered inner-upload-container ${
                    isDragActive || !hasUploadedFile() ? 'hidden' : ''
                  }`}
                >
                  <div className={hasUploadedFile() ? 'column' : ''}>
                    <div className='columns has-padding resizable-div'>
                      {props.currentDocuments.documents?.length > 0 &&
                        props.currentDocuments.documents.map((file, index) => {
                          if (
                            file.type === props.documentType ||
                            (props.documentType === 'ID' && idTypeList.includes(file.type))
                          ) {
                            return (
                              <div
                                className='uploaded-file-bubble is-one-fifth has-margin-right-6'
                                key={`${file.name}_${file.type}_${index}_${
                                  documentIDNumber(file) ? documentIDNumber(file) : ''
                                }`}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <p className='uploaded-doc-name'>{file.name}</p>
                                <span
                                  className={`close-button ${
                                    props.documentType === 'ID' ? 'id-center' : 'non-id'
                                  }`}
                                  onClick={(e) => {
                                    removeUploadedFile(file);
                                    e.stopPropagation();
                                  }}
                                />
                                <p className='id-subtext'>{documentIDNumber(file)}</p>
                              </div>
                            );
                          }
                          return null;
                        })}
                    </div>
                  </div>
                </div>
                <div
                  className={`columns has-margin-6 is-mobile has-text-centered inner-upload-container ${
                    isDragActive ? 'hidden' : ''
                  }`}
                >
                  <div className={'padding-drag-area has-text-centered'}>
                    <img className='cloud-image' src={CloudUpload} alt='Upload' />
                    <span id='grey-drag-text'> Drag and drop file here or </span>
                    <span id='upload-text'>Upload</span>
                    <span id='mobile-text'> Upload</span>
                  </div>
                </div>
              </div>
            )}
            {isDragActive && (
              <div
                className={`columns has-margin-6 is-mobile has-text-centered inner-upload-container height-limit ${
                  isDragActive ? 'padding-drag-area-active' : 'hidden'
                }`}
              >
                <div className='column has-text-centered'>
                  {isDragActive && !isDragReject && (
                    <img className='cloud-image' src={CloudUpload} alt='Upload' />
                  )}
                  {isDragReject && (
                    <div className='has-text-centered'>
                      <p className='reject-text'>
                        File type not accepted. Please click to retry again
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className='upload-subtext has-padding-left-6 is-hidden-mobile'>
              {props.subtext}
            </div>
            <span className='upload-subtext is-pulled-right has-padding-right-6'>
              {props.fileLimitText}
            </span>
          </div>
        </div>
      </div>
      <div className='upload-subtext has-margin-left-6 has-mobile-margin-left-0 has-mobile-padding-x-6 is-hidden-tablet fullwidth has-padding-top-7 has-padding-bottom-5'>
        {props.subtext}
      </div>
    </div>
  );
};
