import React, { useEffect } from 'react';
import { useNavService } from 'hooks';
import { Button } from 'components';
import { routes } from 'router';
import { TealiumService } from 'services';
import { applicationDefaults } from 'utils';
import { VodaLendLogoRed } from 'assets';
import './application-failed.scss';

export const ApplicationFailed = () => {
  const navigate = useNavService();

  useEffect(() => {
    TealiumService.onApplicationFailedStart();
  }, []);

  function exit() {
    TealiumService.onApplicationFailedExit();
    window.location.href = applicationDefaults.staticHomeUrl;
  }

  function reportAnIssue() {
    TealiumService.onApplicationFailedReportIssue();
    navigate.to(routes.reportIssue);
  }

  return (
    <article className='Wizard full-height page-content page-content-background'>
      <header className='page-header-common'>
        <img className='vodapay-logo' src={VodaLendLogoRed} alt='VodaPay' />
      </header>
      <div className='application-failed'>
        <section className='content'>
          <header>
            <h2>Application unsuccessful</h2>
            <p>
              Unfortunately, we are unable to assist you with a loan based on your credit profile.
              To receive a free copy of your credit report please visit Experian at
              <a href='www.mycreditcheck.co.za'>www.mycreditcheck.co.za</a>
            </p>
          </header>

          <section>
            <Button onClick={exit} secondary>
              Exit
            </Button>
            <Button onClick={reportAnIssue} primary>
              Report an issue
            </Button>
          </section>
        </section>
      </div>
    </article>
  );
};
