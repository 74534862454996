import React, { useEffect, useRef, useState } from 'react';
import { toastMessageServiceSingleton } from 'services';
import { ToastMessage } from 'components';
import './toast-message-queue.scss';

export const ToastMessageQueue = (props) => {
  const [toasts, setToasts] = useState([]);
  const toastsRef = useRef(toasts);
  toastsRef.current = toasts;
  const [timeouts, setTimeouts] = useState([]);
  const timeoutsRef = useRef(timeouts);
  timeoutsRef.current = timeouts;

  const removeToast = (toast) => {
    const activeToasts = [...toastsRef.current];
    activeToasts.splice(activeToasts.indexOf(toast), 1);
    setToasts(activeToasts);
  };

  const removeAllToasts = () => {
    for (var i = 0; i < timeoutsRef.current.length; i++) {
      clearTimeout(timeoutsRef.current[i]);
    }
    setTimeouts([]);
    setToasts([]);
  };

  useEffect(() => {
    const addToast = (toast) => {
      if (toasts.map((toast) => String(toast.message)).includes(String(toast.message))) {
        return;
      }

      const newToast = {
        ...toast,
      };

      if (!toast.permanent) {
        const interval = setTimeout(() => {
          removeToast(toast);
        }, 10000);

        setTimeouts([interval, ...timeouts]);
        setToasts([newToast, ...toasts]);

        return () => clearTimeout(interval);
      } else {
        setToasts([newToast, ...toasts]);

        return () => {};
      }
    };

    toastMessageServiceSingleton.setRemoveAllMethod(removeAllToasts);
    toastMessageServiceSingleton.setAddMethod(addToast);

    return () => {
      toastMessageServiceSingleton.setAddMethod(undefined);
      toastMessageServiceSingleton.setRemoveAllMethod(undefined);
    };
  }, []);

  // Move the help fab up for each toast message present in the queue
  const helpFab = document.getElementsByClassName('HelpFloatingActionButton')[0];

  if (helpFab) {
    helpFab.style.marginBottom = toasts.length * 106 + 'px';
  }

  // Give the queue spacing to the left if the side nav is present
  const sideNav = document.getElementsByClassName('SideNav')[0];

  const sideNavPaddingLeft = sideNav && window.innerWidth > 769 ? '314px' : '';

  return (
    <div
      className={`ToastMessageQueue ${props.className || ''}`}
      style={{ paddingLeft: sideNavPaddingLeft }}
    >
      {toasts.map((toast, index) => (
        <ToastMessage key={index} toast={toast} removeToast={() => removeToast(toast)} />
      ))}
    </div>
  );
};
