import { useNavigate } from 'react-router-dom';

export const useNavService = () => {
  const navigation = useNavigate();

  const navigate = {
    to: (page) => {
      navigation(page);
    },
    back: (delta = 1) => {
      navigation(-delta);
    },
    replace: (page) => {
      navigation(page, { replace: true });
    },
  };

  return navigate;
};
