import { routes } from 'router';

// Apply Now Journey
export const routePageMap = {
  [routes.personalDetails]: 'vodalend compare: apply now: personal details',
  [routes.employmentDetails]: 'vodalend compare: apply now: employment details',
  [routes.bankingDetails]: 'vodalend compare: apply now: banking details',
  [routes.bankSelect]: 'vodalend compare: apply now: banking details: choose bank',
  [routes.bankPortal]: 'vodalend compare: apply now: banking details: choose bank: login',
  [routes.confirmIncome]:
    'vodalend compare: apply now: banking details: choose bank: login: income details',
  [routes.statementUpload]: 'vodalend compare: apply now: banking details: manual upload',
  [routes.offers]: 'vodalend compare: apply now: view offers',
};

export const routeLinkMap = {
  [routes.personalDetails]: 'vodalend compare: apply now: personal details: back',
  [routes.employmentDetails]: 'vodalend compare: apply now: employment details: back',
  [routes.bankingDetails]: 'vodalend compare: apply now: banking details: back',
  [routes.bankSelect]: 'vodalend compare: apply now: banking details: choose bank: back',
  [routes.bankPortal]: 'vodalend compare: apply now: banking details: choose bank: login: back',
  [routes.confirmIncome]:
    'vodalend compare: apply now: banking details: choose bank: login: income details: back',
  [routes.statementUpload]: 'vodalend compare: apply now: banking details: manual upload: back',
  [routes.offers]: 'vodalend compare: apply now: product select: back',
};

export const routeServiceNameMap = {
  [routes.personalDetails]: 'vodalend compare: landing page: apply now: personal details',
  [routes.employmentDetails]: 'vodalend compare: landing page: apply now: employment details',
  [routes.bankingDetails]: 'vodalend compare: landing page: apply now: banking details',
  [routes.bankSelect]: 'vodalend compare: apply now: banking details: choose bank',
  [routes.bankPortal]: 'vodalend compare: apply now: banking details: choose bank',
  [routes.confirmIncome]: 'vodalend compare: apply now: banking details: choose bank',
  [routes.statementUpload]: 'vodalend compare: apply now: banking details: manual upload',
  [routes.offers]: 'vodalend compare: apply now: view offers',
};

// Quick Quote Journey
export const routePageMapQuote = {
  [routes.personalDetails]: 'vodalend compare: quick quote: personal details',
  [routes.employmentDetails]: 'vodalend compare: quick quote: employment details',
  [routes.offers]: 'vodalend compare: quick quote: product select',
};

export const routeLinkMapQuote = {
  [routes.personalDetails]: 'vodalend compare: quick quote: personal details: back',
  [routes.employmentDetails]: 'vodalend compare: quick quote: employment details: back',
  [routes.offers]: 'vodalend compare: quick quote: product select: back',
};

export const routeServiceNameMapQuote = {
  [routes.personalDetails]: 'vodalend compare: landing page: quick quote: personal details',
  [routes.employmentDetails]: 'vodalend compare: landing page: quick quote: employment details',
  [routes.offers]: 'vodalend compare: quick quote: product select',
};
