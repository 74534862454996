import React, { useContext, useEffect } from 'react';
import { LoaderAnimation } from 'components';
import { LendingContext } from 'context';
import { useFixedPage } from 'hooks';
import { TealiumService } from 'services';
import { lendingOnboardingSteps, applicationDefaults } from 'utils';
import './full-page-loader.scss';

export const FullPageLoader = ({ showLoader }) => {
  const { application } = useContext(LendingContext);
  useFixedPage(showLoader);

  useEffect(() => {
    if (application?.lastStep === lendingOnboardingSteps.offers_details) {
      TealiumService.onOfferRedirect(
        application.request.applicationMethod,
        application.selectedOffer.companyName,
      );
    }
  }, []);

  function getLoaderContent() {
    switch (application?.lastStep) {
      case lendingOnboardingSteps.personal_details:
        return (
          <div>
            <h2>We're completing a credit enquiry</h2>
            <p>This should only take a moment</p>
          </div>
        );

      case lendingOnboardingSteps.online_bank_select:
        if (application.bank.uploadMethod === applicationDefaults.uploadMethods.online) {
          return (
            <div>
              <h2>We're redirecting you to your bank</h2>
              <p>This should only take a moment</p>
            </div>
          );
        }
        return <h2>This should only take a moment</h2>;

      case lendingOnboardingSteps.upload_bank_statements:
        return (
          <div>
            <h2>Checking your bank statements</h2>
            <p>This should only take a moment</p>
          </div>
        );

      case lendingOnboardingSteps.employment_details:
        if (
          application?.request.applicationMethod === applicationDefaults.applicationMethods.quote
        ) {
          return (
            <div>
              <h2>Getting your quotes ready</h2>
              <p>This should only take a moment</p>
            </div>
          );
        }
        break;

      case lendingOnboardingSteps.portal_confirm_income:
        return (
          <div>
            <h2>Getting your offers ready</h2>
            <p>This should only take a moment</p>
          </div>
        );

      case lendingOnboardingSteps.offers_details:
        return (
          <div>
            <h2>Redirecting you to {application.selectedOffer.companyName}</h2>
            <p>Complete the application on the provider website</p>
          </div>
        );
      default:
        return <h2>This should only take a moment</h2>;
    }
  }

  return (
    <div>
      {showLoader && (
        <div className='FullPageLoader'>
          <div className='form-details-container'>
            <div className='form-details-container__content loader-modal'>
              <div className='animation'>
                <LoaderAnimation height={160} width={120} />
              </div>
              {getLoaderContent()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
