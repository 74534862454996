import React from 'react';
import { TickedCheckbox } from 'assets';
import classNames from 'classnames';
import './image-button.scss';

export const ImageButton = ({ icon, alt, title, onClick, checked = false, block, rect }) => {
  const btnClass = classNames({
    'image-button-block': block,
    'image-button-rect': rect,
    active: checked,
  });

  return (
    <button className={btnClass} onClick={onClick}>
      <img src={icon} alt={alt} />
      <p>{title}</p>
      {checked && <img src={TickedCheckbox} className='checkbox-img' alt='Checked icon' />}
    </button>
  );
};
