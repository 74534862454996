import React from 'react';
import { BackArrowDark, VodaLendLogoRed } from 'assets';
import { lendingOnboardingStepGroups } from 'utils';
import { BsCheck } from 'react-icons/bs';
import './progress-bar.scss';

export const ProgressBar = ({ steps, currentStep, onBack, hideBack }) => {
  const ProgressBarComponent = ({
    isStart,
    isEnd,
    isActive,
    isComplete,
    // cumulative progress halfway to left of the step (from 0 - 100).
    progressLeft,
    // cumulative progress halfway to the right of the step (from 0 - 100).
    progressRight,
    stepNumber,
  }) => (
    <div className='columns is-gapless is-vcentered'>
      <div className='column'>
        <div className='step-bar'>
          {isStart ? (
            <div className='step-spacer' style={{ width: 'calc(50}%)' }} />
          ) : (
            <>
              <div className='step-progress' style={{ width: `calc(${progressLeft}%)` }} />
              <div
                className='step-spacer right-side-border-radius-2px'
                style={{ width: `calc(${Math.max(0, 100 - progressLeft)}%)` }}
              />
            </>
          )}
        </div>
      </div>

      <div className='column is-narrow'>
        <div className={`progress-step ${isComplete ? 'completed' : isActive ? 'active' : ''}`}>
          <div
            className={`progress-step-inner  ${
              isComplete ? 'completed' : isActive ? 'active' : ''
            }`}
          >
            {isComplete && <BsCheck className='icon-check-mark' />}
            {isActive && <div className='step-number'>{stepNumber}</div>}
          </div>
        </div>
      </div>

      <div className='column'>
        <div className='step-bar'>
          {isEnd ? (
            <div className='step-spacer' style={{ width: 'calc(50}%)' }} />
          ) : (
            <>
              <div
                className='step-progress left-side-border-radius-2px'
                style={{ width: `calc(${progressRight}%)` }}
              />
              <div
                className='step-spacer'
                style={{ width: `calc(${Math.max(0, 100 - progressRight)}%)` }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );

  function renderSteps(section) {
    const currentGroup = currentStep?.group || currentStep?.step;
    const groups = steps.map((s) => s.group || s.step).filter((v, i, a) => a.indexOf(v) === i);
    const currentGroupIdx = groups.findIndex((g) => g === currentGroup);
    if (section === 'bars') {
      return groups.map((group, idx) => (
        <div className='column' key={`step-${idx}`}>
          <ProgressBarComponent
            isStart={idx === 0}
            isEnd={idx === groups.length - 1}
            isActive={group === currentGroup}
            isComplete={idx < currentGroupIdx}
            stepNumber={idx + 1}
            progressLeft={idx > currentGroupIdx ? 0 : 100}
            progressRight={idx > currentGroupIdx ? 0 : 100}
          />
        </div>
      ));
    } else if (section === 'labels') {
      return groups.map((group, idx) => (
        <div className='column ' key={`step-name-${idx}`}>
          <div className={`has-text-centered step-label ${group === currentGroup ? 'active' : ''}`}>
            {lendingOnboardingStepGroups[group]}
          </div>
        </div>
      ));
    } else if (section === 'mobile') {
      return (
        <>
          <div className='has-padding-4'>
            <div className='current-step-heading-mobile'>{`Step ${currentGroupIdx + 1} of ${
              groups.length
            }`}</div>
            <div className='step-name-heading-mobile is-4 has-margin-0'>
              {lendingOnboardingStepGroups[currentGroup]}
            </div>
          </div>
          <div className='columns is-mobile is-gapless mobile-progress'>
            {groups.map((group, idx) => (
              <div className={'column'} key={`step-mobile-${idx}`}>
                <div className={`mobile-step ${idx <= currentGroupIdx ? 'completed' : ''}`} />
              </div>
            ))}
          </div>
        </>
      );
    }
  }

  return (
    <div className='ProgressBar'>
      <div className='progress-block is-hidden-mobile'>
        <div className='vodapay-logo'>
          <img src={VodaLendLogoRed} alt='VodaPay' />
        </div>
        <div className='progress-container'>
          <div className='columns is-gapless is-vcentered'>{renderSteps('bars')}</div>
          <div className='columns is-gapless step-label-columns'>{renderSteps('labels')}</div>
        </div>
      </div>
      <div className='mobile-heading is-hidden-tablet'>
        {onBack && !hideBack && (
          <span className='mobile-back-arrow-button' onClick={onBack}>
            <img src={BackArrowDark} alt='Back' />
          </span>
        )}
        {renderSteps('mobile')}
      </div>
    </div>
  );
};
