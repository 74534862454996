import React from 'react';
import { LendingContextProvider, WizardContextProvider } from 'context';
import { WizardOutlet } from './wizard-outlet';

export const CompareOutlet = () => (
  <LendingContextProvider>
    <WizardContextProvider>
      <WizardOutlet />
    </WizardContextProvider>
  </LendingContextProvider>
);
