import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { routes } from 'router';
import { ToastMessageQueue } from 'components';
import { CompareOutlet } from 'outlets';
import {
  ApplicationFailed,
  UploadMethod,
  BankPortal,
  ConfirmIncome,
  EmploymentDetails,
  GetStarted,
  Offers,
  OffersUnavailable,
  PersonalDetails,
  ReportIssue,
  ReportIssueSent,
  Request,
  SelectBank,
  Suggestions,
  SuggestionsSent,
  TechnicalError,
  ThankYou,
  UploadStatements,
} from 'pages';

export const Router = () => (
  <TechnicalError>
    <BrowserRouter>
      <Routes>
        <Route path={routes.compare} element={<CompareOutlet />}>
          <Route path={routes.getStarted} element={<GetStarted />} />
          <Route path={routes.loanRequest} element={<Request />} />
          <Route path={routes.personalDetails} element={<PersonalDetails />} />
          <Route path={routes.employmentDetails} element={<EmploymentDetails />} />
          <Route path={routes.uploadMethod} element={<UploadMethod />} />
          <Route path={routes.bankSelect} element={<SelectBank />} />
          <Route path={routes.bankPortal} element={<BankPortal />} />
          <Route path={routes.statementUpload} element={<UploadStatements />} />
          <Route path={routes.confirmIncome} element={<ConfirmIncome />} />
          <Route path={routes.offers} element={<Offers />} />
          <Route path={routes.offersUnavailable} element={<OffersUnavailable />} />
        </Route>

        <Route path={routes.suggestions} element={<Suggestions />} />
        <Route path={routes.suggestionsSent} element={<SuggestionsSent />} />
        <Route path={routes.thankYou} element={<ThankYou />} />
        <Route path={routes.reportIssue} element={<ReportIssue />} />
        <Route path={routes.issueSent} element={<ReportIssueSent />} />
        <Route path={routes.appFailed} element={<ApplicationFailed />} />

        <Route path='*' element={<Navigate to={routes.getStarted} replace />} />
      </Routes>
    </BrowserRouter>

    <ToastMessageQueue />
  </TechnicalError>
);
