import React, { useContext, useEffect } from 'react';
import { WizardContext } from 'context';
import { applicationDefaults } from 'utils';
import { VodaLendLogoRed, UnavailableVector } from 'assets';
import './offers-unavailable.scss';

export const OffersUnavailable = () => {
  const { setHideHeader, setNextLabel, setNext, setNextEnabled } = useContext(WizardContext);

  useEffect(() => {
    setHideHeader(true);
    setNextLabel('VodaLend home');
    setNext(() => (window.location.href = applicationDefaults.staticHomeUrl));
    setNextEnabled(true);

    return () => {
      setHideHeader(false);
      setNextLabel('Next');
      setNext(() => {});
      setNextEnabled(false);
    };
  }, []);

  return (
    <section className='offers-unavailable-page'>
      <header>
        <img className='vodapay-logo' src={VodaLendLogoRed} alt='VodaPay' />
      </header>
      <section className='offers-unavailable-container'>
        <article className='offers-unavailable-panel'>
          <header>
            <h2>Sorry, we don't have any offers available</h2>
            <p>
              We're sorry, based on the information you've provided, we can't find any offers that
              match your needs.
            </p>
          </header>
          <img src={UnavailableVector} alt='unavailable vector' />
        </article>
      </section>
    </section>
  );
};
