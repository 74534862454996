export function isValidAlphabetical(stringValue) {
  return stringValue && !/[<>?\\/*+!@$#%^&*()=}{\][|\\|~:;,_0-9]/.test(stringValue);
}

export function isValidName(stringValue) {
  return stringValue && /^[a-zA-Z- öç_-]+$/.test(stringValue);
}

export function isValidEmail(email) {
  return (
    email &&
    email.length >= 6 &&
    email.length <= 320 &&
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,64}@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(email)
  );
}

export function isValidMobileNumber(mobileNumber) {
  return mobileNumber && /^\+27\d{9}$/.test(mobileNumber);
}

export function isValidID(ID) {
  let valid =
    ID &&
    /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))$/.test(
      ID,
    );
  return valid ? idCheckSum(ID) : false;
}

function idCheckSum(idString) {
  let control = -1;
  try {
    let a = 0;
    for (let i = 0; i < 6; i++) {
      a += parseInt(idString[2 * i]);
    }

    let b = 0;
    for (let i = 0; i < 6; i++) {
      b = b * 10 + parseInt(idString[2 * i + 1]);
    }
    b *= 2;
    let bAsString = b.toString();
    let c = 0;
    for (let i = 0; i < bAsString.length; i++) {
      c += parseInt(bAsString[i]);
    }
    c += a;
    control = 10 - (c % 10);
    if (control === 10) {
      control = 0;
    }
  } catch {
    /* ignore*/
  }
  return parseInt(idString.slice(-1)) === control;
}

export const isValidJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isValidYear = (year) =>
  !!(
    year &&
    typeof year === 'string' &&
    year.replace(/_ /g, '').length === 4 &&
    new Date(year) < new Date() &&
    new Date(year) >= new Date('1900')
  );
