import {
  lendingApplicationMessages,
  lendingOnboardingSteps,
  applicationDefaults,
  storageKeys,
  income_frequencies,
  errorMessages,
  offersFormat,
  calcDateDiff,
  employment_statuses,
} from 'utils';
import { request } from './request';
import { Cache } from './cache.service';

function save() {
  return {
    async searchCustomer(application, property) {
      const resiliency = {
        authRetries: 1,
        response: null,
      };

      const value = application.personal[property];
      const fetchOptions = { method: 'GET' };

      resiliency.response = await request(`/customers/property/${property}?value=${value}`, {
        fetchOptions,
        baseURLOverwrite: null,
        tealiumErrorLogger: null,
        suppressTealiumEventOnError: false,
        showToastOnError: false,
        ignoreErrorCodes: [401],
      });

      return resiliency.response;
    },

    async createCustomer(application) {
      const resiliency = {
        authRetries: 1,
        response: null,
      };

      const personal = application.personal;
      const fetchOptions = {
        method: 'POST',
        body: JSON.stringify({
          email: personal.email,
          idNumber: personal.idNumber,
          clientReference: personal.clientReference,
          firstName: personal.firstName,
          lastName: personal.lastName,
        }),
      };

      resiliency.response = await request('/customers', {
        fetchOptions,
        baseURLOverwrite: null,
        tealiumErrorLogger: null,
        suppressTealiumEventOnError: false,
        showToastOnError: true,
        ignoreErrorCodes: [401],
      });

      return resiliency.response;
    },

    async createBankAccount(application, accountType) {
      const resiliency = {
        authRetries: 1,
        response: null,
      };

      const personal = application.personal;

      const fetchOptions = {
        method: 'POST',
        body: JSON.stringify({
          bank: application.bank.bankName,
          type: accountType,
          customerId: personal.clientId,
        }),
      };

      resiliency.response = await request('/bank-accounts', {
        fetchOptions,
        baseURLOverwrite: null,
        tealiumErrorLogger: null,
        suppressTealiumEventOnError: false,
        showToastOnError: true,
        ignoreErrorCodes: [401],
      });

      return resiliency.response;
    },

    async createBankPortal(bankId) {
      const resiliency = {
        authRetries: 1,
        response: null,
      };

      const fetchOptions = { method: 'GET' };

      resiliency.response = await request(
        `/bank-accounts/${bankId}/sync?redirectUrl=https://vodalenddev.vodacom.co.za/vodalend-application/callback-page`,
        {
          fetchOptions,
          baseURLOverwrite: null,
          tealiumErrorLogger: null,
          suppressTealiumEventOnError: false,
          showToastOnError: true,
          ignoreErrorCodes: [401],
        },
      );

      return resiliency.response;
    },

    async uploadBankStatements(statements, bankAccountId) {
      let responses = [];
      for (const statement of statements) {
        const request = await this.sendBankStatement(statement, bankAccountId);
        responses.push(request);
      }
      return responses;
    },
    async sendBankStatement(statement, bankAccountId) {
      const resiliency = {
        authRetries: 1,
        response: null,
      };

      const formData = new FormData();
      formData.append('statement', statement);

      const fetchOptions = { method: 'POST', body: formData };

      resiliency.response = await request(`/bank-accounts/${bankAccountId}/upload-bankstatement`, {
        fetchOptions,
        baseURLOverwrite: null,
        tealiumErrorLogger: null,
        suppressTealiumEventOnError: false,
        showToastOnError: false,
        ignoreErrorCodes: [401],
      });
      return resiliency.response;
    },

    async getBankAccountSummary(bankId) {
      const resiliency = {
        authRetries: 1,
        response: null,
      };

      const fetchOptions = { method: 'GET' };

      resiliency.response = await request(`/bank-accounts/${bankId}/summary-plus?months=3`, {
        fetchOptions,
        baseURLOverwrite: null,
        tealiumErrorLogger: null,
        suppressTealiumEventOnError: false,
        showToastOnError: true,
        ignoreErrorCodes: [401],
      });

      return resiliency.response;
    },

    async getOffers(application) {
      const resiliency = {
        authRetries: 1,
        response: null,
      };
      const personalDetails = application.personal;
      const requestDetails = application.request;
      const bank = application?.bank;
      const employerDetails = application.employer;

      const isApplyJourney =
        application.request.applicationMethod === applicationDefaults.applicationMethods.apply;
      const grossIncome = parseFloat(employerDetails.grossIncome || 0);
      const expenses = parseFloat(employerDetails.estimatedExpenses || 0);

      const applyNowData = isApplyJourney
        ? {
            bankAccount: {
              id: bank?.bankAccountId,
            },
            upload_method: bank.uploadMethod,
            income_source: employerDetails.incomeSource,
            occupation: employerDetails.incomeSource,
          }
        : {};

      const employedData = (employer) => {
        if (
          employer.employmentStatus === employment_statuses.formally_employed ||
          employer.employmentStatus === employment_statuses.contract_employed
        ) {
          return {
            employed: true,
            employer: employer?.employerName,
            employment_year: employer?.startDate,
            employment_time: null,
            employment_sector: employer?.employmentSector,
            employment_province: employer?.employmentProvince,
            employment_cell_phone: `0${employer?.employmentNumber.substring(
              3,
              employer?.employmentNumber.length,
            )}`,
          };
        }

        return {};
      };

      const fetchOptions = {
        method: 'POST',
        body: JSON.stringify({
          affiliate_id: '191',
          id_number: personalDetails.idNumber,
          first_name: personalDetails.firstName,
          middle_name: '',
          last_name: personalDetails.lastName,
          cell_phone_number: `0${personalDetails.mobileNumber.substring(
            3,
            personalDetails.mobileNumber.length,
          )}`,
          email: personalDetails.email,
          gross_income: grossIncome,
          expenses: expenses,
          net_income: grossIncome - expenses,
          payment_frequency: employerDetails.incomeFrequency || income_frequencies.weekly,
          payday: 0,
          bank_name: bank?.bankName || applicationDefaults.banks.nedbank.value,
          bank: bank?.bankName || applicationDefaults.banks.nedbank.value,
          type: null,
          popi: true,
          initial_popi: false,
          intent_id: 2,
          loan_amount_required: parseInt(requestDetails.loanAmount),
          repayment_period: parseInt(requestDetails.loanTerm),
          application_method: requestDetails.applicationMethod,
          fast_application: true,
          ...(requestDetails.applicationMethod === applicationDefaults.applicationMethods.apply &&
            applyNowData),
          ...employedData(employerDetails),
        }),
      };

      resiliency.response = await request('/v1/offer', {
        fetchOptions,
        baseURLOverwrite: null,
        tealiumErrorLogger: null,
        suppressTealiumEventOnError: false,
        showToastOnError: true,
        ignoreErrorCodes: [401],
      });

      return resiliency.response;
    },

    async acceptOffer(application) {
      const resiliency = {
        authRetries: 1,
        response: null,
      };

      const { hashId, partnerId, liveScore } = application.selectedOffer;

      const reqBody = {
        hashid: hashId,
        partnerId: partnerId,
        type: application.request.applicationMethod,
      };

      if (applicationDefaults.applicationMethods.apply === application.request.applicationMethod) {
        reqBody.liveScore = liveScore;
      }

      const fetchOptions = {
        method: 'POST',
        body: JSON.stringify(reqBody),
      };

      resiliency.response = await request('/v1/accept', {
        fetchOptions,
        baseURLOverwrite: null,
        tealiumErrorLogger: null,
        suppressTealiumEventOnError: false,
        showToastOnError: true,
        ignoreErrorCodes: [401],
      });

      return resiliency.response;
    },
  };
}

export const compareBackend = () => ({
  async saveApplication(application) {
    if (application.canSave) {
      const client = save();
      const errorOutcome = {
        successful: false,
        techError: false,
        message: errorMessages.technical.general,
      };

      try {
        switch (application.lastStep) {
          case lendingOnboardingSteps.personal_details: {
            const emailResponse = await client.searchCustomer(application, 'email');
            const idNumberResponse = await client.searchCustomer(application, 'idNumber');

            if (emailResponse.status >= 500 || idNumberResponse.status >= 500) {
              return errorOutcome;
            }
            if (emailResponse.status === 200 || idNumberResponse.status === 200) {
              const customer =
                emailResponse.status === 200 ? emailResponse.result : idNumberResponse.result;
              application.personal.clientId = customer.id;
              Cache.save(storageKeys.compare_application, application);
              break;
            }

            const customerResponse = await client.createCustomer(application);
            if (customerResponse.status >= 400) {
              return errorOutcome;
            }
            application.personal.clientId = customerResponse.result?.id;
            Cache.save(storageKeys.compare_application, application);
            break;
          }

          case lendingOnboardingSteps.employment_details: {
            const requestDetails = application.request;
            if (requestDetails.applicationMethod === applicationDefaults.applicationMethods.quote) {
              const offersResponse = await client.getOffers(application);
              if (offersResponse.status >= 400) {
                return errorOutcome;
              }
              const offers = offersResponse.result;
              application.offers = {
                hashId: offers.id,
                matches: offersFormat(offers),
              };
              Cache.save(storageKeys.compare_application, application);
            }
            break;
          }

          case lendingOnboardingSteps.online_bank_select: {
            const bankResponse = await client.createBankAccount(
              application,
              application.bank.uploadMethod,
            );
            if (bankResponse.status >= 400) {
              return errorOutcome;
            }

            const bankAccountId = bankResponse.result?.id;
            application.bank.bankAccountId = bankAccountId;
            application.bank.bankStatements = [];
            Cache.save(storageKeys.compare_application, application);

            if (application.bank.uploadMethod === applicationDefaults.uploadMethods.statement) {
              break;
            }

            const bankPortalResponse = await client.createBankPortal(bankAccountId);
            if (bankPortalResponse.status >= 400) {
              return errorOutcome;
            }

            const bankPortalUrl = bankPortalResponse.result?.portalUrl;
            application.bank.bankPortalUrl = bankPortalUrl;
            Cache.save(storageKeys.compare_application, application);
            break;
          }

          case lendingOnboardingSteps.upload_bank_statements: {
            const bankAccountId = application.bank.bankAccountId;
            const responses = await client.uploadBankStatements(
              application.bank.bankStatements,
              bankAccountId,
            );

            for (const response of responses) {
              const result = response?.result || response;
              if (response.status >= 400) {
                return { successful: false, message: result.errorMsg };
              }
              const now = new Date();
              const daysDiff = calcDateDiff(result.data.startDate, now.toISOString(), 'days');
              if (daysDiff.days > 119) {
                return { successful: false, message: errorMessages.statement.startDate };
              }
            }
            if (application.bank.bankStatements.length === 1 && responses.length === 1) {
              const daysDiff = calcDateDiff(
                responses[0].result.data.startDate,
                responses[0].result.data.endDate,
                'days',
              );

              if (daysDiff.days < 60) {
                return { successful: false, message: errorMessages.statement.transactionHistory };
              }
            }

            const summaryResponse = await client.getBankAccountSummary(bankAccountId);
            if (summaryResponse.status >= 400) {
              return errorOutcome;
            }

            const summary = summaryResponse.result;
            application.bank.summary = summary;
            application.bank.bankAccountId = bankAccountId;
            Cache.save(storageKeys.compare_application, application);
            break;
          }

          case lendingOnboardingSteps.online_bank_portal: {
            const bankAccountId = application.bank.bankAccountId;
            const summaryResponse = await client.getBankAccountSummary(bankAccountId);
            if (summaryResponse.status >= 400) {
              return errorOutcome;
            }

            const summary = summaryResponse.result;
            application.bank.summary = summary;
            Cache.save(storageKeys.compare_application, application);
            break;
          }

          case lendingOnboardingSteps.portal_confirm_income: {
            const offersResponse = await client.getOffers(application);
            if (offersResponse.status >= 400) {
              return errorOutcome;
            }

            const offers = offersResponse.result;
            if (offers.matches) {
              const liveMatches = offersFormat(offers).filter((offerItem) => offerItem.live);
              application.offers = {
                hashId: offers.id,
                matches: liveMatches,
              };
              Cache.save(storageKeys.compare_application, application);
              break;
            }
            break;
          }

          case lendingOnboardingSteps.offers_details: {
            const acceptOfferResponse = await client.acceptOffer(application);
            if (acceptOfferResponse.status >= 400) {
              return errorOutcome;
            }

            const acceptOffer = acceptOfferResponse.result;
            if (acceptOffer && acceptOffer.ok) {
              break;
            }
            break;
          }

          case lendingOnboardingSteps.thank_you: {
            await client.acceptOffer(application);
            break;
          }
          default:
            return { successful: true, message: lendingApplicationMessages.actions.unchanged };
        }
      } catch (error) {
        console.error(error);
        errorOutcome.techError = true;
        errorOutcome.message = error;
        return errorOutcome;
      }
    }

    return { successful: true, message: lendingApplicationMessages.actions.unchanged };
  },
});
