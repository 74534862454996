import React, { useContext, useState, useEffect } from 'react';
import { LendingContext, WizardContext } from 'context';
import { routes } from 'router';
import { useNavService } from 'hooks';
import { ImageButton } from 'components';
import { TealiumService } from 'services';
import { applicationDefaults, lendingOnboardingSteps } from 'utils';
import './select-bank.scss';

export const SelectBank = () => {
  const { application, updateApplication, updating, saveError } = useContext(LendingContext);
  const { setNext, setNextEnabled, forceUpdate } = useContext(WizardContext);
  const [selectedBank, setSelectedBank] = useState('');
  const [nextClicked, setNextClicked] = useState(false);
  const navigate = useNavService();

  useEffect(() => {
    TealiumService.onSelectBankStart();
    setNextEnabled(false);
  }, []);

  useEffect(() => {
    if (selectedBank) {
      setNextEnabled(true);
      setNext(save);
      forceUpdate((fresh) => !fresh);
    }
  }, [selectedBank]);

  useEffect(() => {
    if (nextClicked && !updating && !saveError) {
      const route =
        application.bank.uploadMethod === applicationDefaults.uploadMethods.online
          ? routes.bankPortal
          : routes.statementUpload;

      navigate.to(route);
    }
  }, [nextClicked, updating, saveError]);

  function save() {
    setNextClicked(true);
    updateApplication(lendingOnboardingSteps.online_bank_select, selectedBank);
  }

  const selectBankOption = (values) => {
    TealiumService.onSelectBank(values);
    setSelectedBank(values);
  };

  return (
    <div className='select-bank-container'>
      <article className='select-bank-panel'>
        <header>
          <h2>Select your bank</h2>
          <p>
            Bank statements are used to match you with a suitable lender.
            <br />
            This process is fully encrypted and secure.
          </p>
        </header>
        <section>
          {Object.values(applicationDefaults.banks).map(({ label, icon, value }, index) => (
            <ImageButton
              key={index}
              icon={icon}
              alt={`${label} icon`}
              onClick={() => selectBankOption(value)}
              checked={selectedBank === value}
              title={label}
              rect
            />
          ))}
        </section>
        <p>
          *If you do not see your bank listed above, unfortunately we cannot assist you further.
        </p>
      </article>
    </div>
  );
};
