const formatter = new Intl.NumberFormat('en', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const stripWhitespace = (value) => {
  if (value && typeof value === 'string' && value !== '') {
    return value.replace(/ /g, '');
  } else {
    return value;
  }
};

export const formatCurrencyAmount = (amount) => {
  if (typeof amount === 'string') {
    amount = parseFloat(amount) || amount;
  }
  let formatted = formatter.format(amount);
  return formatted !== 'NaN' ? formatted : amount;
};

export const removeNonNumericCharacters = (text) => text.replace(/[^0-9]/g, '');
