export const routes = {
  compare: '/compare',
  getStarted: '/compare/get-started',
  loanRequest: '/compare/loan',
  personalDetails: '/compare/personal-details',
  employmentDetails: '/compare/employment-details',
  uploadMethod: '/compare/upload-method',
  bankSelect: '/compare/bank/select',
  bankPortal: '/compare/bank/portal',
  statementUpload: '/compare/bank/upload',
  confirmIncome: '/compare/confirm-income',
  offers: '/compare/offers',
  offersUnavailable: '/compare/offers/unavailable',
  suggestions: '/compare/offers/suggestions',
  suggestionsSent: '/compare/offers/suggestions/sent',
  appFailed: '/compare/offers/unsuccessful',
  thankYou: '/compare/offers/thank-you',
  reportIssue: '/compare/report-issue',
  issueSent: '/compare/report-issue/sent',
  noEntry: '/compare/no-entry',
};
