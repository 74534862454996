const extractBooleanIfPresent = (qpValue) => {
  const isTrueSet = qpValue.toLowerCase() === 'true';
  const isFalseSet = qpValue.toLowerCase() === 'false';
  if (isTrueSet) {
    return true;
  } else if (isFalseSet) {
    return false;
  } else {
    return qpValue;
  }
};

export const urlQueryParams = (url) => {
  const qps = url.split('?')[1]?.split('&');
  const structuredQPs = {};

  if (qps) {
    qps.forEach((currentQPSet) => {
      const tempArray = currentQPSet.split('=');
      if (!structuredQPs[tempArray[0]]) {
        structuredQPs[decodeURIComponent(tempArray[0])] = decodeURIComponent(tempArray[1]);
      }
    });
  }

  return structuredQPs;
};

export const getValue = (qpMap, qpName) =>
  qpMap[qpName] ? extractBooleanIfPresent(qpMap[qpName]) : '';
