/*
  Autogenerated from Thami's spec.
  Please update when the spec changes.
  Fields with values in the form "< ... >" are ignored as their value is variable.
 */
export const tealiumSpec = [
  {
    event_name: 'page_view, form_start',
    page_name: 'vodapay: originations: self service: login',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
    form_name: 'self service login',
    form_type: 'login',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: login error',
    page_name: 'vodapay: originations: self service: login',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: back to vodacombusiness.co.za',
    page_name: 'vodapay: originations: self service: login',
    link_type: 'e',
    link_url: '<insert link href>',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: login error',
    page_name: 'vodapay: originations: self service: login',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: first time member link',
    page_name: 'vodapay: originations: self service: login',
    link_type: 'o',
    link_url: '<insert link href>',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: login error',
    page_name: 'vodapay: originations: self service: login',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: forgot password',
    page_name: 'vodapay: originations: self service: login',
    link_type: 'o',
    link_url: '<insert link href>',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: login error',
    page_name: 'vodapay: originations: self service: login',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: login',
    page_name: 'vodapay: originations: self service: login',
    link_type: 'o',
    link_url: '<insert link href>',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: login error',
    page_name: 'vodapay: originations: self service: login',
  },
  {
    event_name: 'form_error',
    form_error_name: '<insert event error name>',
    'form_error _code': '<insert event error code>',
    form_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: login error',
    page_name: 'vodapay: originations: self service: login',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal:technical difficulties',
    page_name: 'vodapay: originations: self service: login',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: forgot password',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: forgot password error',
    page_name: 'vodapay: originations: self service: forgot password',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: forgot password reset',
    page_name: 'vodapay: originations: self service: forgot password',
    link_type: 'o',
    link_url: '<insert link href>',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: forgot password error',
    page_name: 'vodapay: originations: self service: forgot password',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: login OTP',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: click resend OTP',
    page_name: 'vodapay: originations: self service: login OTP',
    link_type: 'o',
    link_url: '<insert link href>',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: login resend OTP',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'form_error',
    page_error_name: '<insert event error name>',
    page_error_code: '<insert event error code>',
    page_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: login resend OTP error',
    page_name: 'vodapay: originations: self service: login resend OTP',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: click resend OTP',
    page_name: 'vodapay: originations: self service: login resend OTP',
    link_type: 'o',
    link_url: '<insert link href>',
  },
  {
    event_name: 'ui_interaction, visitor_login_multi_factor',
    event_action: 'click',
    link_name: 'vodapay: originations portal: click OTP continue',
    page_name: 'vodapay: originations: self service: login OTP',
    link_type: 'o',
    link_url: '<insert link href>',
  },
  {
    event_name: 'form_error',
    form_error_name: '<insert event error name>',
    'form_error _code': '<insert event error code>',
    form_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: OTP error',
    page_name: 'vodapay: originations: self service: login OTP',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: technical error',
    page_name: '<insert page name>',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: technical error',
    page_name: '<insert page name>',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: create password',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: click set password',
    page_name: 'vodapay: originations: self service: create password',
    link_type: 'o',
    link_url: '<insert link href>',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: create password error',
    page_name: 'vodapay: originations: self service: create password',
  },
  {
    event_name: 'page_view, journey_start',
    page_name: 'vodapay: originations: self service: first time user activation',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
    journey_name: 'first time user activation',
    journey_type: 'self service',
  },
  {
    event_name: 'form_error',
    form_error_name: '<insert form error name>',
    'form_error _code': '<insert form error code>',
    form_error_type: 'user error',
    link_name: 'vodapay: originations portal: first time user activation error',
    page_name: 'vodapay: originations: self service: first time user activation',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: dashboard',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: dashboard error',
    page_name: 'vodapay: originations: self service: dashboard',
  },
  {
    event_name: 'page_filter, tool_start',
    link_name: 'vodapay: originations portal: date filter applied',
    page_name: 'vodapay: originations: self service: dashboard',
    page_filters_type: 'date',
    tool_name: 'vodapay: originations portal: date filter',
    tool_type: 'select',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: dashboard error',
    page_name: 'vodapay: originations: self service: dashboard',
  },
  {
    event_name: 'page_filter, tool_start',
    link_name: 'vodapay: originations portal: terminal filter applied',
    page_name: 'vodapay: originations: self service: dashboard',
    page_filters_type: 'terminal',
    page_filters_value: '<all, POS, QR, Ecommerce>',
    tool_name: 'vodapay: originations portal: terminal filter',
    tool_type: 'select',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: dashboard error',
    page_name: 'vodapay: originations: self service: dashboard',
  },
  {
    event_name: 'originations_bca_qualify',
    link_name: 'vodapay: originations portal: bca qualify',
    page_name: 'vodapay: originations: self service: dashboard',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: dashboard error',
    page_name: 'vodapay: originations: self service: dashboard',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: bca apply now',
    page_name: 'vodapay: originations: self service: dashboard',
    link_type: 'o',
    link_url: '<insert URL>',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: dashboard error',
    page_name: 'vodapay: originations: self service: dashboard',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: terminals',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: terminals error',
    page_name: 'vodapay: originations: self service: terminals',
  },
  {
    event_name: 'page_filter, tool_start',
    link_name: 'vodapay: originations portal: terminal filter applied',
    page_name: 'vodapay: originations: self service: dashboard',
    page_filters_type: 'terminal',
    page_filters_value: '<all, POS, QR, Ecommerce>',
    tool_name: 'ter',
    tool_type: 'select',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: terminals filter error',
    page_name: 'vodapay: originations: self service: terminals',
  },
  {
    page_filters_value: 'online payment gateway vodapay ozow payment pages ',
    tool_name: 'terminals filter',
  },
  {
    event_name: 'tool start',
    link_name: 'vodapay: originations portal: terminals view more',
    page_name: 'vodapay: originations: self service: dashboard',
    tool_name: 'terminals show more',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: terminals filter error',
    page_name: 'vodapay: originations: self service: terminals',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: terminal transactions',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: terminal transactions error',
    page_name: 'vodapay: originations: self service: terminal transactions',
  },
  {
    event_name: 'page_filter, tool_start',
    link_name: 'vodapay: originations portal: date filter applied',
    page_name: 'vodapay: originations: self service: terminal transactions',
    page_filters_type: 'date',
    tool_name: 'vodapay: originations portal: date filter',
    tool_type: 'select',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: terminal transactions error',
    page_name: 'vodapay: originations: self service: terminal transactions',
  },
  {
    event_name: 'page_filter, tool_start',
    link_name: 'vodapay: originations portal: transaction type filter applied',
    page_name: 'vodapay: originations: self service: terminal transactions',
    page_filters_type: 'transcation type',
    page_filters_value: '<insert chosen value>',
    tool_name: 'vodapay: originations portal: transaction type filter',
    tool_type: 'select',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: terminal transactions error',
    page_name: 'vodapay: originations: self service: terminal transactions',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: export transactions',
    page_name: 'vodapay: originations: self service: terminal transactions',
    link_type: 'o',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: terminal transactions error',
    page_name: 'vodapay: originations: self service: terminal transactions',
  },
  {
    event_name: 'ui_interaction, search_apply',
    event_action: 'click',
    link_name: 'vodapay: originations portal: search by amount',
    page_name: 'vodapay: originations: self service: terminal transactions',
    link_type: 'o',
    search_category: 'transaction amount',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: terminal transactions error',
    page_name: 'vodapay: originations: self service: terminal transactions',
  },
  {
    event_name: 'ui_interaction, search_apply',
    event_action: 'click',
    link_name: 'vodapay: originations portal: search by reference',
    page_name: 'vodapay: originations: self service: terminal transactions',
    link_type: 'o',
    search_category: 'transaction reference',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: terminal transactions error',
    page_name: 'vodapay: originations: self service: terminal transactions',
  },
  {
    event_name: 'ui_interaction',
    link_name: 'vodapay: originations portal: transactions load more',
    page_name: 'vodapay: originations: self service: terminal transactions',
    link_type: 'o',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: terminal transactions error',
    page_name: 'vodapay: originations: self service: terminal transactions',
  },
  {
    event_name: 'ui_interaction',
    link_name: 'vodapay: originations portal: transactions view individual',
    page_name: 'vodapay: originations: self service: terminal transactions',
    link_type: 'o',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: terminal transactions error',
    page_name: 'vodapay: originations: self service: terminal transactions',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: account transactions',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'page_filter, tool_start',
    link_name: 'vodapay: originations portal: date filter applied',
    page_name: 'vodapay: originations: self service: account transactions',
    page_filters_type: 'date',
    tool_name: 'vodapay: originations portal: date filter',
    tool_type: 'select',
  },
  {
    event_name: 'page_filter, tool_start',
    link_name: 'vodapay: originations portal: transaction type filter applied',
    page_name: 'vodapay: originations: self service: account transactions',
    page_filters_type: 'transcation type',
    page_filters_value: '<insert chosen value>',
    tool_name: 'vodapay: originations portal: transaction type filter applied',
    tool_type: 'select',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: export transactions',
    page_name: 'vodapay: originations: self service: account transactions',
    link_type: 'o',
  },
  {
    event_name: 'ui_interaction, search_apply',
    event_action: 'click',
    link_name: 'vodapay: originations portal: search by amount',
    page_name: 'vodapay: originations: self service: account transactions',
    link_type: 'o',
    search_category: 'transaction amount',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: account transactions error',
    page_name: 'vodapay: originations: self service: account transactions',
  },
  {
    event_name: 'ui_interaction, search_apply',
    event_action: 'click',
    link_name: 'vodapay: originations portal: search by reference',
    page_name: 'vodapay: originations: self service: account transactions',
    link_type: 'o',
    search_category: 'transaction reference',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: account transactions error',
    page_name: 'vodapay: originations: self service: account transactions',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: transactions show more',
    page_name: 'vodapay: originations: self service: account transactions',
    link_type: 'o',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: account transactions error',
    page_name: 'vodapay: originations: self service: account transactions',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: transactions view individual',
    page_name: 'vodapay: originations: self service: account transactions',
    link_type: 'o',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: account transactions error',
    page_name: 'vodapay: originations: self service: account transactions',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: refund customer',
    page_name: 'vodapay: originations: self service: account transactions',
    link_type: 'o',
    link_url: '<insert URL>',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: transaction refunds',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: transaction refunds page load error',
    page_name: 'vodapay: originations: self service: transaction refunds',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: back to transactions',
    page_name: 'vodapay: originations: self service: transaction refunds',
    link_type: 'o',
    link_url: '<insert URL>',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: export transactions',
    page_name: 'vodapay: originations: self service: transaction refunds',
    link_type: 'o',
    link_url: '<insert URL>',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: export transactions',
    page_name: 'vodapay: originations: self service: transaction refunds',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: refund transaction',
    page_name: 'vodapay: originations: self service: transaction refunds',
    link_type: 'o',
    link_url: '<insert URL>',
  },
  {
    event_name: 'page_view, journey_start, form_start',
    page_name: 'vodapay: originations: self service: transaction refunds',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
    form_name: 'refund customer',
    journey_name: 'refund customer',
    journey_type: 'transaction',
  },
  {
    event_name: 'ui_interaction, form_end',
    event_action: 'click',
    link_name: 'vodapay: originations portal: refund transaction',
    page_name: 'vodapay: originations: self service: transaction refunds',
    link_type: 'o',
    link_url: '<insert URL>',
    form_name: 'refund customer',
    transaction_total: '<insert refund amount>',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: transaction refunds modal error',
    page_name: 'vodapay: originations: self service: transaction refunds',
  },
  {
    event_name: 'page_view,  journey_end, originations_refund_processed',
    page_name: 'vodapay: originations: self service: transaction refunds',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
    journey_name: 'refund customer',
    journey_type: 'transaction',
    transaction_total: '<insert refund amount>',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: transaction refund failed',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: transaction refund error',
    page_name: 'vodapay: originations: self service: transaction refunds',
  },
  {
    event_name: 'ui_interaction, journey_end',
    event_action: 'click',
    link_name: 'vodapay: originations portal: close refund transaction ',
    page_name: 'vodapay: originations: self service: transaction refund failed',
    journey_name: 'refund customer',
    journey_type: 'transaction',
    link_type: 'o',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: retry refund transaction ',
    page_name: 'vodapay: originations: self service: transaction refund failed',
    link_type: 'o',
    transaction_total: '<insert transaction amount>',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: account transactions error',
    page_name: 'vodapay: originations: self service: account transactions',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: get financed',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: bca get financed error',
    page_name: 'vodapay: originations: self service: get financed',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: bta more info',
    page_name: 'vodapay: originations: self service: get financed',
    link_type: 'o',
    link_url: '<insert URL>',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: bta apply now',
    page_name: 'vodapay: originations: self service: get financed',
    link_type: 'o',
    link_url: '<insert URL>',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: bca apply now',
    page_name: 'vodapay: originations: self service: get financed',
    link_type: 'o',
    link_url: '<insert URL>',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: bca info',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: bca info error',
    page_name: 'vodapay: originations: self service: bca info',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    event_label: '<topic slected>',
    event_category: 'FAQ selection',
    link_name: 'vodapay: originations: self service: bca faq click',
    page_name: 'vodapay: originations: self service: bca info',
    link_type: 'o',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations: self service: bca discliamer click',
    page_name: 'vodapay: originations: self service: bca info',
    link_type: 'o',
    link_url: '<insert URL>',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations: self service: bca view offer click',
    page_name: 'vodapay: originations: self service: bca info',
    link_type: 'o',
    link_url: '<insert URL>',
  },
  {
    event_name: 'page_view, journey_start',
    page_name: 'vodapay: originations: self service: bca apply now',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
    journey_name: 'BCA application',
    journey_type: 'transaction',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: bca appy now error',
    page_name: 'vodapay: originations: self service: bca apply now',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations: self service: bca click on digital terms',
    page_name: 'vodapay: originations: self service: bca apply now',
    link_type: 'o',
    link_url: '<insert URL>',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations: self service: bca payment refactor info',
    page_name: 'vodapay: originations: self service: bca apply now',
    link_type: 'o',
    link_url: '<insert URL>',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations: self service: bca get advance',
    page_name: 'vodapay: originations: self service: bca apply now',
    link_type: 'o',
    originations_bca_payment_period: '<insert payment period>',
    transaction_total: '<insert how mch do you need amount>',
  },
  {
    event_name: 'page_view, form_start',
    page_name: 'vodapay: originations: self service: bca business information',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
    form_name: 'bca business information',
  },
  {
    event_name: 'form_error',
    form_error_name: '<insert page error name>',
    'form_error _code': '<insert page error code>',
    form_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: bca business information error',
    page_name: 'vodapay: originations: self service: bca business information',
  },
  {
    event_name: 'ui_interaction, form_end',
    event_action: 'click',
    link_name: 'vodapay: originations: self service: bca next click',
    page_name: 'vodapay: originations: self service: bca business information',
    link_type: 'o',
    form_name: 'bca business information',
    product_target_segment: '<type of business>',
    visitor_id_asset_primary: '<South African ID/ Passport>',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: confirm terms and conditions',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: bca confirm terms and conditions error',
    page_name: 'vodapay: originations: self service: confirm terms and conditions',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations: self service: bca terms click',
    page_name: 'vodapay: originations: self service: confirm terms and conditions',
    link_type: 'o',
    link_url: '<insert URL>',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations: self service: bca confirm click',
    page_name: 'vodapay: originations: self service: confirm terms and conditions',
    journey_name: 'BCA application',
    link_type: 'o',
    link_url: '<insert URL>',
  },
  {
    event_name: 'page_view, journey_end, originations_application_complete, transaction_complete',
    page_name: 'vodapay: originations: self service: BCA application success',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
    journey_name: 'BCA application',
    journey_type: 'transaction',
    product_name: 'BCA',
    product_quantity: 1,
    product_price: '<funding amount>',
    product_category: 'BCA',
    transaction_total: '<total repayment amount>',
    transaction_id: '<insert transaction ID>',
    transaction_payment_type: '<monthly/daily>',
    originations_bca_payment_period: '<repayment term> number only',
  },
  {
    event_name: 'event_error',
    page_error_name: '<insert event error name>',
    page_error_code: '<insert event error code>',
    page_error_type: '<insert event error type>',
    link_name: 'vodapay: originations: self service: BCA application error',
    page_name: 'vodapay: originations: self service: BCA application success',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: bca view contract',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: bca view contract error',
    page_name: 'vodapay: originations: self service: bca view contract',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations: self service: bca print contract',
    page_name: 'vodapay: originations: self service: bca view contract',
    link_type: 'o',
  },
  {
    event_name: 'event_error',
    page_error_name: '<insert event error name>',
    page_error_code: '<insert event error code>',
    page_error_type: '<insert event error type>',
    link_name: 'vodapay: originations: self service: bca print contract error',
    page_name: 'vodapay: originations: self service: bca view contract',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations: self service: bca download contract',
    page_name: 'vodapay: originations: self service: bca view contract',
    link_type: 'b',
  },
  {
    event_name: 'event_error',
    page_error_name: '<insert event error name>',
    page_error_code: '<insert event error code>',
    page_error_type: '<insert event error type>',
    link_name: 'vodapay: originations: self service: bca download contract error',
    page_name: 'vodapay: originations: self service: bca view contract',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: bca dashboard',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    event_label: '<topic slected>',
    event_category: 'FAQ selection',
    link_name: 'vodapay: originations: self service: bca dsaboard faq click',
    page_name: 'vodapay: originations: self service: bca dashboard',
    link_type: 'o',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: my documents',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: my documents error',
    page_name: 'vodapay: originations: self service: my documents',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: download document',
    page_name: 'vodapay: originations: self service: my documents',
    link_type: 'b',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: my documents error',
    page_name: 'vodapay: originations: self service: my documents',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: documents show more',
    page_name: 'vodapay: originations: self service: my documents',
    link_type: 'o',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: my documents error',
    page_name: 'vodapay: originations: self service: my documents',
  },
  {
    event_name: 'page_view',
    page_name: 'vodapay: originations: self service: my profile',
    page_url: '<insert URL>',
    page_section: 'self service',
    page_channel: 'vodapay: self service',
    page_subdomain: 'vodapay: self service',
    page_type: 'website',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: change password',
    page_name: 'vodapay: originations: self service: my profile',
    link_type: 'o',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: set password',
    page_name: 'vodapay: originations: self service: my profile',
    link_type: 'o',
  },
  {
    event_name: 'page_error',
    page_error_name: '<insert page error name>',
    page_error_code: '<insert page error code>',
    page_error_type: '<insert page error type>',
    link_name: 'vodapay: originations portal: my profile error',
    page_name: 'vodapay: originations: self service: my profile',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations portal: cancel password change',
    page_name: 'vodapay: originations: self service: my profile',
    link_type: 'o',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: my profile error',
    page_name: 'vodapay: originations: self service: my profile',
  },
  {
    link_name: 'vodapay: originations portal: change password successful',
    page_name: 'vodapay: originations: self service: my profile',
    link_type: 'o',
  },
  {
    event_name: 'event_error',
    event_error_name: '<insert event error name>',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: my profile error',
    page_name: 'vodapay: originations: self service: my profile',
  },
  {
    event_name: 'event_error',
    event_error_name: 'change password failed',
    event_error_code: '<insert event error code>',
    event_error_type: '<insert event error type>',
    link_name: 'vodapay: originations portal: change password failed',
    page_name: 'vodapay: originations: self service: my profile',
  },
  {
    event_name: 'ui_interaction',
    event_action: 'click',
    link_name: 'vodapay: originations: Help',
    page_name: '<insert page name>',
    link_type: 'o',
  },
  {
    event_name: 'ui_interaction, support_issue_raised',
    event_action: 'click',
    link_name: 'vodapay: originations: Call support number',
    page_name: '<insert page name>',
    link_type: 'e',
  },
  {
    event_name: 'ui_interaction, support_issue_raised',
    event_action: 'click',
    link_name: 'vodapay: originations: Email support',
    page_name: '<insert page name>',
    link_type: 'e',
  },
  {
    event_name: 'visitor_logout',
    link_name: 'vodapay: originations: logout',
    page_name: '<insert page name>  ',
  },
];
