import React, { useEffect, useContext } from 'react';
import { WizardContext, LendingContext } from 'context';
import { useNavService } from 'hooks';
import { Disclaimer, ImageButton } from 'components';
import { routes } from 'router';
import { applicationDefaults, lendingOnboardingSteps } from 'utils';
import { TealiumService } from 'services';
import { VodaLendLogoRed, ApplyNowIcon, QuickQuoteIcon } from 'assets';
import './get-started.scss';

export const GetStarted = () => {
  const { setHideHeader, setHideFooter } = useContext(WizardContext);
  const { updateApplication } = useContext(LendingContext);
  const navigate = useNavService();

  useEffect(() => {
    setHideFooter(true);
    setHideHeader(true);
    TealiumService.onLandingStart();
    return () => {
      setHideFooter(false);
      setHideHeader(false);
    };
  }, []);

  function startApply() {
    TealiumService.onApply();
    updateApplication(lendingOnboardingSteps.get_started, {
      applicationMethod: applicationDefaults.applicationMethods.apply,
    });
    navigate.to(routes.loanRequest);
  }

  function startQuote() {
    TealiumService.onQuote();
    updateApplication(lendingOnboardingSteps.get_started, {
      applicationMethod: applicationDefaults.applicationMethods.quote,
    });
    navigate.to(routes.loanRequest);
  }

  return (
    <div className='get-started-page'>
      <header>
        <img className='vodapay-logo' src={VodaLendLogoRed} alt='VodaPay' />
      </header>
      <section className='get-started-container'>
        <article className='get-started-panel'>
          <header>
            <h2>Apply for a personal loan in 3 minutes</h2>
            <p>
              For fast and efficient funding from the best loan providers in the country, select
              your preferred option below
            </p>
          </header>
          <section>
            <ImageButton
              icon={ApplyNowIcon}
              alt='apply now icon'
              onClick={startApply}
              title='Apply now'
              block
            />
            <ImageButton
              icon={QuickQuoteIcon}
              alt='quick quote icon'
              onClick={startQuote}
              title='Quick quote'
              block
            />
          </section>
          <p className='text-align-center'>
            Select "Apply now" for the quickest and easiest way to receive a quote and complete the
            application process
          </p>
        </article>
        <Disclaimer fixed />
      </section>
    </div>
  );
};
