import React, { useContext, useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { WizardContext, LendingContext } from 'context';
import { routes } from 'router';
import { useNavService } from 'hooks';
import { FloatingInput } from 'components';
import { TealiumService } from 'services';
import { isValidMobileNumber, isValidYear } from 'helpers';
import {
  applicationDefaults,
  employment_statuses,
  errorMessages,
  lendingOnboardingSteps,
  htmlFormat,
  masks,
  parseCurrency,
  validate,
  stringFormat,
} from 'utils';
import './employment-details.scss';

export const EmploymentDetails = () => {
  const [formErrors, setFormErrors] = useState([]);
  const { application, updateApplication, updating, saveError } = useContext(LendingContext);
  const { setNext, setNextEnabled } = useContext(WizardContext);
  const [nextClicked, setNextClicked] = useState(false);
  const formValidator = useRef(null);
  const navigate = useNavService();

  useEffect(() => {
    TealiumService.onEmploymentDetailsStart(application.request?.applicationMethod);
    const doFetch = async () => {
      formValidator.current();
    };
    doFetch();
  }, []);

  useEffect(() => {
    if (nextClicked && !updating && !saveError) {
      const route =
        application.request.applicationMethod === applicationDefaults.applicationMethods.apply
          ? routes.uploadMethod
          : routes.offers;

      navigate.to(route);
    }
  }, [updating, nextClicked]);

  function determineFormError(errors) {
    for (const key in errors) {
      const errorMessage = errors[key];
      if (
        errorMessage &&
        errorMessage !== '' &&
        errorMessage !== 'true' &&
        !formErrors.includes(errorMessage)
      ) {
        TealiumService.onFormError(
          application.request?.applicationMethod,
          errorMessage,
          routes.employmentDetails,
        );
        setFormErrors([...formErrors, errorMessage]);
      }
    }
  }

  function validateForm(values) {
    const {
      employmentStatus,
      employerName,
      startDate,
      employmentNumber,
      employmentSector,
      employmentProvince,
      incomeSource,
      incomeFrequency,
      grossIncome,
      estimatedExpenses,
    } = values;
    const errors = {};

    if (!employmentStatus) {
      errors.employmentStatus = errorMessages.general.employmentStatus;
      return errors;
    }

    if (employmentStatus === employment_statuses.unemployed) {
      return errors;
    }

    if (isEmployed(values)) {
      if (!employerName) {
        errors.employerName = errorMessages.general.employerName;
      }

      if (!isValidYear(startDate)) {
        errors.startDate = errorMessages.general.startDate;
      }

      if (
        !employmentNumber ||
        !isValidMobileNumber(stringFormat.stripWhitespace(employmentNumber))
      ) {
        errors.employmentNumber = errorMessages.general.employmentNumber;
      }

      if (!employmentSector) {
        errors.employmentSector = errorMessages.general.employmentSector;
      }

      if (!employmentProvince) {
        errors.employmentProvince = errorMessages.general.employmentProvince;
      }
    }

    if (isEmployed(values) || employmentStatus === employment_statuses.commission) {
      if (!incomeSource) {
        errors.incomeSource = errorMessages.general.incomeSource;
      }
    }

    if (!incomeFrequency) {
      errors.incomeFrequency = errorMessages.general.incomeFrequency;
    }

    if (!grossIncome || grossIncome <= 0) {
      errors.grossIncome = errorMessages.general.grossIncome;
    }

    if (!estimatedExpenses || estimatedExpenses <= 0) {
      errors.estimatedExpenses = errorMessages.general.estimatedExpenses;
    }

    determineFormError(errors);
    return errors;
  }

  function isEmployed(values) {
    return (
      values.employmentStatus === employment_statuses.contract_employed ||
      values.employmentStatus === employment_statuses.formally_employed
    );
  }

  function isFormCompleted(values) {
    if (!validate.lang.isBlank(values.employmentStatus)) {
      if (isEmployed(values)) {
        return validateAllFields(values);
      } else if (
        values.employmentStatus === employment_statuses.commission ||
        values.employmentStatus === employment_statuses.sassa_grant ||
        values.employmentStatus === employment_statuses.self_employed
      ) {
        return validateIncomeAndExpenses(values);
      }

      return values.employmentStatus === employment_statuses.unemployed;
    }
  }

  function validateAllFields(values) {
    return (
      !validate.lang.isBlank(values.employerName) &&
      !validate.lang.isBlank(values.incomeSource) &&
      !validate.lang.isBlank(values.incomeFrequency) &&
      !validate.lang.isBlank(values.startDate) &&
      !validate.lang.isBlank(values.grossIncome?.toString()) &&
      !validate.lang.isBlank(values.estimatedExpenses?.toString())
    );
  }

  function validateIncomeAndExpenses(values) {
    const expensesComplete = !validate.lang.isBlank(values.estimatedExpenses?.toString());
    const incomeComplete = !validate.lang.isBlank(values.grossIncome?.toString());
    const incomeFrequencyComplete = !validate.lang.isBlank(values.incomeFrequency);
    return expensesComplete && incomeComplete && incomeFrequencyComplete;
  }

  function save(values) {
    if (values.employmentStatus === employment_statuses.unemployed) {
      navigate.to(routes.offersUnavailable);
      return;
    }

    values.grossIncome = parseCurrency(values.grossIncome);
    values.estimatedExpenses = parseCurrency(values.estimatedExpenses);

    TealiumService.onEmploymentDetailsEnd(application.request?.applicationMethod);
    updateApplication(lendingOnboardingSteps.employment_details, values);

    setNextClicked(true);
  }

  function getInitialValues() {
    const employer = application.employer;
    return {
      employmentStatus: employer?.employmentStatus || '',
      employerName: employer?.employerName || '',
      startDate: employer?.startDate || '',
      employmentNumber: employer?.employmentNumber || '',
      employmentSector: employer?.employmentSector || '',
      employmentProvince: employer?.employmentProvince || '',
      incomeSource: employer?.incomeSource || '',
      incomeFrequency: employer?.incomeFrequency || '',
      grossIncome: employer?.grossIncome || '',
      estimatedExpenses: employer?.estimatedExpenses || '',
    };
  }

  return (
    <section className='employment-details-container'>
      <article className='employment-details-panel'>
        <header>
          <h2>Employment details</h2>
        </header>
        <section>
          <Formik
            innerRef={(formik) => formik && setNext(formik.submitForm)}
            initialValues={getInitialValues() || {}}
            initialErrors={isFormCompleted({ employmentStatus: '' }) ? {} : { incomplete: 'true' }}
            validate={validateForm}
            validateOnChange
            validateOnBlur
            validateOnMount
            enableReinitialize
            onSubmit={save}
          >
            {({ values, errors, isSubmitting, validateForm }) => {
              formValidator.current = validateForm;
              const canGoNext =
                !isSubmitting && !validate.hasErrors(errors) && isFormCompleted(values);
              setTimeout(() => {
                setNextEnabled(canGoNext);
              }, 1);

              return (
                <Form>
                  <Field
                    name='employmentStatus'
                    type='text'
                    placeholder='Employment status'
                    component={FloatingInput}
                    items={applicationDefaults.employmentStatus}
                    getItemDisplayValue={(item) => htmlFormat.decode(item.label)}
                    filterItemsWithInputValue
                    onItemSelect={(item) =>
                      TealiumService.onEmploymentDetailsDropDown(
                        application.request?.applicationMethod,
                        item.label,
                      )
                    }
                    fieldType='select'
                    className='top-field'
                  />
                  {(values.employmentStatus === employment_statuses.formally_employed ||
                    values.employmentStatus === employment_statuses.contract_employed) && (
                    <>
                      <Field
                        name='employerName'
                        type='text'
                        placeholder='Employer name'
                        component={FloatingInput}
                        fieldType='standard'
                        className='top-field'
                      />
                      <Field
                        name='startDate'
                        type='text'
                        placeholder='Year of employment'
                        component={FloatingInput}
                        fieldType='masked'
                        className='top-field'
                        mask={masks.year}
                      />
                      <Field
                        name='employmentNumber'
                        type='text'
                        placeholder='Workplace contact number'
                        component={FloatingInput}
                        fieldType='masked'
                        className='bottom-field'
                        mask={masks.phoneNumberSpaced}
                      />
                      <Field
                        name='employmentSector'
                        placeholder='Employment sector'
                        component={FloatingInput}
                        items={applicationDefaults.employmentSectors}
                        getItemDisplayValue={(item) => item.label}
                        filterItemsWithInputValue
                        fieldType='select'
                        className='top-field'
                      />
                      <Field
                        name='employmentProvince'
                        placeholder='Which province is your employer located in?'
                        component={FloatingInput}
                        items={applicationDefaults.address.provinces}
                        getItemDisplayValue={(item) => item}
                        filterItemsWithInputValue
                        fieldType='select'
                        className='top-field'
                      />
                    </>
                  )}
                  {(values.employmentStatus === employment_statuses.formally_employed ||
                    values.employmentStatus === employment_statuses.contract_employed ||
                    values.employmentStatus === employment_statuses.commission) && (
                    <Field
                      name='incomeSource'
                      placeholder='Income source'
                      component={FloatingInput}
                      items={applicationDefaults.incomeSource}
                      getItemDisplayValue={(item) => htmlFormat.decode(item.label)}
                      filterItemsWithInputValue
                      fieldType='select'
                      className='top-field'
                    />
                  )}
                  {(values.employmentStatus === employment_statuses.formally_employed ||
                    values.employmentStatus === employment_statuses.self_employed ||
                    values.employmentStatus === employment_statuses.contract_employed ||
                    values.employmentStatus === employment_statuses.sassa_grant ||
                    values.employmentStatus === employment_statuses.commission) && (
                    <>
                      <Field
                        name='incomeFrequency'
                        placeholder='Income frequency'
                        component={FloatingInput}
                        items={applicationDefaults.incomeFrequency}
                        getItemDisplayValue={(item) => htmlFormat.decode(item.label)}
                        filterItemsWithInputValue
                        fieldType='select'
                        className='top-field'
                      />
                      <Field
                        name='grossIncome'
                        type='text'
                        placeholder='Gross monthly income'
                        component={FloatingInput}
                        fieldType='masked'
                        mask={(e) => masks.currencyAmount(e)}
                        className='top-field'
                      />
                      <Field
                        name='estimatedExpenses'
                        type='text'
                        placeholder='Estimated monthly expenses'
                        component={FloatingInput}
                        fieldType='masked'
                        mask={(e) => masks.currencyAmount(e)}
                        className='top-field'
                      />
                    </>
                  )}
                </Form>
              );
            }}
          </Formik>
        </section>
      </article>
    </section>
  );
};
