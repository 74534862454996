import React, { useContext, useState, useEffect } from 'react';
import { LendingContext, WizardContext } from 'context';
import { routes } from 'router';
import { useNavService } from 'hooks';
import { ImageButton } from 'components';
import { TealiumService } from 'services';
import { applicationDefaults, lendingOnboardingSteps } from 'utils';
import { OnlineBankingIcon, UploadStatementsIcon } from 'assets';
import './upload-method.scss';

export const UploadMethod = () => {
  const { updateApplication } = useContext(LendingContext);
  const { setNext, setNextEnabled, forceUpdate } = useContext(WizardContext);
  const [uploadMethod, setUploadMethod] = useState('');
  const navigate = useNavService();

  useEffect(() => {
    TealiumService.onBankingDetailsStart();
  }, []);

  useEffect(() => {
    const canGoNext = isOnlineBankingSelected() || isUploadStatementsSelected();
    setNextEnabled(canGoNext);
    setNext(() => canGoNext && save());
    forceUpdate((fresh) => !fresh);
  }, [uploadMethod]);

  function save() {
    TealiumService.onBankingDetailsEnd(uploadMethod);
    updateApplication(lendingOnboardingSteps.upload_method, uploadMethod);

    navigate.to(routes.bankSelect);
  }

  const selectBankOption = (option) => {
    setUploadMethod(option);
  };

  function isOnlineBankingSelected() {
    return uploadMethod === applicationDefaults.uploadMethods.online;
  }

  function isUploadStatementsSelected() {
    return uploadMethod === applicationDefaults.uploadMethods.statement;
  }

  return (
    <div className='banking-details-container'>
      <article className='banking-details-panel'>
        <header>
          <h2>Bank statements</h2>
          <p>
            We can obtain your bank statements from your online banking. It is fast and secure. Or
            upload your statements manually.
          </p>
        </header>
        <section>
          <ImageButton
            checked={isOnlineBankingSelected()}
            icon={OnlineBankingIcon}
            alt='online banking icon'
            title='Online banking'
            onClick={() => selectBankOption(applicationDefaults.uploadMethods.online)}
            block
          />
          <ImageButton
            checked={isUploadStatementsSelected()}
            icon={UploadStatementsIcon}
            alt='upload statement icon'
            title='Upload statements'
            onClick={() => selectBankOption(applicationDefaults.uploadMethods.statement)}
            block
          />
        </section>
      </article>
    </div>
  );
};
