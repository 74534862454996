import React from 'react';
import { NavigationFooter } from 'components';
import { applicationDefaults } from 'utils';
import { VodaLendLogoRed, ReportIssueSuccess } from 'assets';
import './suggestions-sent.scss';

export const SuggestionsSent = () => (
  <article className='page-content-background'>
    <section className='Wizard full-height page-content'>
      <header className='page-header-common'>
        <img className='vodapay-logo' src={VodaLendLogoRed} alt='VodaPay' />
      </header>
      <div className='suggestions-sent'>
        <section className='content'>
          <h2>Thank you for your suggestion</h2>
          <p>We will review your suggestion and make any necessary changes.</p>
          <img src={ReportIssueSuccess} alt='Suggestions sent graphic' />
        </section>
      </div>
      <NavigationFooter
        onNext={() => (window.location.href = applicationDefaults.staticHomeUrl)}
        nextEnabled
        nextButtonLabel='VodaLend home'
        hideNext={false}
        hideBack
        hideCancel
      />
    </section>
  </article>
);
