import React from 'react';
import { NavigationFooter } from 'components';
import { applicationDefaults } from 'utils';
import { VodaLendLogoRed, ReportIssueSuccess } from 'assets';
import './report-issue-sent.scss';

export const ReportIssueSent = () => (
  <article className='page-content-background'>
    <section className='Wizard full-height page-content'>
      <header className='page-header-common'>
        <img
          className='vodapay-logo'
          src={VodaLendLogoRed}
          style={{ width: '40px' }}
          alt='VodaPay'
        />
      </header>
      <div className='report-issue-sent'>
        <section className='content'>
          <h2>Thank you for reporting an issue</h2>
          <p>
            We will review the issue you've reported and try to have it resolved as soon as possible
          </p>
          <img src={ReportIssueSuccess} alt='Report issue graphic' />
        </section>
      </div>
      <NavigationFooter
        onNext={() => (window.location.href = applicationDefaults.staticHomeUrl)}
        nextEnabled
        nextButtonLabel='VodaLend home'
        hideNext={false}
        hideBack
        hideCancel
      />
    </section>
  </article>
);
